import React from 'react';
import { useParams } from 'react-router-dom';
import { SurveyService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import EditablePage from '../../components/EditablePage';
import Typography from '../../components/Typography';
import Survey from '../../models/Survey';
import { AuthContext } from '../Authentication/state';
import SurveyBuilder from './SurveyBuilder';
import SurveyViewer from './SurveyViewer';

const SurveyDetails: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const { id } = useParams<{ id: string }>();
    const [survey, setSurvey] = React.useState<Survey | null>();
    const isEdit = React.useContext(AuthContext).state.isEditorView;

    React.useEffect(() => {
        if (id) {
            toggleLoading(true);
            SurveyService.getSurvey(id).then((response) => {
                setSurvey(response);
            }).finally(()=>toggleLoading(false));
        }
    }, [id]);

    return (
        <EditablePage>
            <div className="surveys">
                {survey && (
                    <>
                        {isEdit ? (
                            <SurveyBuilder survey={survey} />
                        ) : (
                            <div className="survey-details">
                                <Typography variant="title">
                                    {survey.title}
                                </Typography>
                                <Typography variant="body">{survey.description}</Typography>
                                <hr />
                                <SurveyViewer elements={survey.elements} formView={true} canSubmit={false}/>
                            </div>
                        )}
                    </>
                )}
            </div>
        </EditablePage>
    );
};

export default SurveyDetails;
