import React from 'react';
import { UserReportStatus } from '../../models/Report';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as ApproveIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as RejectIcon } from '../../assets/icons/x-circle.svg';
import IconButton from '../../components/IconButton';

type Props = {
    handleStatusChange: (value: UserReportStatus) => void;
};

const ReportAdminActions: React.FC<Props> = ({handleStatusChange}) => {
    return (
        <div className="report-admin-actions">
            <IconButton
                Icon={ApproveIcon}
                color="success"
                onClick={() => handleStatusChange(UserReportStatus.Approved)}
                tooltip="Approve report"
            />
            <IconButton Icon={RejectIcon} color="error" onClick={() => handleStatusChange(UserReportStatus.Rejected)} tooltip="Reject report" />
            <IconButton Icon={SendIcon} color="info" onClick={() => handleStatusChange(UserReportStatus['Need More Info'])} tooltip="Send for revision" />
        </div>
    );
};

export default ReportAdminActions;
