import React from 'react';
import { ProjectService } from '../../../api';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import Project, { ProjectStatus } from '../../../models/Project';
import { Link, useHistory, useParams } from 'react-router-dom';
import ProjectInfo from './ProjectInfo';
import Card, { CardActionBar, CardActionHeader } from '../../../components/Card';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/AlertDialog/state';
import { DataLoadingContext } from '../../../components/DataLoading';
import { ReactComponent as CompleteIcon } from './../../../assets/icons/check-circle.svg';
import { ReactComponent as ArchiveIcon } from './../../../assets/icons/archive.svg';
import { ReactComponent as UnarchiveIcon } from './../../../assets/icons/unarchive.svg';
import './projectdetail.scss';
import ProjectCampaigns from './ProjectCampaigns';
import IconButton from '../../../components/IconButton';
import Tag from '../../../components/Tag';
import { getColorByProjectStatus } from '../../../utils/getColorByStatus';
import { NotificationActionType, NotificationContext } from '../../../components/Notification/state';

const ProjectDetail: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [project, setProject] = React.useState<Project | null>(null);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [reload, setReloading] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ProjectService.getProject(id)
            .then((response) => {
                setProject(response);
            })
            .finally(() => toggleLoading(false));
    };
    const openDeleteProjectDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Project',
                description: `Campaigns associated with this project will be affected. Are you sure you want to delete project ${
                    project?.name as string
                }?`,
                handleConfirm: () => handleDeleteProject(project?.id as string),
            },
        });
    };

    const handleDeleteProject = (id: string) => {
        ProjectService.deleteProject(id).then(() => {
            history.push('/projects');
        });
    };

    const handleStatusChange = (status: ProjectStatus) => {
        toggleLoading(true);
        ProjectService.updateProjectStatus(project?.id as string, status).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `Project ${status == ProjectStatus.Active ? "unarchived" : `${status == ProjectStatus.Archived ? "archived" : "completed"}`} successfully`,
                    status: 'success',
                    autoClose: true,
                }})
            getData();
            setReloading(true);
            setTimeout(()=>setReloading(false), 1000);
        }).catch((e)=>notificationDispatch({
            type: NotificationActionType.OPEN,
            payload: e.toString()
        })).finally(()=>toggleLoading(false))
    };

    const handleOpenCompleteDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Complete Campaign',
                description: `All campaigns for the project will also be completed. Please make sure all submitted reports for the project campaigns are evaluated. Synner activity with unapproved reports will be marked as Incomplete and will not be compensated. Are you sure you want to continue?`,
                handleConfirm: () => handleStatusChange(ProjectStatus.Completed),
            },
        });
    };

    const handleOpenArchiveDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: (project?.status === ProjectStatus.Archived) ? "Unarchive Project" :'Archive Campaign',
                description: (project?.status === ProjectStatus.Archived) ? "Are you sure you want to unarchive this project?":`All campaigns for the project will also be archived. Please make sure all submitted reports for the project campaigns are evaluated. Synner activity with unapproved reports will be marked as Incomplete and will not be compensated. Are you sure you want to continue?`,
                handleConfirm: () => (project?.status === ProjectStatus.Archived) ?  handleStatusChange(ProjectStatus.Active) : handleStatusChange(ProjectStatus.Archived),
            },
        });
    };

    return (
        <div className="project-detail">
            {project && (
                <Card>
                    <CardActionHeader>
                        <Tag label={ProjectStatus[project.status]} color={getColorByProjectStatus(project.status)} />
                        <Typography variant="title" color="secondary">
                            {project.name.toUpperCase()}
                        </Typography>
                        <>
                            {project.status !== ProjectStatus.Archived  ? (
                                <div className="d-flex">
                                    {project.status !== ProjectStatus.Completed && (
                                        <IconButton Icon={CompleteIcon} color="success" tooltip="Complete project" onClick={handleOpenCompleteDialog}/>
                                    )}
                                    <IconButton Icon={ArchiveIcon} color="grey" tooltip="Archive project" onClick={handleOpenArchiveDialog}/>
                                </div>
                            ) :
                            <>
                            <div className="d-flex">
                            <IconButton Icon={UnarchiveIcon}  tooltip="Unarchive project" onClick={handleOpenArchiveDialog}/>
                            </div>
                            </>
                            }
                        </>
                    </CardActionHeader>
                    <ProjectInfo project={project} />
                    <CardActionBar>
                        <>
                            <Button color="error" onClick={openDeleteProjectDialog}>
                                Delete
                            </Button>
                            {project.status === ProjectStatus.Active && (
                                <Link to={`/campaigns/create-campaign/${project.id}`}>
                                    <Button color="primary">Create Campaign</Button>
                                </Link>
                            )}
                        </>
                        <>
                            {project.status === ProjectStatus.Active && (
                                <Link to={`/projects/${project.id}/edit`}>
                                    <Button color="primary">Edit</Button>
                                </Link>
                            )}
                        </>
                    </CardActionBar>
                </Card>
            )}
            {!reload && <ProjectCampaigns />}
        </div>
    );
};

export default ProjectDetail;
