import React from 'react';
import Avatar from '../../components/Avatar';
import IconButton from '../../components/IconButton';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Modal from '../../components/Modal';
import Upload from '../../components/Upload';

type Props = {
    source: string;
    name: string;
    handleChange: (file: File) => Promise<string>;
};

const ProfilePicture: React.FC<Props> = ({ source, name, handleChange }) => {
    const [imgSrc, setImgSrc]= React.useState(source);
    const [isOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading]= React.useState(false);

    const handleUpload = (file: File | File[]) => {
        setLoading(true);
        handleChange(file as File).then((response)=> {
            setImgSrc(response);           
            setIsOpen(false);
            setLoading(false);
        }).catch(()=> {
            setLoading(false);
        })
    };

    return (
        <>
            <div className="profile-picture">
                <Avatar size="large" source={loading?'':imgSrc} name={name} />
                <div className="upload-button">
                    <IconButton
                        onClick={() => setIsOpen(true)}
                        color="white"
                        Icon={EditIcon}
                        tooltip="Edit profile picture"
                    />
                </div>
            </div>
            <Modal isOpen={isOpen} close={() => setIsOpen(false)} title="Upload new Profile Picture" size="medium">
                <Upload maxSize={5120} isMultiple={false} handleUpload={handleUpload} loading={loading} isAvatar={true} fileTypes={["jpg", "jpeg", "png", "gif", "bmp", "svg", "heic", "webp"]}/>
            </Modal>
        </>
    );
};

export default ProfilePicture;
