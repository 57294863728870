import React from 'react';
import ErrorInfo from '../../components/ErrorInfo';
import Content from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import ContentActions from './ContentActions';
import EditContent from './EditContent';
import './contentmanager.scss';
import { ContentService } from '../../api';
import LoaderGif from '../../assets/images/loader.gif';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';

type Props = {
    content: Content;
    children: React.ReactChild;
};

const ContentManager: React.FC<Props> = ({ content, children }) => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const history = useHistory();
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [_content, setContent] = React.useState<Content>(content);

    const handleChange = (name: string, value: string | boolean | number) => {
        setContent({ ..._content, [name]: value });
        setIsSuccess(false);
    };

    const handleSubmit = () => {        
        const isValid = _content.title.length > 0 && _content.data.length > 0;
        if (isValid) {
            setIsLoading(true);
            const request = content.id ? ContentService.updateContent(_content): ContentService.createContent(_content);
            request.then(() => {
                setIsLoading(false);
                setIsSuccess(true);
                setError('');
                if(!content.id) {
                    history.goBack();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });            
        } else {
            setError('Title and content fields are required');
        }
    };

    const handleDelete = () => {
        setIsLoading(true);
        ContentService.deleteContent(_content.id as string)
            .then(() => {
                setIsLoading(false);
                history.goBack();
                setError('');
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    return (
        <>
            {isEdit && (
                <div className="content-manager paper">
                    <div style={{ width: '100%' }}>
                        {error.length > 0 && (
                            <>
                                <ErrorInfo error={error} onClose={() => setError('')} />
                                <br />
                            </>
                        )}
                        <EditContent handleChange={handleChange} content={_content} />
                    </div>
                    <ContentActions
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        content={_content}
                        handleDelete={handleDelete}
                        loading={isLoading}
                    />
                    {isLoading && (
                        <div className="content-loading">
                            <img src={LoaderGif} alt="loader" />
                        </div>
                    )}
                    {isSuccess && (
                        <div className="content-loading">
                            <CheckIcon/>
                        </div>
                    )}
                </div>
            )}
            {!isEdit && <>{children}</>}
        </>
    );
};

export default ContentManager;
