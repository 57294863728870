import React from 'react';
import './progressbar.scss';

type Props = {
    progress: number,
    variant: string,
}

const ProgressBar: React.FC<Props> = ({ progress, variant }) => {
	
	const newStyle = {
        opacity: 1,
        width: `${progress}%`
    }
    return (
        <div className="progress">
            <div className={`progress-done bg-${variant}`} style={newStyle}>
            </div>
        </div>
    );
};

export default ProgressBar;
