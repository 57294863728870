import { ActivityStatus } from './Activity';
import { ProjectType } from './Project';

export default interface Campaign {
    id?: string;
    projectId: string;
    status: CampaignStatus;
    type?: ProjectType;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    venue?: string;
    country: string;
    startDate: string | Date;
    endDate: string | Date;
    description: string;
    report?: string;
    isInCampaign?: boolean;
    surveys?: CampaignSurvey[];
    stipend: string;
    expectedSynners: string;
    photoUrl?: string;
    projectName?: string;
    displayName?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export type UserCampaign = {
    id: string;
    campaignId: string;
    campaignName: string;
    projectName: string;
    displayName: string;
    activityStatus: ActivityStatus;
    managerId: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    venue?: string;
    startDate: Date;
    endDate: Date;
    expanded: boolean;
};

export enum CampaignStatus {
    Ready = 1,
    Completed = 3,
    Archived = 4,
}

export type CampaignSurvey = {
    title: string;
    id: string;
};
