import React from 'react';
import Typography from '../../../components/Typography';
import { SurveyElementType } from '../../../models/Survey';
import { getListFromEnum } from '../../../utils';
import { SurveyBuilderActionType, surveyBuilderContext } from './state';
import ToolIcon from './ToolIcon';

const Tools: React.FC = () => {
    const availableTools = getListFromEnum(Object.values(SurveyElementType));
    const dispatch = React.useContext(surveyBuilderContext).dispatch;

    const handleSelect = (type: string | number) => {
        dispatch({
            type: SurveyBuilderActionType.ADD_SURVEY_ELEMENT,
            payload: type as SurveyElementType,
        });
    };

    return (
        <div className="tools">
            {availableTools.map((el, index) => (
                <div
                    className="tool"
                    onClick={() => handleSelect(el.value)}
                    tabIndex={0}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && handleSelect(el.value)}
                    key={index}
                >
                    <div className="icon">
                        <ToolIcon type={el.value as SurveyElementType} />
                    </div>
                    <div className="label">
                        <Typography variant="caption">{el.label}</Typography>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Tools;
