import React from "react";
import { UserRole } from "../../models/User";
import LandingPage from '../../containers/Landing';
import ProfilePage from '../../containers/Profile';
import DashboardPage from '../../containers/Dashboard';
import ApplyPage from '../../containers/Apply';
import ForgotPasswordPage from '../../containers/ForgotPassword';
import ResetPasswordPage from '../../containers/ResetPassword';
import UsersPage from '../../containers/Users';
import SynnerDetailPage from '../../containers/Users/UserDetail';
import ApplicantsPage from '../../containers/Applicants';
import RegisterPage from '../../containers/Register';
import ApplicantDetailPage from "../../containers/Applicants/ApplicantDetail";
import ProjectsPage from "../../containers/Projects";
import CreateProjectPage from "../../containers/Projects/CreateProject";
import CampaignsPage from "../../containers/Campaigns";
import CreateCampaignPage from "../../containers/Campaigns/CreateCampaign";
import InvitationPage from "../../containers/Campaigns/CampaignInvitation";
import CalendarPage from "../../containers/Calendar";
import AnnouncementsPage from "../../containers/Announcements";
import CreateAnnouncementPage from "../../containers/Announcements/CreateAnnouncement";
import HandbookPage from "../../containers/Handbook";
import CreateHandbookPage from "../../containers/Handbook/CreateHandbook";
import ContactPage from "../../containers/Contact";
import CreateContactPage from "../../containers/Contact/CreateContact";
import FaqPage from "../../containers/Faq";
import CreateFaqPage from "../../containers/Faq/CreateFaq";
import DefaultSingleTemplatePage from "../../containers/ContentManager/DefaultSingleTemplate";
import ReportsPage from "../../containers/Reports";
import ReportDetailPage from "../../containers/Reports/ReportDetail";
import SurveysPage from "../../containers/Surveys";
import SurveyDetailsPage from "../../containers/Surveys/SurveyDetails";
import SurveyBuilderPage from "../../containers/Surveys/SurveyBuilder";
import {ReactComponent as DashboardIcon } from '../../assets/icons/pie-chart.svg';
import {ReactComponent as ProfileIcon } from '../../assets/icons/users.svg';
import {ReactComponent as EventIcon } from '../../assets/icons/list.svg';
import {ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import {ReactComponent as ProjectIcon } from '../../assets/icons/trello.svg';
import { ReactComponent as ApplicationIcon } from "../../assets/icons/file-text.svg";
import {ReactComponent as ReportIcon } from "../../assets/icons/clipboard.svg";
import {ReactComponent as SurveyIcon } from "../../assets/icons/survey.svg";
import {ReactComponent as FinanceIcon} from "../../assets/icons/dollar-sign.svg";
import NotFound from "../../containers/NotFound";
import CreateReportPage from "../../containers/Reports/CreateReport";
import ProjectDetail from "../../containers/Projects/ProjectDetail";
import EditProject from "../../containers/Projects/EditProject";
import EditCampaign from "../../containers/Campaigns/EditCampaign";
import CampaignDetail from "../../containers/Campaigns/CampaignDetail";
import MyCampaigns from "../../containers/Campaigns/MyCampaigns";
import UserCampaigns from "../../containers/Users/UserCampaigns";
import Financials from "../../containers/Financials";

export type Route = {
    label: string;
    route: string;
    roles: Array<UserRole>;
    Component: React.FC;
    Icon?: React.FC;
    exact: boolean;
    isLeftNav?: boolean;
    isTopNav?: boolean;
    isExternal?:boolean;
}

export type NavItem = {
    label:string;
    route: string;
    Icon?: React.FC;
}
const SubAuthRoles = [UserRole.Trainee, UserRole.OnBoarding]
const AuthRoles = [UserRole.Admin, UserRole.Manager, UserRole.Synner]
const AllRoles = [UserRole.Visitor, ...AuthRoles, ...SubAuthRoles]

export const routes: Array<Route> = [
    {
        label: 'Landing',
        route: '/',
        roles: [UserRole.Visitor],
        Component: LandingPage,
        exact: true,
    },
    {
        label: '404',
        route: '/404',
        roles: AllRoles,
        Component: NotFound,
        exact: true,
    },
    {
        label: 'Apply',
        route: '/apply',
        roles: [UserRole.Visitor],
        Component: ApplyPage,
        exact: true,
    },
    {
        label: 'Forgot Password',
        route: '/forgot-password',
        roles: [UserRole.Visitor],
        Component: ForgotPasswordPage,
        exact: true,
    },
    {
        label: 'Reset Password',
        route: '/reset-password/:token/:email',
        roles: [UserRole.Visitor],
        Component: ResetPasswordPage,
        exact: false,
    },
    {
        label: 'Reset Password',
        route: '/reset-password',
        roles: [...AuthRoles, ...SubAuthRoles],
        Component: ResetPasswordPage,
        exact: true,
    },
    {
        label: 'Registration',
        route: '/register/:token/:emailAddress',
        roles: [UserRole.Visitor],
        Component: ForgotPasswordPage,
        exact: true,
    },
    {
        label: 'Registration',
        route: '/registration/:token/:email',
        roles: [UserRole.Visitor],
        Component: RegisterPage,
        exact: false,
    },
    {
        label: 'Profile',
        route: '/profile',
        roles: [...AuthRoles, ...SubAuthRoles],
        Component: ProfilePage,
        Icon: ProfileIcon,
        exact: true,
    },
    {
        label: 'Dashboard',
        route: '/dashboard',
        roles: [UserRole.Synner, UserRole.Trainee],
        Component: DashboardPage,
        isLeftNav: true,
        Icon: DashboardIcon,
        exact: true,
    },
    {
        label: 'Projects',
        route: '/projects',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ProjectsPage,
        isLeftNav: true,
        Icon: ProjectIcon,
        exact: true,
    },
    {
        label: 'Projects',
        route: '/projects/archived',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ProjectsPage,
        exact: true,
    },
    {
        label: 'Projects',
        route: '/projects/create-project',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateProjectPage,
        exact: true,
    },
    {
        label: 'Projects',
        route: '/projects/:id/detail',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ProjectDetail,
        exact: true,
    },
    {
        label: 'Projects',
        route: '/projects/:id/edit',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: EditProject,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns',
        roles: [UserRole.Synner, UserRole.Trainee, UserRole.Admin, UserRole.Manager],
        Component: CampaignsPage,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CampaignsPage,
        isLeftNav: true,
        Icon: EventIcon,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/archived',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CampaignsPage,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/my-campaigns',
        roles: [UserRole.Synner, UserRole.Trainee],
        Component: MyCampaigns,
        isLeftNav: true,
        Icon: EventIcon,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/:id/detail',
        roles: [UserRole.Admin, UserRole.Manager, UserRole.Synner, UserRole.Trainee],
        Component: CampaignDetail,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/:id/edit',
        roles: [UserRole.Admin, UserRole.Manager, UserRole.Synner, UserRole.Trainee],
        Component: EditCampaign,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/create-campaign/:projectId',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateCampaignPage,
        exact: true,
    },
    {
        label: 'Campaigns',
        route: '/campaigns/:id/invitation',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: InvitationPage,
        exact: true,
    },
    {
        label: 'Report',
        route: '/reports/:id',
        roles: [UserRole.Admin, UserRole.Manager, UserRole.Synner, UserRole.Trainee],
        Component: ReportDetailPage,
        exact: true,
    },
    {
        label: 'Reports',
        route: '/reports',
        Icon: ReportIcon,
        isLeftNav: true,
        roles: [UserRole.Synner, UserRole.Trainee],
        Component: ReportsPage,
        exact: true,
    },
    {
        label: 'Reports',
        route: '/report/create-report',
        roles: [UserRole.Synner, UserRole.Admin, UserRole.Manager],
        Component: CreateReportPage,
        exact: true,
    },
    {
        label: 'Synners',
        route: '/synners',
        roles: [UserRole.Synner, UserRole.Manager],
        Component: UsersPage,
        isLeftNav: true,
        Icon: ProfileIcon,
        exact: true,
    },
    {
        label: 'Users',
        route: '/users',
        roles: [UserRole.Admin],
        Component: UsersPage,
        isLeftNav: true,
        Icon: ProfileIcon,
        exact: true,
    },
    {
        label: 'Users',
        route: '/users/disabled',
        roles: [UserRole.Admin],
        Component: UsersPage,
        exact: true,
    },
    {
        label: 'Synners',
        route: '/users/:id/detail',
        roles: [UserRole.Admin, UserRole.Synner, UserRole.Manager],
        Component: SynnerDetailPage,
        exact: true,
    },
    {
        label: 'Activity',
        route: '/users/:userId/campaigns',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: UserCampaigns,
        exact: true,
    },
    {
        label: 'Activity',
        route: '/users/:userId/reports',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ReportsPage,
        exact: true,
    },
    {
        label: 'Applicants',
        route: '/applicants',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ApplicantsPage,
        isLeftNav: true,
        Icon: ApplicationIcon,
        exact: true,
    },
    {
        label: 'Applicants',
        route: '/applicants/:id',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: ApplicantDetailPage,
        exact: true,
    },
    {
        label: 'Financials',
        route: '/financials',
        roles: [UserRole.Admin, UserRole.Manager],
        isLeftNav: true,
        Icon: FinanceIcon,
        Component: Financials,
        exact: true,
    },
    {
        label: 'Financials',
        route: '/financials/completed',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: Financials,
        exact: true,
    },
    {
        label: 'Calendar',
        route: '/calendar',
        roles: [UserRole.Synner, UserRole.Admin, UserRole.Manager, UserRole.Trainee],
        Component: CalendarPage,
        isLeftNav: true,
        Icon: CalendarIcon,
        exact: true,
    },
    {
        label: 'Announcements',
        route: '/announcement',
        roles: [...AuthRoles, ...SubAuthRoles],
        isTopNav: true,
        Component: AnnouncementsPage,
        exact: true,
    },
    {
        label: 'Announcements',
        route: '/announcement/create-announcement',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateAnnouncementPage,
        exact: true,
    },
    {
        label: 'Announcements',
        route: '/announcement/:id',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: DefaultSingleTemplatePage,
        exact: true,
    },
    {
        label: 'FAQs',
        route: '/faq',
        roles: [...AuthRoles, ...SubAuthRoles],
        isTopNav: true,
        Component: FaqPage,
        exact: true,
    },
    {
        label: 'Faqs',
        route: '/faq/create-faq',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateFaqPage,
        exact: true,
    },
    {
        label: 'Faqs',
        route: '/faq/:id',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: DefaultSingleTemplatePage,
        exact: true,
    },
    {
        label: 'Handbook',
        route: '/handbook',
        roles: [...AuthRoles, ...SubAuthRoles],
        isTopNav: true,
        Component: HandbookPage,
        exact: true,
    },
    {
        label: 'Handbook',
        route: '/handbook/create-handbook',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateHandbookPage,
        exact: true,
    },
    {
        label: 'Handbook',
        route: '/handbook/:id',
        roles: [UserRole.Admin, UserRole.Manager, UserRole.Trainee],
        Component: DefaultSingleTemplatePage,
        exact: true,
    },
    {
        label: 'Contact',
        route: '/contact',
        roles: [...AuthRoles, ...SubAuthRoles],
        isTopNav: true,
        Component: ContactPage,
        exact: true,
    },
    {
        label: 'Contact',
        route: '/contact/create-contact',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: CreateContactPage,
        exact: true,
    },
    {
        label: 'Contact',
        route: '/contact/:id',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: DefaultSingleTemplatePage,
        exact: true,
    },
    {
        label: 'Report Builder',
        route: '/surveys',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: SurveysPage,
        isLeftNav: true,
        Icon: SurveyIcon,
        exact: true,
    },
    {
        label: 'Report Builder',
        route: '/surveys/:id',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: SurveyDetailsPage,
        exact: true,
    },
    {
        label: 'Report Builder',
        route: '/create-survey',
        roles: [UserRole.Admin, UserRole.Manager],
        Component: SurveyBuilderPage,
        exact: true,
    },
];

export const getLeftNavItemsByRole=(role: UserRole):Array<NavItem>=> {
    const response:Array<NavItem> = [];
    routes.forEach((element)=> {
        if(element.isLeftNav && element.roles.includes(role)){
            response.push({
                label: element.label,
                route: element.route,
                Icon: element.Icon
            })
        }
    })
    return response;
}
export const getTopNavItemsByRole=(role: UserRole):Array<NavItem>=> {
    const response:Array<NavItem> = [];
    routes.forEach((element)=> {
        if(element.isTopNav && element.roles.includes(role)){
            response.push({
                label: element.label,
                route: element.route,
                Icon: element.Icon
            })
        }
    })
    return response;
}