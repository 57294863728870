import React from 'react';
import './toggle.scss';

type Props = {
    onChange: (name: string, value: boolean) => void;
    name: string;
    value: boolean;
};

const Toggle: React.FC<Props> = ({ onChange, name, value }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.name, e.target.checked);
    };
    return (
        <div className="toggle-container">
            <input type="checkbox" className="toggle" onChange={handleChange} name={name} checked={value} />
        </div>
    );
};
export default Toggle;
