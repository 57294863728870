import React from 'react';

import {
    dateIsValid,
    emailIsValid,
    getStates,
    getUserRoles,
    phoneNumberIsValid,
    zipCodeIsValid,
} from '../../utils';
import InputField from '../../components/InputField';
import InputSelect from '../../components/InputSelect';

import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import AdminUserCreationReq from '../../models/AdminUserCreationReq';
import Modal, { ModalActionBar } from '../../components/Modal';
import { AuthService, ProfileService, UserService } from '../../api';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Profile from '../../models/Profile';
import DateInput from '../../components/DateInput';
type Props = {
    isOpen: boolean;
    close: () => void;
    getData: ()=>void;
    userId: string | null;
};
const initialNewUserState: AdminUserCreationReq = {
    fname: '',
    lname: '',
    emailAddress: '',
    role: '',
    phoneNumber: '',
    addressLine1: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    dateOfBirth: '',
};

const UserModal: React.FC<Props> = ({ isOpen, close, getData, userId }) => {
    const [showError, setShowError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [emailExists, setEmailExists] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [user, setUser] = React.useState<AdminUserCreationReq>(initialNewUserState);
    const [selectedProfile, setSelectedProfile] = React.useState<Profile| null>(null);

    const userRoles = getUserRoles();
    const states = getStates();

    React.useEffect(()=> {
        if(userId) {
            UserService.getUser(userId).then((response)=> {
                setSelectedProfile(response);
                setUser(response);
            });
        }
    }, [userId])

    const closeUserModal = () => {
        setUser({...initialNewUserState});
        setShowError(false);  
        setEmailExists(false);      
        close();
    };

    const handleChange = (name: string, value: string|number) => {
        setUser({ ...user, [name]: value });
    };

    const isValid = ()=> {
        return  user.fname.length > 0 &&
        user.lname.length > 0 &&
        emailIsValid(user.emailAddress) &&
        phoneNumberIsValid(user.phoneNumber) &&
        user.addressLine1.length > 0 &&
        user.city.length > 0 &&
        user.state.length > 0 &&
        zipCodeIsValid(user.zipCode) &&
        user.country.length > 0 &&
        user.zipCode.length > 0 &&
        dateIsValid(user.dateOfBirth);
    }

    const handleSubmit = (): void => {
        if (!isValid()) {
            setShowError(true);
        } else {            
            setIsLoading(true);        
            const profileReq = {...selectedProfile, ...user} as Profile;
            const req = userId ? ProfileService.updateProfile(profileReq) : AuthService.createUser(user);
            req.then(()=> {notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: userId ? `User profile successfully updated` :`User successfully registered`,
                    status: 'info',
                    autoClose: true,
                },
            });
            closeUserModal();
            getData();
        })
        .catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(()=>setIsLoading(false))
        }
    };
    const handleEmailBlurred = () => {
        if(emailIsValid(user.emailAddress) && selectedProfile?.emailAddress !== user.emailAddress){
            AuthService.doesEmailExists(user.emailAddress)
            .then((response) => {
                setEmailExists(response);
            }).catch(() => {
                setEmailExists(false);
            })
        }
    }
    
    return (
        <Modal isOpen={isOpen} close={closeUserModal} title={userId ? 'Edit User': 'Add New User'} size="large">
            <Typography variant="caption">*Please provide valid address information</Typography>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        First Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="fname"
                        value={user.fname}
                        error={showError && user.fname.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Last Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="lname"
                        value={user.lname}
                        error={showError && user.lname.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Email Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.emailAddress}
                        name="emailAddress"
                        type="email"
                        onBlur={handleEmailBlurred}
                        error={emailExists || (showError && !emailIsValid(user.emailAddress))}
                        errorText={!emailExists ? `Invalid email address` : 'User with the email already exists.'}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Phone Number <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.phoneNumber}
                        name="phoneNumber"
                        type="number"
                        error={showError && !phoneNumberIsValid(user.phoneNumber)}
                        errorText="Invalid phone number"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">Role</Typography>
                    <InputSelect
                        options={userRoles}
                        value={user.role}
                        name="role"
                        placeholder="Role"
                        onChange={handleChange}
                        error={showError && user.role.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Date Of Birth <span className='typography--caption'>(MM/DD/YYYY)</span><span className="color-error">*</span>
                    </Typography>
                    <DateInput
                        onChange={handleChange}
                        value={user.dateOfBirth}
                        name="dateOfBirth"
                        error={showError && !dateIsValid(user.dateOfBirth)}
                        errorText="Please enter a valid date of birth"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Street Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.addressLine1}
                        name="addressLine1"
                        error={showError && user.addressLine1.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">Apt/Unit/Suite</Typography>
                    <InputField onChange={handleChange} name="addressLine2" value={user.addressLine2} />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        State <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={states}
                        value={user.state}
                        name="state"
                        placeholder="State"
                        onChange={handleChange}
                        error={showError && user.state.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        City <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.city}
                        name="city"
                        error={showError && user.city.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Zip Code <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.zipCode}
                        name="zipCode"
                        type="number"
                        error={showError && !zipCodeIsValid(user.zipCode)}
                        errorText="Invalid zip Code"
                    />
                </div>
            </div>
            <ModalActionBar>
                <Button onClick={closeUserModal} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="secondary" disabled={isLoading} loading={isLoading}>
                    Submit
                </Button>
            </ModalActionBar>
        </Modal>
    );
};

export default UserModal;
