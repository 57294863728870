import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ContentService } from '../../api';

//note
//need to setup api key in .env file as REACT_APP_EDITOR_API_KEY
//

type Props = {
    value: string;
    name: string;
    onChange: (name: string, value: string) => void;
    height?: number;
};
const ContentEditor: React.FC<Props> = ({ name, value, onChange, height = 640 }) => {
    const [initialValue, setInitialValue] = React.useState('');

    React.useEffect(() => {
        setInitialValue(value);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleEditorChange = (e: any) => {
        const editorValue = e.target.getContent();
        if (editorValue) {
            onChange(name, editorValue);
        }
    };
    return (
        <Editor
            apiKey={process.env.REACT_APP_EDITOR_API_KEY}
            initialValue={initialValue}
            init={{
                height: height,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                images_dataimg_filter: function (img:any) {
                    return img.hasAttribute('internal-blob');
                },
                menubar: 'file edit view insert format tools table help',
                plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                importcss_append: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image table',                
                formats: {
                    // Changes the default format for h1 to have a class of heading
                    h1: { block: 'h1', classes: 'typography--maxHeading' },                    
                    h2: { block: 'h2', classes: 'typography--heading' },  
                    h3: { block: 'h3', classes: 'typography--subheading' },                    
                    h4: { block: 'h4', classes: 'typography--title' },
                    h5: { block: 'h5', classes: 'typography--subtitle' },                   
                    p: { block: 'p', classes: 'typography--body' },
                    caption: { block: 'p', classes: 'typography--caption' }
                  },
                  style_formats: [
                    // Adds the h1 format defined above to style_formats
                    { title: 'Max Heading', format: 'h1' },
                    { title: 'Heading', format: 'h2' },
                    { title: 'Sub Heading', format: 'h3' },
                    { title: 'Title', format: 'h4' },                    
                    { title: 'Sub Title', format: 'h5' },                    
                    { title: 'Body', format: 'p' },                    
                    { title: 'Caption', format: 'caption' },
                  ],
                /* we override default upload handler to simulate successful upload*/
                images_upload_handler: function (blobInfo) {
                    return ContentService.uploadMedia(blobInfo.blob(), blobInfo.filename())
                        .then((response) => {
                            return response;
                        })
                        .catch(() => {
                            return '';
                        });
                },
            }}
            onChange={handleEditorChange}
        />
    );
};

export default ContentEditor;
