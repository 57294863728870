import React from 'react';
import IconButton from '../../components/IconButton';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import { ApplicationService } from '../../api';
import { useHistory } from 'react-router-dom';

type Props = { id: string; name: string; getData: () => void };

const ApplicantActions: React.FC<Props> = ({ id, name, getData }) => {
    const history = useHistory();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleDelete = (id: string) => {
        ApplicationService.deleteApplication(id).then(() => {
            getData();
        });
    };

    const handleOpenDeleteApplicantDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Applicant',
                description: `Are you sure you want to delete applicant ${name}?`,
                handleConfirm: () => handleDelete(id),
            },
        });
    };
    return (
        <>
            <IconButton
                Icon={DeleteIcon}
                onClick={() => handleOpenDeleteApplicantDialog()}
                color="error"
                tooltip="Delete Applicant"
            />
            <IconButton
                Icon={ExternalLinkIcon}
                onClick={() => history.push(`/applicants/${id}`)}
                color="info"
                tooltip="Applicant Details"
            />
        </>
    );
};

export default ApplicantActions;
