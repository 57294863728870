import React from 'react';
import Typography from '../../components/Typography';
import { ActivityService } from '../../api';
import './dashboard.scss';
import { DataLoadingContext } from '../../components/DataLoading';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { UserCampaign } from '../../models/Campaign';
import ActivityCard from './ActivityCard';

const ActionableItems: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [activities, setActivities] = React.useState<UserCampaign[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ActivityService.getActionableActivities()
            .then((response) => setActivities(response))
            .finally(() => {
                toggleLoading(false);
                setIsInitialized(true);
            });
    };
    return (
        <div className="dashboard-grid-item actionable-items-container">
            <div className="dashboard-grid-item-header">
                <Typography variant="subtitle">To-do List</Typography>
            </div>
            <div className="dashboard-grid-item-content">
                {isInitialized && activities.length === 0 && (
                    <div>
                        <Typography variant="subtitle" textAlign="center">
                            There&apos;s nothing on your to-do list.
                        </Typography>
                        <Typography variant="heading" textAlign="center">
                            APPLY TO ACTIVE CAMPAIGNS
                        </Typography>
                        <Link to="/campaigns">
                            <Button className="d-block mx-auto">View Campaigns</Button>
                        </Link>
                    </div>
                )}
                <div className="actionable-items">
                    {activities.map((activity) => (
                        <ActivityCard activity={activity} getData={getData} key={activity.id}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActionableItems;
