import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ActivityIcon } from '../../../assets/icons/activity.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';
import { AuthContext } from '../../Authentication/state';
import { UserRole } from '../../../models/User';

type CampaignTabs = 'My Campaigns' | 'All Campaigns' | 'Archived Campaigns';

const CampaignWrapper: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    const [active, setActive] = React.useState<CampaignTabs>();
    const location = useLocation();
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);

    React.useEffect(() => {
        if (location.pathname === '/campaigns') setActive('All Campaigns');
        if (location.pathname === '/campaigns/my-campaigns') setActive('My Campaigns');
        if (location.pathname === '/campaigns/archived') setActive('Archived Campaigns');
    }, [location]);

    return (
        <div className="selection-wrapper">
            {!isAdminManager ? (
                <div className="tabs">
                    <Link to="/campaigns/my-campaigns">
                        <div className={`selection ${active === 'My Campaigns' ? 'active' : ''}`}>
                            <ActivityIcon />
                            <span>My Campaigns</span>
                        </div>
                    </Link>
                    <Link to="/campaigns">
                        <div className={`selection ${active === 'All Campaigns' ? 'active' : ''}`}>
                            <GridIcon />
                            <span>Active Campaigns</span>
                        </div>
                    </Link>
                </div>
            ) : (
                <div className="tabs">
                    <Link to="/campaigns">
                        <div className={`selection ${active === 'All Campaigns' ? 'active' : ''}`}>
                            <GridIcon />
                            <span>Campaigns</span>
                        </div>
                    </Link>
                    <Link to="/campaigns/archived">
                        <div className={`selection ${active === 'Archived Campaigns' ? 'active' : ''}`}>
                            <ArchiveIcon />
                            <span>Campaign Archives</span>
                        </div>
                    </Link>
                </div>
            )}
            <div className="campaigns">{children}</div>
        </div>
    );
};
export default CampaignWrapper;
