import React from 'react';
import Typography from '../../components/Typography';
import InputField from '../../components/InputField';
import { dateIsValid, emailIsValid, getStates, phoneNumberIsValid, zipCodeIsValid } from '../../utils';
import Button from '../../components/Button';
import { PersonalDetails } from '../../models/Application';
import InputSelect from '../../components/InputSelect';
import {AuthService} from '../../api';
import ErrorInfo from '../../components/ErrorInfo';
import DateInput from '../../components/DateInput';

type Props = {
    data: PersonalDetails;
    handleSave: (data: PersonalDetails) => void;
    handleButtonClick: (action: string) => void;
};

const GettingStarted: React.FC<Props> = ({ data, handleSave, handleButtonClick }) => {
    const [personalDetails, setPersonalDetails] = React.useState(data);
    const [showError, setShowError] = React.useState(false);
    const [emailExists, setEmailExists] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setPersonalDetails(data);
    }, [data]);

    const handleSubmit = (): void => {
        const isValid =
            personalDetails.fname.length > 0 &&
            personalDetails.lname.length > 0 &&
            dateIsValid(personalDetails.dateOfBirth) &&
            emailIsValid(personalDetails.emailAddress) &&
            !emailExists &&
            phoneNumberIsValid(personalDetails.phoneNumber) &&
            personalDetails.addressLine1.length > 0 &&
            personalDetails.city.length > 0 &&
            zipCodeIsValid(personalDetails.zipCode) &&
            personalDetails.state.length > 0 &&
            personalDetails.country.length> 0 &&
            personalDetails.zipCode.length > 0 &&
            personalDetails.howSyndicate.length > 0;
        if (!isValid) {
            setShowError(true);
        } else {
            handleSave(personalDetails);
            handleButtonClick('NEXT');
        }
    };

    const states = getStates();

    const handleChange = (name: string, value: string | number) => {
        setPersonalDetails({ ...personalDetails, [name]: value });
    };

    const handleEmailBlurred = () => {
        if(emailIsValid(personalDetails.emailAddress)){
            setIsLoading(true);
            AuthService.doesEmailExists(personalDetails.emailAddress)
            .then((response) => {
                setEmailExists(response);
                setIsLoading(false);
            }).catch(() => {
                setEmailExists(false);
                setIsLoading(false);
            });
        }
    }

    return (
        <>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        First Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="fname"
                        value={personalDetails.fname}
                        error={showError && personalDetails.fname.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Last Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="lname"
                        value={personalDetails.lname}
                        error={showError && personalDetails.lname.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid-half">                
                <div>
                    <Typography variant="body">
                        Pronouns
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="preferredPronouns"
                        value={personalDetails.preferredPronouns}                        
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Date Of Birth <span className='typography--caption'>(MM/DD/YYYY)</span><span className="color-error">*</span>
                    </Typography>
                    <DateInput
                        onChange={handleChange}
                        value={personalDetails.dateOfBirth}
                        name="dateOfBirth"
                        error={showError && !dateIsValid(personalDetails.dateOfBirth)}
                        errorText="Please enter a valid date of birth"
                    />
                </div>
            </div>
            {emailExists && <><br/><ErrorInfo error="User with email address already exists. Please contact us if you are having issues accessing your account."/><br/></>}
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Email Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.emailAddress}
                        name="emailAddress"
                        type="email"
                        error={showError && !emailIsValid(personalDetails.emailAddress)}
                        errorText={'Invalid email address'}
                        onBlur={handleEmailBlurred}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Phone Number <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.phoneNumber}
                        name="phoneNumber"
                        error={showError && !phoneNumberIsValid(personalDetails.phoneNumber)}
                        errorText="Invalid phone number"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Street Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.addressLine1}
                        name="addressLine1"
                        error={showError && personalDetails.addressLine1.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">Apt/Unit/Suite</Typography>
                    <InputField onChange={handleChange} name="addressLine2" value={personalDetails.addressLine2} />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        City <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.city}
                        name="city"
                        error={showError && personalDetails.city.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        State <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={states}
                        value={personalDetails.state}
                        name="state"
                        placeholder="State"
                        onChange={handleChange}
                        error={showError && personalDetails.state.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Zip Code <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.zipCode}
                        name="zipCode"
                        type="number"
                        error={showError && !zipCodeIsValid(personalDetails.zipCode)}
                        errorText="Invalid zip Code"
                    />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        How did you hear about The Syndicate? <span className="color-error">*</span>
                    </Typography>
                    <Typography variant="caption">
                        (If you were referred please list the name of the person who referred you.)
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={personalDetails.howSyndicate}
                        name="howSyndicate"
                        error={showError && personalDetails.howSyndicate.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="action-container">                
                <Button loading={isLoading} color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default GettingStarted;
