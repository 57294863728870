import React from 'react';
import IconButton from '../../components/IconButton';
import Content, { ContentStatus } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import './article.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Typography from '../../components/Typography';

type Props = {
    content: Content;
    viewDetail: (id: string) => void;
};

const DefaultListItemTemplate: React.FC<Props> = ({ content, viewDetail}) => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;

    return (
        <div
            className={`article-item ${ContentStatus[content.status]}`}
        >
            {isEdit && <IconButton Icon={EditIcon} onClick={() => viewDetail(content.id as string)} />}
            <Typography variant="subheading">{content.title}</Typography>            
            <Typography variant="caption">{new Date(content.updatedAt as string).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
            <hr />
            <br />
            <div dangerouslySetInnerHTML={{ __html: content.data }}></div>
        </div>
    );
};

export default DefaultListItemTemplate;
