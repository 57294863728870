import { CampaignStatus } from './Campaign';
import SocialMedia  from './SocialMedia';

export default interface Project {
    id?: string;
    managerId: string;    
    managerName?:string;
    managerEmail?:string;
    status: ProjectStatus;
    type: ProjectType;
    name: string;
    description: string;
    clientName: string;
    photoUrl?: string;
    staffNote?: string;
    website?: string;
    socialMedias: Array<SocialMedia>;
    misc?: string;
    campaigns?: ProjectCampaign[];
    createdAt?: Date;
    updatedAt?:Date;
}

export enum ProjectStatus {
    Active = 1,
    Archived = 2,
    Completed = 3,
}

export enum ProjectType {
    'Competitive Event' = 0,
    'Day-of-Show' = 1,
    'Retail/Lifestyle Campaign' = 2,
    'Outdoor Postering' = 3,
    'Digital Marketing Campaigns' = 4,
    'Hybrid Campaign' = 5
}

export type ProjectCampaign = {
    id: string;
    name: string;
    status: CampaignStatus;
    startDate: string;
    endDate: string;
};

export type ProjectManager = {
    id: string;
    fname: string;
    lname: string;
    emailAddress: string;
};
