import React from 'react';
import InputSelect from '../../../components/InputSelect';
import Typography from '../../../components/Typography';
import { SurveyDropdownData } from '../../../models/Survey';

type Props = {
    value?: string;
    data: SurveyDropdownData;
    handleChange: (name: string, value: string) => void;
};

const SurveyDropdown: React.FC<Props> = ({ value, data, handleChange }) => {
    const getOptions = () => {
        const temp: { label: string; value: string }[] = [];
        if(data.options) {
            data.options.forEach((opt: string) => {
            temp.push({ label: opt, value: opt });
        })}
        return temp;
    };
    const options = getOptions();
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <InputSelect
                options={options}
                name={data.name}
                onChange={(name, value)=>handleChange(name, value as string)}
                value={value}
                placeholder="Select an option"
            />
        </div>
    );
};

export default SurveyDropdown;
