import React from 'react';
import Checkbox from '../../../components/Checkbox';
import Typography from '../../../components/Typography';
import { SurveyCheckboxData } from '../../../models/Survey';

type Props = {
    value?: string;
    data: SurveyCheckboxData;
    handleChange: (name: string, value: string) => void;
};

const SurveyCheckBox: React.FC<Props> = ({ value, data, handleChange }) => {
    const onChange = () => {
        if (value) {
            value === 'YES' ? handleChange(data.name, 'NO') : handleChange(data.name, 'YES');
        } else {
            handleChange(data.name, 'YES');
        }
    };
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <br />
            <Checkbox onChange={onChange} required={data.required} label={data.label} checked={value === 'YES'} />
            <br />
        </div>
    );
};

export default SurveyCheckBox;
