import React from 'react';
import ContentEditor from '../../components/Editor';
import InputField from '../../components/InputField';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import Content from '../../models/Content';

type Props = {
    handleChange: (name: string, value: string) => void;
    content: Content;
};
const EditContent: React.FC<Props> = ({ content, handleChange }) => {
    return (
        <div className="edit-content">
            <Typography variant="body">Title</Typography>
            <InputField name="title" value={content.title} onChange={handleChange} />
            <Typography variant="body">Subtitle</Typography>
            <TextArea name="description" value={content.description} onChange={handleChange} />
            <Typography variant="body">Content</Typography>
            <ContentEditor onChange={handleChange} value={content.data} name="data" />
        </div>
    );
};

export default EditContent;
