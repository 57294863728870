import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ActivityService, CampaignService } from '../../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/AlertDialog/state';
import Button from '../../../components/Button';
import Card, { CardActionBar, CardActionHeader } from '../../../components/Card';
import { DataLoadingContext } from '../../../components/DataLoading';
import Campaign, { CampaignStatus } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import { AuthContext } from '../../Authentication/state';
import CampaignInfo from './CampaignInfo';
import './campaigndetail.scss';
import CampaignUsers from './CampaignUsers';
import Typography from '../../../components/Typography';
import UserSubmissions from '../UserSubmissions';
import { ReactComponent as CompleteIcon } from './../../../assets/icons/check-circle.svg';
import { ReactComponent as ArchiveIcon } from './../../../assets/icons/archive.svg';
import IconButton from '../../../components/IconButton';
import Tag from '../../../components/Tag';
import { getColorByCampaignStatus } from '../../../utils/getColorByStatus';
import { NotificationActionType, NotificationContext } from '../../../components/Notification/state';

const CampaignDetail: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [campaign, setCampaign] = React.useState<Campaign>();
    const { id } = useParams<{ id: string }>();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    const [showApplySuccess, setShowApplySuccess] = React.useState(false);
    const [reload, setReloading] = React.useState(false);

    const history = useHistory();

    React.useEffect(() => {
        getData();
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        CampaignService.getCampaign(id)
            .then((response) => {
                setCampaign(response);
            })
            .finally(() => toggleLoading(false));
    };

    const handleOpenDeleteCampaignDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Campaign',
                description: `Any user associated with the campaign and reports submitted to the campaign will be affected. Are you sure you want to delete campaign ${
                    campaign?.name as string
                }?`,
                handleConfirm: () => handleDelete(campaign?.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        CampaignService.deleteCampaign(id).then(() => {
            history.push('/campaigns');
        });
    };

    const handleApply = () => {
        ActivityService.applyForCampaign(id).finally(() => {
            getData();
            setShowApplySuccess(true);
        });
    };

    const handleStatusChange=(status: CampaignStatus)=>{
        toggleLoading(true);
        CampaignService.updateCampaignStatus(campaign?.id as string, status).then(()=>{
            getData();
            setReloading(true);
            setTimeout(()=>setReloading(false), 1000);
        }).catch((e)=>notificationDispatch({
            type: NotificationActionType.OPEN,
            payload: e.toString()
        })).finally(()=>toggleLoading(false));
    }

    const handleOpenCompleteDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Complete Campaign',
                description: `Please make sure all submitted reports are evaluated. Synner activity with unapproved reports will be marked as Incomplete and will not be compensated. Are you sure you want to continue?`,
                handleConfirm: () => handleStatusChange(CampaignStatus.Completed),
            },
        });
    };

    const handleOpenArchiveDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Archive Campaign',
                description: `Please make sure all submitted reports are evaluated. Synner activity with unapproved reports will be marked as Incomplete and will not be compensated. Are you sure you want to continue?`,
                handleConfirm: () => handleStatusChange(CampaignStatus.Archived),
            },
        });
    };

    return (
        <div className="campaign-detail">
            {campaign && (
                <>
                    <Card>
                        <CardActionHeader>
                            <Tag
                                label={CampaignStatus[campaign.status]}
                                color={getColorByCampaignStatus(campaign.status)}
                            />
                            <div>
                                <Typography variant="subheading" color="secondary" weight={600}>
                                    {campaign.projectName}
                                </Typography>
                                <Typography variant="subtitle" color="secondary">
                                    {campaign.name}
                                </Typography>
                            </div>
                            <>
                                {campaign.status !== CampaignStatus.Archived && isAdminManager &&(
                                    <div className="d-flex">
                                        {campaign.status !== CampaignStatus.Completed && (
                                            <IconButton
                                                Icon={CompleteIcon}
                                                color="success"
                                                tooltip="Complete campaign"
                                                onClick={handleOpenCompleteDialog}
                                            />
                                        )}
                                        <IconButton
                                            Icon={ArchiveIcon}
                                            color="grey"
                                            tooltip="Archive campaign"
                                            onClick={handleOpenArchiveDialog}
                                        />
                                    </div>
                                )}
                            </>
                        </CardActionHeader>
                        <CampaignInfo campaign={campaign} />
                        <>
                            {isAdminManager && (
                                <CardActionBar>
                                    <>
                                        <Button color="error" onClick={handleOpenDeleteCampaignDialog}>
                                            Delete
                                        </Button>
                                        {campaign.status === CampaignStatus.Ready && (
                                            <Link to={`/campaigns/${campaign.id}/edit`}>
                                                <Button color="secondary">Edit</Button>
                                            </Link>
                                        )}
                                    </>
                                </CardActionBar>
                            )}
                        </>
                    </Card>
                    {user && user.role === UserRole.Synner && campaign.isInCampaign && (
                        <div className="campaign-user-report">
                            <Card>
                                <Typography variant="subtitle">YOUR REPORTS</Typography>
                                <UserSubmissions campaignId={campaign.id as string} />
                            </Card>
                        </div>
                    )}
                    {user &&
                        user.role === UserRole.Synner &&
                        campaign.status === CampaignStatus.Ready &&
                        !campaign.isInCampaign && (
                            <div className="campaign-user-report">
                                {showApplySuccess && (
                                    <>
                                        <Typography variant="subtitle" textAlign="center" color="success">
                                            You have successfully applied to the campaign. You can get started once your
                                            request is approved.
                                        </Typography>
                                        <br />
                                    </>
                                )}
                                <Button
                                    onClick={handleApply}
                                    color="primary"
                                    className="apply-to-campaign-button"
                                    disabled={showApplySuccess}
                                >
                                    Apply To Campaign
                                </Button>
                            </div>
                        )}
                    {(isAdminManager || campaign.isInCampaign) && !reload &&(
                        <CampaignUsers campaignId={id} status={campaign.status} stipend={campaign.stipend}/>
                    )}
                </>
            )}
        </div>
    );
};
export default CampaignDetail;
