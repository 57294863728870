import React from 'react';
import Checkbox from '../../../components/Checkbox';
import InputField from '../../../components/InputField';
import InputSelect from '../../../components/InputSelect';
import TextArea from '../../../components/TextArea';
import Typography from '../../../components/Typography';
import { ProjectType } from '../../../models/Project';
import { SurveyStatus } from '../../../models/Survey';
import { getListFromEnum } from '../../../utils';
import { SurveyBuilderActionType, surveyBuilderContext } from './state';

const SurveyIntro: React.FC = () => {
    const { state, dispatch } = React.useContext(surveyBuilderContext);

    const { title, description, type, isDefaultForType, status, totalSubmissions, id } = state.survey;

    const handleChange = (name: string, value: string | boolean | number) => {
        dispatch({
            type: SurveyBuilderActionType.SET_SURVEY,
            payload: { ...state.survey, [name]: value },
        });
    };

    const surveyTypes =   getListFromEnum(Object.values(ProjectType));

    const surveystatuses = getListFromEnum(Object.values(SurveyStatus));

    return (
        <div className="survey-intro form-grid-half">
            <div>
                <Typography variant="subtitle">Title</Typography>
                <InputField onChange={handleChange} name="title" value={title} />
                <Typography variant="subtitle">Description</Typography>
                <TextArea onChange={handleChange} name="description" value={description} />
                <Typography variant="subtitle">Required number of submissions</Typography>
                <InputField onChange={handleChange} name="totalSubmissions" value={totalSubmissions} type="number"/>
            </div>
            <div>
                <Typography variant="subtitle">Campaign Type</Typography>
                <InputSelect
                    options={surveyTypes}
                    onChange={handleChange}
                    value={type}
                    placeholder="select type"
                    name="type"
                />
                {id && <><Typography variant="subtitle">Status</Typography>
                <InputSelect
                    options={surveystatuses}
                    onChange={handleChange}
                    value={status}
                    placeholder="select type"
                    name="status"
                />
                </>}
                <Checkbox
                    onChange={() => handleChange('isDefaultForType', !isDefaultForType)}
                    checked={isDefaultForType}
                    label="Is this survey going to be one of the default surveys for the project type?"
                />                
            </div>
        </div>
    );
};
export default SurveyIntro;
