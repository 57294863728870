import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../components/Typography';
import DashboardCalander from '../Calendar/DashboardCalander';
import ActionableItems from './ActionableItems';
import CampaignStats from './CampaignStats';
import './dashboard.scss';
import FeaturedAnnouncement from './FeaturedAnnouncement';

const Dashboard: React.FC = () => {
    const history = useHistory();

    const goToPage = (path: string) => {
        history.push(path);
    };
    return (
        <div className="dashboard">
            <Typography variant="heading" color="primary" textAlign="center">
                Welcome Back!
            </Typography>
            <br />
            <div className="dashboard-grid">
                <div>
                    <CampaignStats/>
                    <div className="dashboard-grid-item">
                        <div className="dashboard-grid-item-header">
                            <div>
                                <Typography variant="subtitle">Your Calendar</Typography>
                                <Typography variant="caption">{new Date().toLocaleString('default', { month: 'long' })} {new Date().getFullYear()}</Typography>
                            </div>
                            <p
                                tabIndex={0}
                                onClick={() => goToPage('/calendar')}
                                onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) =>
                                    e.key === 'Enter' && goToPage('/calendar')
                                }
                                className="typography--caption text-action color-info"
                            >
                                View calendar
                            </p>
                        </div>
                        <DashboardCalander/>
                    </div>
                </div>
                <div>
                    <ActionableItems />
                    <FeaturedAnnouncement/>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
