import React from 'react';

const  useDebounse=(value:unknown, delay:number):unknown => {
  const [debounsedValue, setDebounsedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebounsedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debounsedValue;
}
export default useDebounse;
