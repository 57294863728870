import { CampaignSurvey } from '../models/Campaign';
import Survey, { SurveyElement } from '../models/Survey';
import instance from './instance';

function getSurveysForCampaign(campaignId: string): Promise<Survey[]> {
    return instance.get(`/surveys/campaign/${campaignId}`).then((response) => {
        const temp: Survey[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.forEach((survey: any) => {
            const elements: SurveyElement[] = JSON.parse(survey.elements);
            temp.push({ ...survey, elements: elements });
        });
        return temp;
    });
}

function getSurveys(): Promise<Survey[]> {
    return instance.get(`/surveys`).then((response) => {
        const temp: Survey[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.forEach((survey: any) => {
            const elements: SurveyElement[] = JSON.parse(survey.elements);
            temp.push({ ...survey, elements: elements });
        });
        return temp;
    });
}

function getSurvey(id: string): Promise<Survey> {
    return instance.get(`/survey/${id}`).then((response) => {
        const temp: SurveyElement[] = JSON.parse(response.data.elements);
        return { ...response.data, elements: temp };
    });
}

function deleteSurvey(id: string): Promise<boolean> {
    return instance.delete(`/survey/${id}`).then((response) => {
        return response.data;
    });
}

function updateSurvey(survey: Survey): Promise<boolean> {
    const request: unknown = {
        ...survey,
        elements: JSON.stringify(survey.elements),
        status: parseInt(survey.status.toString()),
        type: parseInt(survey.type.toString()),
    };
    return instance.put('survey', request).then((response) => {
        return response.data;
    });
}

function createSurvey(survey: Survey): Promise<boolean> {
    const request: unknown = {
        ...survey,
        elements: JSON.stringify(survey.elements),
        status: parseInt(survey.status.toString()),
        type: parseInt(survey.type.toString()),
    };
    return instance.post('survey', request).then((response) => {
        return response.data;
    });
}

function getAvailableSurveyList(): Promise<CampaignSurvey[]> {
    return instance.get('/surveys/list').then((response) => {
        return response.data;
    });
}

export {
    getSurveysForCampaign,
    getSurvey,
    getSurveys,
    deleteSurvey,
    updateSurvey,
    createSurvey,
    getAvailableSurveyList,
};
