import React from 'react';
import ContentManager from '.';
import { ContentService } from '../../api';
import EditablePage from '../../components/EditablePage';
import Typography from '../../components/Typography';
import Content, { ContentStatus } from '../../models/Content';
import { useParams } from 'react-router-dom';
import './article.scss';

const DefaultSingleTemplate: React.FC = () => {
    const [content, setContent] = React.useState<Content | null>(null);

    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        ContentService.getContent(id).then((response) => {
            setContent(response);
        });
    }, [id]);

    return (
        <EditablePage>
            <article>
                {content && (
                    <ContentManager content={content}>
                        <div className={`article ${ContentStatus[content.status]}`}>
                        <Typography variant="subheading">{content.title}</Typography>
                        <hr/>
                        <br/>
                        <div                            
                            dangerouslySetInnerHTML={{ __html: content.data }}
                        ></div>
                        </div>
                        
                    </ContentManager>
                )}
            </article>
        </EditablePage>
    );
};

export default DefaultSingleTemplate;
