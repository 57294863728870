import React from 'react';
import { SurveyBuilderActionType } from '.';
import { ProjectType } from '../../../../models/Project';
import Survey, { SurveyElement, SurveyElementType, SurveyStatus } from '../../../../models/Survey';
import { SurveyBuilderAction } from './actions';

type SurveyBuilderReducerState = {
    survey: Survey;
    selectedElement: SurveyElement | null;
    selectedElementIndex: number;
};

const initSurvey: Survey = {
    isDefaultForType: false,
    type: ProjectType['Day-of-Show'],
    status: SurveyStatus.Draft,
    totalSubmissions: 1,
    title: '',
    elements: [],
};

export const initialState: SurveyBuilderReducerState = {
    survey: initSurvey,
    selectedElement: null,
    selectedElementIndex: -1,
};

export function surveyBuilderReducer(
    state: SurveyBuilderReducerState,
    action: SurveyBuilderAction,
): SurveyBuilderReducerState {
    switch (action.type) {
        case SurveyBuilderActionType.SET_SURVEY:
            return { ...state, survey: action.payload };
        case SurveyBuilderActionType.ADD_SURVEY_ELEMENT:
            const name = `Q${Date.now() + Math.random()}`;
            const newElement = getNewElementForType(action.payload, name);
            const elements = newElement ? [...state.survey.elements, newElement] : state.survey.elements;
            return {
                ...state,
                survey: { ...state.survey, elements: elements },
                selectedElement: newElement,
                selectedElementIndex: state.survey.elements.length,
            };
        case SurveyBuilderActionType.SELECT_SURVEY_ELEMENT:
            return {...state, selectedElement: state.survey.elements[action.payload], selectedElementIndex: action.payload};
        case SurveyBuilderActionType.REMOVE_SURVEY_ELEMENT:
            case SurveyBuilderActionType.SELECT_SURVEY_ELEMENT:
                const temp = [...state.survey.elements];
                temp.splice(action.payload, 1);
                if(action.payload === state.selectedElementIndex) {
                    return {...state, survey: {...state.survey, elements: temp}, selectedElement: null, selectedElementIndex: -1}
                } else {
                    return {...state, survey: {...state.survey, elements: temp}};
                }            
        default:
            return state;
    }
}

const getNewElementForType = (type: SurveyElementType, name: string): SurveyElement | null => {
    switch (type) {
        case SurveyElementType.Checkbox:
            return {
                type: SurveyElementType.Checkbox,
                metaData: {
                    name: name,
                    title: `checkbox`,
                    label: `label`
                },
            };
        case SurveyElementType.Dropdown:
            return {
                type: SurveyElementType.Dropdown,
                metaData: {
                    name: name,
                    title: `dropdown`,
                    options: [],
                },
            };
        case SurveyElementType.FileUpload:
            return {
                type: SurveyElementType.FileUpload,
                metaData: {
                    name: name,
                    title: `file upload`,
                },
            };
        case SurveyElementType.Input:
            return {
                type: SurveyElementType.Input,
                metaData: {
                    name: name,
                    title: `text input`,
                },
            };
        case SurveyElementType.TextArea:
            return {
                type: SurveyElementType.TextArea,
                metaData: {
                    name: name,
                    title: `paragraph input`,
                },
            };
        case SurveyElementType.RadioButton:
            return {
                type: SurveyElementType.RadioButton,
                metaData: {
                    name: name,
                    title: `radio`,
                    options: [],
                },
            };
        case SurveyElementType.WebContent:
            return {
                type: SurveyElementType.WebContent,
                metaData: '<p>Add Content here</p>'
            }
        default:
            return null;
    }
};

export const surveyBuilderContext = React.createContext<{
    state: SurveyBuilderReducerState;
    dispatch: React.Dispatch<SurveyBuilderAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
