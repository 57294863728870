import React from 'react';
import {  UserService } from '../../../api';
import Avatar from '../../../components/Avatar';
import Typography from '../../../components/Typography';
import { AvailableSynner } from '../../../models/Invitation';
import Profile from '../../../models/Profile';
import { formatLocation } from '../../../utils';
import UserPastActivities from '../../Users/RecentCompletedCampaigns';

const SynnerDetail: React.FC<{ synner: AvailableSynner }> = ({ synner }) => {
    const [profile, setProfile] = React.useState<Profile | null>(null);

    React.useEffect(() => {
        UserService.getUser(synner.id).then((response) => {
            setProfile(response);
        });
    }, [synner]);

    return (
        <div className="synner-detail">
            <div className="intro-detail">
                <Avatar
                    size="medium"
                    name={synner.name}
                    source={profile ? profile.photoUrl : 'https://via.placeholder.com/150'}
                />
                <div>
                    <Typography variant="body">{synner.name}</Typography>
                    <Typography variant="caption">{synner.distanceFromEvent} miles away from campaign</Typography>
                </div>
                <div>
                    <div className="event-number">
                        <span className="number">{profile?.totalCompletedCampaigns}</span>
                        <br />
                        <span className="text">campaigns</span>
                    </div>
                </div>
            </div>
            {profile && (
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">Email</Typography>
                        <Typography variant="caption">{profile.emailAddress}</Typography>
                        <Typography variant="body">Phone number</Typography>
                        <Typography variant="caption">{profile.phoneNumber}</Typography>
                    </div>

                    <div>
                        <Typography variant="body">Address</Typography>
                        <Typography variant="caption">
                            {formatLocation(
                                profile.addressLine1,
                                profile.addressLine2,
                                profile.city,
                                profile.state,
                                profile.country,
                                profile.zipCode,
                            )}
                        </Typography>
                    </div>
                </div>
            )}
            <UserPastActivities id={synner.id}/>            
        </div>
    );
};

export default SynnerDetail;
