import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import CampaignWrapper from './CampaignWrapper';
import FullGridList from './CampaignList/FullGridList';
import MiniGridList from './CampaignList/MiniGridList';
import { useWindowDimensions } from '../../utils';
import { CampaignService } from '../../api';
import Campaign from '../../models/Campaign';
import { useLocation } from 'react-router-dom';

const Campaigns: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);
    const { width } = useWindowDimensions();
    const location = useLocation();

    React.useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = () => {
        toggleLoading(true);
        CampaignService.getCampaigns(location.pathname === "/campaigns/archived")
            .then((response) => {
                setCampaigns(response);
            })
            .finally(() => toggleLoading(false));
    };

    return (
        <CampaignWrapper>
            {width > 1000 ? (
                <FullGridList data={campaigns} getData={getData} />
            ) : (
                <MiniGridList data={campaigns} getData={getData} />
            )}
        </CampaignWrapper>
    );
};

export default Campaigns;
