import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation} from 'react-router-dom';
import { UserRole } from '../../models/User';
import { AuthContext } from '../../containers/Authentication/state';

type Props = RouteProps & {
    component: React.ElementType;
    roles: Array<UserRole>;
};

export const getRedirectRoute = (role: UserRole = UserRole.Visitor): string => {
    let response = '/';
    switch (role) {
        case UserRole.Visitor:
            response = '/';
            break;
        case UserRole.OnBoarding:
            response = '/profile';
            break;
        case UserRole.Admin:
        case UserRole.Manager:
            response = '/campaigns';
            break;
        default:
            response = '/dashboard';
    }
    return response;
};

const PrivateRoute: React.FC<Props> = ({ component: Component, roles, ...rest }) => {
    const { state } = useContext(AuthContext);
    const location = useLocation();

    const hasPermission = (): boolean => {
        const role = state.user?.role ? state.user.role : UserRole.Visitor;
        if (roles.includes(role)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Route
            {...rest}
            render={(props) => (hasPermission() ? <Component {...props} />: <Redirect to={{pathname: getRedirectRoute(state.user?.role), state: {from: location}}} />)}
        ></Route>
    );
};

export default PrivateRoute;
