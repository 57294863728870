import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import Authentication from './containers/Authentication';
import './assets/styles/main.scss';
import { notificationInitialState, NotificationContext, notificationReducer } from './components/Notification/state';
import Notification from './components/Notification';
import { AlertDialogContext, alertDialogInitialState, alertDialogReducer } from './components/AlertDialog/state';
import AlertDialog from './components/AlertDialog';
import { setUpBugheard } from './utils/analytics';
import { useWindowDimensions } from './utils';

const App: React.FC = () => {
    const [notificationState, notificationDispatch] = React.useReducer(notificationReducer, notificationInitialState);
    const [alertDialogState, alertDialogDispatch] = React.useReducer(alertDialogReducer, alertDialogInitialState);
    const width = useWindowDimensions().width;
    
    React.useEffect(()=> {
        if(width >= 1000){
            setUpBugheard();
        }
    }, [width])

    return (
        <main>
            <Router>
                <NotificationContext.Provider value={{ notificationState, notificationDispatch }}>
                    <AlertDialogContext.Provider value={{alertDialogState, alertDialogDispatch}}>
                        <Authentication>
                            <AppRouter />
                            <Notification />
                            <AlertDialog/>
                        </Authentication>
                    </AlertDialogContext.Provider>
                </NotificationContext.Provider>
            </Router>
        </main>
    );
};

export default App;
