import React from 'react';
import Checkbox from '../../../components/Checkbox';
import IconButton from '../../../components/IconButton';
import Typography from '../../../components/Typography';
import { AvailableSynner } from '../../../models/Invitation';
import { InvitationActionType, InvitationContext } from './state';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import Modal from '../../../components/Modal';
import SynnerDetail from './SynnerDetail';
import Tag from '../../../components/Tag';
import { ProjectType } from '../../../models/Project';
import { formatDate } from '../../../utils';

const AvailableList: React.FC = () => {
    const { state, dispatch } = React.useContext(InvitationContext);
    const { availableSynners, selectedSynners} = state;
    const [detailSynner, setDetailSynner] = React.useState<AvailableSynner|null>(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleSelection = (synner: AvailableSynner) => {
        dispatch({
            type: InvitationActionType.SET_SELECTED_SYNNER,
            payload: synner,
        });
    };

    const handleViewProfile=(synner:AvailableSynner)=> {
        setDetailSynner(synner);
        setIsModalOpen(true);
    }

    const handleCloseModal=()=> {
        setDetailSynner(null);
        setIsModalOpen(false);
    }

    return (
        <><div className="available-list">
            {availableSynners.map((el, index) => (
                <div className={`available-item ${(typeof state.workType ==="number" && el.totalCompletedCampaigns === 0)? 'inactive': ''}`} key={index}>
                    <div>
                        <Checkbox
                            onChange={() => handleSelection(el)}
                            label=""
                            checked={selectedSynners.findIndex((sel) => sel.id === el.id) !== -1}
                        />
                    </div>
                    <div className='form-grid-half'>
                        <div>
                            <Typography variant="body" weight={500}>
                                {el.name}
                            </Typography>
                            <Typography variant="body">{el.emailAddress}</Typography>
                            <Typography variant="body">{typeof state.workType ==="number"? `Total ${ProjectType[state.workType].replace(/[A-Z-_\&](?=[a-z0-9]+)|[A-Z-_/\&]+(?![a-z0-9])/g, ' $&').trim()}: ${el.totalCompletedCampaigns}`: `Total campaigns: ${el.totalCompletedCampaigns}`}</Typography>
                            <Typography variant='caption'>member since: {formatDate(el.dateRegistered)}</Typography>
                        </div>
                        <div className='tags'>
                            {
                                
                               el.tags && el.tags.split(',').map((el, index)=><Tag label={el} key={index} color="secondary"/>)
                            }
                        </div>
                    </div>
                    <div>
                        <IconButton Icon={UserIcon} color="info" tooltip='Profile details' onClick={()=>handleViewProfile(el)}/>
                    </div>
                    <div className='miles-distance'>
                        <Typography variant="caption">{el.distanceFromEvent} miles away</Typography>
                    </div>
                </div>
            ))}
        </div>
        {detailSynner && <Modal isOpen={isModalOpen} close={handleCloseModal} size="large" title="Profile Details">
            <SynnerDetail synner={detailSynner}/>
        </Modal>}
        </>
    );
};
export default AvailableList;
