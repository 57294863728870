import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './invitation.scss';
import { initialState, InvitationActionType, InvitationReducer } from './state';
import { InvitationContext } from './state/reducer';
import CampaignSummary from './CampaignSummary';
import SearchFilters from './SearchFilters';
import SearchBar from './SearchBar';
import Selections from './Selections';
import { ActivityService, CampaignService } from '../../../api';
import { AvailableSynner } from '../../../models/Invitation';
import AvailableList from './AvailableList';
import Pagination from './Pagination';
import EmptyList from './EmptyList';
import { CampaignStatus } from '../../../models/Campaign';

const Invitation: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [state, dispatch] = React.useReducer(InvitationReducer, initialState);
    const {total, tags, workType, distance, searchText, page, pageSize, requestTrigger} = state;
    const [loading, setLoading]=React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        CampaignService.getCampaign(id).then((response) => {
            if(response.status == CampaignStatus.Ready) { //redirect if campaign status is not set to ready. Can't invite synners in other status
                dispatch({
                    type: InvitationActionType.SET_CAMPAIGN_DETAIL,
                    payload: response,
                });
                dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH})
            } else {
                history.push(`/campaigns/${id}`);
            }            
        });
    }, [id]);

    const getData = () => {
        setLoading(true);
        ActivityService.getAvailableSynners({
            campaignId: id,
            distance: distance,
            tags: tags,
            page: page,
            pageSize: pageSize,
            searchText: searchText,
            workType: workType,
        }).then((response: { items: AvailableSynner[]; total: number }) => {
            dispatch({
                type: InvitationActionType.SET_AVAILABLE_SYNNERS,
                payload: response.items,
            });
            dispatch({
                type: InvitationActionType.SET_TOTAL,
                payload: response.total,
            });
            dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH})
        }).finally(()=> {
            setLoading(false);
        })
    };
    
    useEffect(()=>{
        if(requestTrigger) {            
            getData();
        }
    }, [requestTrigger])

    useEffect(()=> {
        dispatch({
            type: InvitationActionType.SET_PAGE,
            payload: {page: 1, pageSize: 20}
        })
    }, [tags, searchText, distance, workType])


    const exportToCSV =()=> {
        setLoading(true);
        ActivityService.getAvailableSynnersCSV({
            campaignId: id,
            distance: distance,
            tags: tags,
            page: page,
            pageSize: pageSize,
            searchText: searchText,
            workType: workType,
        }).finally(()=> {
            setLoading(false);
        })
    }


    return (
        <div className="invitation">
            <InvitationContext.Provider value={{ state, dispatch }}>
                <CampaignSummary />
                <SearchFilters exportToCSV={exportToCSV}/>
                <SearchBar isLoading={loading}/>
                <Selections />                                
                <Pagination/>
                <EmptyList loading={loading} total={total}/>
                <AvailableList/>                
                <Pagination/>
            </InvitationContext.Provider>
        </div>
    );
};

export default Invitation;
