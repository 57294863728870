import React from 'react';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputField from '../../../components/InputField';
import TextArea from '../../../components/TextArea';
import Typography from '../../../components/Typography';
import { SurveyRadioData } from '../../../models/Survey';

type Props = {
    data: SurveyRadioData;
    handleSubmit: (data: SurveyRadioData) => void;
};

const SurveyRadioButtonDetail: React.FC<Props> = ({ data, handleSubmit }) => {
    const [detail, setDetail] = React.useState(data);
    const [newOption, setNewOption] = React.useState('');

    React.useEffect(() => {
        setDetail(data);
    }, [data]);

    const handleChange = (name: string, value: string | boolean) => {
        setDetail({ ...detail, [name]: value });
    };

    const addOption = () => {
        setDetail({ ...detail, options: [...detail.options, newOption] });
        setNewOption('');
    };

    const removeOption = (index: number) => {
        const temp = [...detail.options];
        temp.splice(index, 1);
        setDetail({ ...detail, options: temp });
    };

    return (
        <div className="detail">
            <Typography variant="subtitle" color="primary">
                DROPDOWN DETAILS
            </Typography>
            <hr />
            <Typography variant="body">Please provide title for the form field.</Typography>
            <InputField onChange={handleChange} name="title" value={detail.title} />
            <Typography variant="body">Please provide description if applicable.</Typography>
            <TextArea onChange={handleChange} name="description" value={detail.description} />
            <Typography variant="body">Please provide options for dropdown.</Typography>
            <div className="multiple-input">
                <InputField
                    onChange={(_name: string, value: string) => setNewOption(value)}
                    name="radio-options"
                    value={newOption}
                />
                <Button color="secondary" onClick={addOption}>
                    Add
                </Button>
            </div>
            <div className="survey-multiple-input-values">
                {detail.options.map((option, index) => (
                    <div className="option" key={index}>
                        <p>{option}</p>
                        <span className="close-icon" onClick={() => removeOption(index)} title ="remove">
                            &#10006;
                        </span>
                    </div>
                ))}
            </div>
            <Checkbox
                onChange={() => handleChange('required', !detail.required)}
                label={'This is a required field'}
                checked={detail.required ? detail.required : false}
            />
            <div className="action-container">
                <Button onClick={() => handleSubmit(detail)}>Update Item</Button>
            </div>
        </div>
    );
};

export default SurveyRadioButtonDetail;
