import AlertDialogModel from "../AlertDialogModel";


export enum AlertDialogActionType {
    OPEN,
    CLOSE,
}

//actions
export type AlertDialogAction =
    | { type: AlertDialogActionType.OPEN; payload: AlertDialogModel }
    | { type: AlertDialogActionType.CLOSE };
