import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import listPlugin from '@fullcalendar/list';
import { CalendarService } from '../../api';
import CalendarEvent from '../../models/CalendarEvent';
import { useHistory } from 'react-router-dom';
const DashboardCalander: React.FC = () => {
    const [events, setEvents] = React.useState<CalendarEvent[]>([]);
    const history = useHistory();
    React.useEffect(() => {
        getData();
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (info: any) => {
        history.push(`/campaigns/${info.event.id}/detail`);
    };

    const getData = () => {
        CalendarService.getMyCalendarEvents().then((response) => {
            setEvents(response);
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventContent = (e: any) => {
        const temp = e.event.extendedProps;
        return `${temp.projectName} - ${e.event.title}`;
    };
    return (
        <FullCalendar
            plugins={[listPlugin]}
            initialView="listMonth"
            headerToolbar={false}
            eventClick={handleClick}
            events={events}
            allDayText=""
            eventContent={eventContent}
        />
    );
};

export default DashboardCalander;
