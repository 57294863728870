import React from 'react';
import { ContentService } from '../../api';
import Content, { ContentType } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import EditablePage from '../../components/EditablePage';
import DefaultListItemTemplate from '../ContentManager/DefaultListItemTemplate';

const Contact: React.FC = () => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const [contacts, setcontacts] = React.useState<Content[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        ContentService.getContents(ContentType.Contact).then((response) => {
            setcontacts(response.items);
        });
    }, []);

    return (
        <EditablePage>
            <div className="contacts">
                {isEdit && (
                    <Button
                        onClick={() => history.push('/contact/create-contact')}
                        color="primary"
                        className="d-block mx-auto"
                    >
                        Create New Contact
                    </Button>
                )}
                {contacts.map((content, index) => (
                    <DefaultListItemTemplate content={content} key={index} viewDetail={(id)=>history.push(`/contact/${id}`)}/>
                ))}
            </div>
        </EditablePage>
    );
};

export default Contact;
