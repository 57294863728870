import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import Avatar from '../../components/Avatar';

const ProjectAvatar: React.FC<GridCellProps> = (props) => {
    return <Avatar size="medium" name={props.dataItem.name} source={props.dataItem.photoUrl? props.dataItem.photoUrl: `https://via.placeholder.com/140?text=P`} shape='square'/>;
};

export default ProjectAvatar;

