import instance from './instance';
import Profile, { SynnerTag } from '../models/Profile';
import { UserRole } from '../models/User';

function getSynners(): Promise<Array<Profile>> {
    return instance.get('synners').then((response) => {
        response.data.forEach((user:Profile) => {
            user.dateRegistered = new Date(user.dateRegistered)
        });
        return response.data;
    });
}
function getAllUsers(showDisabled?:boolean): Promise<Array<Profile>> {
    const request = showDisabled ? instance.get('users?showDisabled=true'): instance.get('users');
    return request.then((response)=> {
        response.data.forEach((user:Profile) => {
            user.dateRegistered = new Date(user.dateRegistered)
        });
        return response.data;
    })
}

function getUsers(role: UserRole, showDisabled?:boolean):Promise<Array<Profile>>
{
    const requests = role == UserRole.Admin ? getAllUsers(showDisabled) :getSynners();    

    return requests.then((response)=>{
        return response;
    });

}
function getUser(id: string): Promise<Profile> {
    return instance.get(`user/${id}`).then((response) => {
        return response.data;
    });
}

function changeRole(id: string, role: string): Promise<boolean> {
    return instance
        .put(`auth/role`, {
            id: id,
            role: role,
        })
        .then((response) => {
            return response.data;
        });
}

function updateTags(tags: string[], id: string): Promise<boolean> {
    return instance
        .put('tags', {
            id: id,
            tags: tags,
        })
        .then((response) => {
            return response.data;
        });
}

function getTags(): Promise<SynnerTag[]> {
    return instance.get('tags').then((response) => {
        return response.data;
    });
}

function editTag(tag: SynnerTag): Promise<boolean> {
    return instance.put('tag', tag).then((response) => {
        return response.data;
    });
}

function disableUser(id:string, comments: string, isAlumni: boolean): Promise<boolean> {
    return instance.delete(`user/disable/${id}?isAlumni=${isAlumni}&comments=${comments}`).then((response)=> {
        return response.data;
    })
}

function enableUser(id:string):Promise<boolean> {
    return instance.put(`user/enable/${id}`).then((response)=> {
        return response.data;
    })
}

function sendMigrationEmail(id:string):Promise<boolean> {
    return instance.get(`auth/send-migration-email/${id}`).then((response)=> {
        return response.data;
    })
}

export { getSynners, getUser, changeRole, getTags, updateTags, editTag, getUsers, disableUser, enableUser, sendMigrationEmail };
