import React from 'react';
import { ApplicationService } from '../../api';
import './applicants.scss';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridRowClickEvent,
    GridDataStateChangeEvent,
    GridFilterCellProps,
    GridRowProps,
} from '@progress/kendo-react-grid';
import { Applicant, ApplicantionStatus } from '../../models/Application';
import {  process, State } from '@progress/kendo-data-query';
import Tag from '../../components/Tag';
import GridDropDownFilter from '../../components/GridDropDownFilter';
import {formatDate, formatDateOnly, getListFromEnum, useWindowDimensions} from '../../utils';
import Typography from '../../components/Typography';
import { DataLoadingContext } from '../../components/DataLoading';
import pagerSettings, { pagerMiniSettings } from '../../utils/pagerSettings';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import ApplicantActions from './ApplicantActions';

const Applicants: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [applicants, setApplicants] = React.useState<Applicant[]>([]);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { width } = useWindowDimensions();
    const history = useHistory();
    React.useEffect(() => {
        getData();
    }, []);

    const getData =()=> {
        toggleLoading(true);
        ApplicationService.getApplicants().then((response) => {
            setApplicants(response);
        }).finally(()=>toggleLoading(false));
    }

    const handleSelection = (applicant: Applicant) => {
        history.push(`/applicants/${applicant.id}`);
    };

    const handleRowSelection = (props: GridRowClickEvent) => {
        handleSelection(props.dataItem);
    };

    

    const statuses = getListFromEnum(Object.values(ApplicantionStatus)).filter((el)=>el.value !== Number(ApplicantionStatus.Approved));

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const DOBCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem.dateOfBirth)}</td>;
    };

    const StudentCell = (props: GridCellProps) => {
        return <td>{props.dataItem[props.field as string] ? <p className='text-center'><CheckIcon className='color-success' style={{width: '16px'}}/></p> : ''}</td>;
    };

    const Status = (props: GridCellProps) => {
        return (
            <td>
                <Tag label={ApplicantionStatus[props.dataItem.status]} color={getTypeByStatus(props.dataItem.status)} />
            </td>
        );
    };

    const MobileCell = (props: GridCellProps) => {
        return (
            <td className="grid-item-mobile grid-item-mobile">
                <div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Name
                        </Typography>
                        <Typography variant="caption">{props.dataItem.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Email Address
                        </Typography>
                        <Typography variant="caption">{props.dataItem.emailAddress}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Student
                        </Typography>
                        <Typography variant="caption">{props.dataItem.isStudent ? 'Yes' : 'No'}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Date of Birth
                        </Typography>
                        <Typography variant="caption">
                        {formatDateOnly(props.dataItem.dateOfBirth)}<br/>
                        {props.dataItem.age} years old.
                        </Typography>
                    </div>
                </div>
                <div className='grid-actions'>
                    <ApplicantActions name={props.dataItem.name} id={props.dataItem.id} getData={getData}/>
                </div>
            </td>
        );
    };

    const getTypeByStatus = (status: ApplicantionStatus) => {
        switch (status) {
            case ApplicantionStatus.Applicant:
                return 'info';
            case ApplicantionStatus.Approved:
                return 'success';
            case ApplicantionStatus.Denied:
                return 'error';
            case ApplicantionStatus.Interview:
                return 'warning';
            default:
                return 'primary';
        }
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        const status: ApplicantionStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `application--${getTypeByStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };
      

    return (
        <div className="applicants">
            <div className="applicants-list">
                <Grid
                    style={{ height: '100%' }}
                    sortable={true}
                    pageable={width>1000 ?pagerSettings: pagerMiniSettings}
                    filterable={true}
                    data={process(applicants, dataState)}
                    {...dataState}
                    onDataStateChange={(e:GridDataStateChangeEvent) => {
                        history.replace({
                            ...history.location,
                            state: e.dataState
                        });
                        setDataState(e.dataState);
                      }}
                    onRowClick={handleRowSelection}
                    rowRender={rowRender}
                >
                    {width > 1000 && <GridColumn title="Name" field="name" />}
                    {width > 1000 && <GridColumn field="emailAddress" title="Email Address" />}
                    {width > 1000 && (
                        <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />
                    )}
                    {width > 1000 && (
                        <GridColumn field="isStudent" title="Student" cell={StudentCell} filterable={false} width={"100px"}/>
                    )}
                    {width > 1000 && (
                        <GridColumn field="age" title="Date Of Birth" cell={DOBCell} filterable={false} />
                    )}
                    {width > 1000 && (
                        <GridColumn field="age" title="Age" filterable={false} width = {"100px"}/>
                    )}
                    {width > 1000 && (
                        <GridColumn
                            field="city"
                            title="City"
                            filterable={true}
                        />
                    )}
                    {width > 1000 &&(
                        <GridColumn
                            field="state"
                            title="State"
                            filterable={true}
                            width="100px"
                        />
                    )}
                    {width > 1000 && (
                        <GridColumn
                            field="applicationReceivedAt"
                            title="Date Applied"
                            cell={DateCell}
                            filterable={false}
                        />
                    )}
                
                    {width > 1000 && (
                        <GridColumn
                            cell={(props: GridCellProps) => (
                                <td className='grid-actions'>
                                    <ApplicantActions name={props.dataItem.name} id={props.dataItem.id} getData={getData}/>
                                </td>
                            )}
                            
                            width="120px"
                            filterable={false}
                            sortable={false}
                        />
                    )}
                    {width <= 1000 && <GridColumn cell={MobileCell} field="name" title="search by name" />}
                </Grid>
            </div>
        </div>
    );
};

export default Applicants;
