import { ProjectType } from "./Project"

type PaymentInvoice  = {
    id: string,
    amount: number,
    campaignId: string,
    vendorId: string,
    dateCreated: string,
    dateCompleted?: string,
    isCompleted: boolean,
    methodOfPayment: string,
    startDate: string,
    endDate: string,
    type: ProjectType,
    vendor: string,
    client: string,
    notes: string,
    projectId:string,
    campaignName: string,
    projectName:string,
    displayName: string,
    emailAddress: string,
    description?:string
}


export enum PaymentOption {
    CHECK = 0,
    PAYPAL = 1,
}

export type InvoiceRequest = {
    id?: string;
    paymentMethod: PaymentOption;
    amount: string;
    notes: string;
    campaignId: string;
    synnerId: string;
};

export default PaymentInvoice