import { InvitationActionType } from '.';
import Campaign from '../../../../models/Campaign';
import { CampaignSynnerAction } from './actions';
import React from 'react';
import { AvailableSynner } from '../../../../models/Invitation';
import { ProjectType } from '../../../../models/Project';

type InvitationReducerState = {
    distance?: number;
    availableSynners: AvailableSynner[];
    campaignDetail: Campaign | null;
    total: number;
    tags: string[];
    workType?: ProjectType;
    searchText?: string;
    selectedSynners: AvailableSynner[];
    page: number;
    pageSize: number;
    requestTrigger: boolean;
};

export const initialState: InvitationReducerState = {
    availableSynners: [],
    campaignDetail: null,
    total: 0,
    tags: [],
    page: 1,
    pageSize: 10,
    selectedSynners: [],
    requestTrigger: false,
};

export function InvitationReducer(state: InvitationReducerState, action: CampaignSynnerAction): InvitationReducerState {
    switch (action.type) {
        case InvitationActionType.SET_AVAILABLE_SYNNERS:
            return { ...state, availableSynners: action.payload, selectedSynners: [] };
        case InvitationActionType.SET_TOTAL:
            return { ...state, total: action.payload };
        case InvitationActionType.SET_CAMPAIGN_DETAIL:
            return { ...state, campaignDetail: action.payload };
        case InvitationActionType.SET_SELECTED_SYNNER:
            const temp: AvailableSynner[] = [...state.selectedSynners];
            const existingSynnerIndex = temp.findIndex((el: AvailableSynner) => el.id === action.payload.id);
            if (existingSynnerIndex !== -1) {
                temp.splice(existingSynnerIndex, 1);
                return { ...state, selectedSynners: temp };
            } else {
                return { ...state, selectedSynners: [...state.selectedSynners, action.payload] };
            }
        case InvitationActionType.SET_DISTANCE:
            return { ...state, distance: action.payload === 0 ? undefined: action.payload };
        case InvitationActionType.SET_TAGS:
            return { ...state, tags: action.payload };
        case InvitationActionType.SET_WORK_TYPE:
            return { ...state, workType: action.payload };
        case InvitationActionType.SET_SEARCH_TEXT:
            return { ...state, searchText: action.payload };
        case InvitationActionType.SET_PAGE:
            return { ...state, page: action.payload.page, pageSize: action.payload.pageSize };
        case InvitationActionType.CLEAR_FILTER:
            return { ...state, tags: [], workType: undefined, distance: undefined, searchText: undefined };
        case InvitationActionType.TOGGLE_DATA_FETCH:
            return { ...state, requestTrigger:  !state.requestTrigger};
        case InvitationActionType.REMOVE_SELECTED_SYNNERS:
            return { ...state, selectedSynners: [] };
        default:
            return state;
    }
}

export const InvitationContext = React.createContext<{
    state: InvitationReducerState;
    dispatch: React.Dispatch<CampaignSynnerAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
