import { process, State } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GridDropDownFilter from '../../../components/GridDropDownFilter';
import Tag from '../../../components/Tag';
import { ActivityStatus } from '../../../models/Activity';
import { UserCampaign } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import { formatDate, getListFromEnum } from '../../../utils';
import { getColorByActivityStatus } from '../../../utils/getColorByStatus';
import pagerSettings from '../../../utils/pagerSettings';
import { AuthContext } from '../../Authentication/state';
import ProjectAvatar from '../../Projects/ProjectAvatar';
import CampaignAddressCell from '../CampaignList/CampaignAddressCell';
import CampaignNameCell from '../CampaignList/CampaignNameCell';
import MyCampaignsActions from './MyCampaignActions';

type Props = {
    data: UserCampaign[];
    getData: () => void;
    openReport: (selectedId: string) => void;
};

const FullGridList: React.FC<Props> = ({ data, getData, openReport }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { user } = React.useContext(AuthContext).state;
    const showActions = user && user.role === UserRole.Synner;
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const statuses = getListFromEnum(Object.values(ActivityStatus));

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const Status = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Tag
                    label={ActivityStatus[props.dataItem.activityStatus]}
                    color={getColorByActivityStatus(props.dataItem.activityStatus)}
                />
            </td>
        );
    };

    return (
        <Grid
            style={{ height: '100%' }}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            sortable={true}
            pageable={pagerSettings}
            filterable={true}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/campaigns/${props.dataItem.campaignId}/detail`)}
        >
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn title="Name" field="displayName" cell={(props)=><td className="clickable-grid-custom-item"><CampaignNameCell{...props}/></td>} />
            <GridColumn title="Location" cell={CampaignAddressCell} filterable={false} sortable={false} />
            <GridColumn field="startDate" title="Start Date" cell={DateCell} filter="date" format="{0:s}" />
            <GridColumn field="endDate" title="End Date" cell={DateCell} filter="date" format="{0:s}" />
            <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />
            {showActions && (
                <GridColumn
                    cell={(props: GridCellProps) => (
                        <td className="grid-actions">
                            <MyCampaignsActions
                                data={props.dataItem as UserCampaign}
                                getData={getData}
                                openReport={openReport}
                            />
                        </td>
                    )}
                    width="180px"
                    filterable={false}
                    sortable={false}
                />
            )}
        </Grid>
    );
};

export default FullGridList;
