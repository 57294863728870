export default interface Content {
    id?: string;
    type: ContentType;
    title: string;
    description: string;
    data: string;
    isFeatured: boolean;
    featuredImage?: string;
    status: ContentStatus;
    createdAt?: string;
    createdById?: string;
    updatedAt?: string;
    updatedById?: string;
}

export enum ContentType {
    Announcement = 0,
    Faq = 1,
    Handbook = 2,
    Contact = 3,
    Blog = 4,
}

export enum ContentStatus {
    Draft = 0,
    Published = 1,
    Archived = 2,
}
