import React from 'react';
import Typography from '../../components/Typography';
import { ActivityStatus } from '../../models/Activity';
import { UserCampaign } from '../../models/Campaign';
import { formatDate, formatLocation } from '../../utils';
import MyCampaignsActions from '../Campaigns/MyCampaigns/MyCampaignActions';
import { useHistory } from 'react-router';
import UserSubmissions from '../Campaigns/UserSubmissions';

type Props = {
    activity: UserCampaign;
    getData: () => void;
};
const ActivityCard: React.FC<Props> = ({ activity, getData }) => {
    const history = useHistory();
    return (
        <div
            className="actionable-card"
            tabIndex={0}
            onClick={() => history.push(`/campaigns/${activity.campaignId}/detail`)}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                e.key === 'Enter' && history.push(`/campaigns/${activity.campaignId}/detail`)
            }
        >
            <div>
                <div className="legend">
                    <Typography variant="body">
                        <span className={`color-warning`}>&#11044; &nbsp;</span>
                        {activity.activityStatus === ActivityStatus.Active && <span>Report(s) due</span>}
                        {activity.activityStatus === ActivityStatus.Invited && <span>Campaign Invitation</span>}
                    </Typography>
                </div>
                <Typography variant="subtitle">{activity.projectName}</Typography>
                <Typography variant="body">{activity.campaignName}</Typography>
                <br />
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body" weight={500}>
                            Location
                        </Typography>
                        <Typography variant="body">
                            {activity.venue && (
                                <>
                                    <b>{activity.venue}</b>
                                    <br />
                                </>
                            )}
                            {formatLocation(
                                activity.addressLine1,
                                activity.addressLine2,
                                activity.city,
                                activity.state,
                                activity.country,
                                activity.zipCode,
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            {activity.startDate === activity.endDate ? 'Event Date' : 'Start Date'}
                        </Typography>
                        <Typography variant="body">{formatDate(activity.startDate)}</Typography>
                    </div>
                    {activity.startDate !== activity.endDate && (
                        <div>
                            <Typography variant="body" weight={500}>
                                End Date
                            </Typography>
                            <Typography variant="body">{formatDate(activity.endDate)}</Typography>
                        </div>
                    )}
                </div>
                {activity.activityStatus !== ActivityStatus.Invited && (
                    <>
                        <br />
                        <hr />
                        <UserSubmissions campaignId={activity.campaignId}/>
                    </>
                )}
            </div>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="actions"
            >
                <MyCampaignsActions data={activity} getData={getData} />
            </div>
        </div>
    );
};

export default ActivityCard;
