import React from 'react';
import { useParams } from 'react-router-dom';
import { ReportService, SurveyService } from '../../api';
import Card from '../../components/Card';
import Tag from '../../components/Tag';
import Typography from '../../components/Typography';
import UserReport, { UserReportStatus } from '../../models/Report';
import Survey from '../../models/Survey';
import { UserRole } from '../../models/User';
import { formatDate } from '../../utils';
import { getColorByReportStatus } from '../../utils/getColorByStatus';
import { AuthContext } from '../Authentication/state';
import SurveyViewer from '../Surveys/SurveyViewer';
import Comment from './Comment';
import ReportAdminActions from './ReportAdminActions';
import ReportCampaign from './ReportCampaign';

const ReportDetail: React.FC = () => {
    const [report, setReport] = React.useState<UserReport | null>(null);
    const [survey, setSurvey] = React.useState<Survey | null>(null);
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);

    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        ReportService.getReport(id).then((response) => {
            setReport(response);
            SurveyService.getSurvey(response.surveyId as string).then((response) => {
                setSurvey(response);
            });
        });
    }, [id]);

    const handleStatusChange = (value: UserReportStatus) => {
        ReportService.updateStatus({ id: report?.id as string, status: value}).then(() => {
            ReportService.getReport(id).then((response) => {
                setReport(response);
            });
        });
    };

    const isFormEditable = () => {
        //let users make edits to report if report status is need more information
        if (user?.role === UserRole.Synner) {
            if (report?.status === UserReportStatus['Need More Info'] && report.synnerId === user.id) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    const handleReportUpdate = (updatedReport: UserReport) => {
        return ReportService.updateReport(updatedReport);
    };

    return (
        <div className="reports">
            {report && <ReportCampaign campaignId={report.campaignId} />}
            {survey && (
                <Card>
                    <>
                       {report && 
                        <div className='report-info'>
                            <Tag
                                label={UserReportStatus[report.status as UserReportStatus]}
                                color={getColorByReportStatus(report.status as UserReportStatus)}
                             />
                             {isAdminManager && report?.status === UserReportStatus['Pending Review'] && <ReportAdminActions handleStatusChange={handleStatusChange}/>}
                        </div>}
                        <Typography variant="title">{survey.title}</Typography>
                        <Typography variant="body">{survey.description}</Typography>
                        {report && (
                            <>
                                <Typography variant="body">
                                    <strong>Date Submitted: </strong>
                                    {formatDate(report.createdAt as string)}
                                </Typography>
                                <Typography variant="body">
                                    <strong>Date Updated: </strong>
                                    {formatDate(report.updatedAt as string)}
                                </Typography>
                                <Comment
                                    comment={report.comments ? report.comments : ''}
                                    status={report.status as UserReportStatus}
                                    id={report.id as string}
                                />
                                <SurveyViewer
                                    elements={survey.elements}
                                    userReport={report}
                                    formView={isFormEditable()}
                                    handleSubmit={handleReportUpdate}
                                />
                            </>
                        )}
                    </>
                </Card>
            )}
        </div>
    );
};

export default ReportDetail;
