import { PersonalDetails, EducationDetails, OnlineActivityDetails, MusicComedyDetails, OtherDetails } from "../../../models/Application";

export enum ApplicationActionType {
    SET_ACTIVE_STEP,
    SET_COMPLETE,
    SET_PERSONAL_DETAILS,
    SET_EDUCATION_DETAILS,
    SET_ONLINE_ACTIVITY_DETAILS,
    SET_MUSIC_COMEDY_DETAILS,
    SET_OTHER_DETAILS
}

export type ApplicationAction =
    | { type: ApplicationActionType.SET_ACTIVE_STEP; payload: number }
    | { type: ApplicationActionType.SET_COMPLETE }
    | { type: ApplicationActionType.SET_PERSONAL_DETAILS; payload: PersonalDetails }
    | { type: ApplicationActionType.SET_EDUCATION_DETAILS; payload: EducationDetails }
    | { type: ApplicationActionType.SET_ONLINE_ACTIVITY_DETAILS; payload: OnlineActivityDetails }
    | { type: ApplicationActionType.SET_MUSIC_COMEDY_DETAILS; payload: MusicComedyDetails }
    | { type: ApplicationActionType.SET_OTHER_DETAILS; payload: OtherDetails };
