import {
    Applicant,
    ApplicantionStatus,
    EducationDetails,
    MusicComedyDetails,
    OnlineActivityDetails,
    OtherDetails,
    PersonalDetails,
} from '../models/Application';
import instance from './instance';

function postApplication(
    application: PersonalDetails & EducationDetails & OnlineActivityDetails & OtherDetails & MusicComedyDetails,
): Promise<string> {
    return instance.post('applicant', application).then((response) => {
        return response.data;
    });
}

function getApplicants(): Promise<Applicant[]> {
    return instance.get('applicants').then((response) => {
        return response.data;
    });
}

function getApplicant(id: string): Promise<Applicant> {
    return instance.get(`applicant/${id}`).then((response) => {
        return response.data;
    });
}

function changeStatus(id: string, status: ApplicantionStatus): Promise<string> {
    return instance
        .put('applicant', {
            id: id,
            status: status,
        })
        .then((response) => {
            return response.data;
        });
}
function deleteApplication(id: string): Promise<string> {
    return instance.delete(`applicant/${id}`).then((response) => {
        return response.data;
    });
}
export { postApplication, getApplicants, getApplicant, changeStatus, deleteApplication };
