import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Typography from '../../components/Typography';


const Completed: React.FC = () => {
    const history = useHistory();
    
    return (
        <div>
            <br/>
            <br/>
            <Typography variant="subheading" textAlign="center" color="primary">
                Thank you for taking your time to apply!
            </Typography>
            <Typography variant="title" textAlign="center">We’ll reach out to you to schedule an interview soon.</Typography>
            <br/>
            <div className="go-back-button">
                <Button onClick={()=>history.push('/')} color="primary">
                    Go Back
                </Button>
            </div>            
        </div>
    );
};

export default Completed;
