import React from 'react';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

import {
    EducationDetails,
    MusicComedyDetails,
    OnlineActivityDetails,
    OtherDetails,
    PersonalDetails,
} from '../../models/Application';
import IconButton from '../../components/IconButton';
import { ApplicationService } from '../../api';
import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import Socials from '../../components/Socials';
import { formatLocation } from '../../utils';

type Props = {
    data: PersonalDetails & EducationDetails & OnlineActivityDetails & OtherDetails & MusicComedyDetails;
    handleReviewEdit: (index: number) => void;
    handleComplete: () => void;
};
const Review: React.FC<Props> = ({ data, handleReviewEdit, handleComplete }) => {
    const [loading, setLoading] = React.useState(false);

    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const postApplication = () => {
        setLoading(true);
        ApplicationService.postApplication(data)
            .then(() => {
                handleComplete();
                setLoading(false);
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
                setLoading(false);
            });
    };
    return (
        <>
            <div className="paper">
                <div className="paper-icon-container">
                    <IconButton
                        Icon={EditIcon}
                        color="primary"
                        onClick={() => handleReviewEdit(0)}
                        tooltip="Edit personal details"
                    />
                </div>
                <Typography variant="subtitle" weight={500} color="secondary">
                    Personal Details
                </Typography>
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body" weight={500}>
                            First Name
                        </Typography>
                        <Typography variant="body">{data.fname}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Last Name
                        </Typography>
                        <Typography variant="body">{data.lname}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Pronouns
                        </Typography>
                        <Typography variant="body">
                            {data.preferredPronouns && data.preferredPronouns.length > 0
                                ? data.preferredPronouns
                                : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Date Of Birth
                        </Typography>
                        <Typography variant="body">{data.dateOfBirth}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Email Address
                        </Typography>
                        <Typography variant="body">{data.emailAddress}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Phone Number
                        </Typography>
                        <Typography variant="body">{data.phoneNumber}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Address
                        </Typography>
                        <Typography variant="body">
                            {formatLocation(
                                data.addressLine1,
                                data.addressLine2,
                                data.city,
                                data.state,
                                data.country,
                                data.zipCode,
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            How did you hear about The Syndicate?
                        </Typography>
                        <Typography variant="body">{data.howSyndicate}</Typography>
                    </div>
                </div>
            </div>
            <div className="paper">
                <div className="paper-icon-container">
                    <IconButton
                        Icon={EditIcon}
                        color="primary"
                        onClick={() => handleReviewEdit(1)}
                        tooltip="Edit education"
                    />
                </div>
                <Typography variant="subtitle" weight={500} color="secondary">
                    Education
                </Typography>
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body" weight={500}>
                            Are you a student?
                        </Typography>
                        <Typography variant="body">{data.isStudent ? 'Yes' : 'No'}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            What year of school are you?
                        </Typography>
                        <Typography variant="body">
                            {data.schoolYear && data.schoolYear.length > 0 ? data.schoolYear : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Major
                        </Typography>
                        <Typography variant="body">
                            {data.major && data.major.length > 0 ? data.major : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            When do you expect to graduate?
                        </Typography>
                        <Typography variant="body">
                            {data.expectedGraduation && data.expectedGraduation.length > 0
                                ? data.expectedGraduation
                                : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            College
                        </Typography>
                        <Typography variant="body">
                            {data.college && data.college.length > 0 ? data.college : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            City
                        </Typography>
                        <Typography variant="body">
                            {data.collegeCity && data.collegeCity.length > 0 ? data.collegeCity : 'N/A'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            State
                        </Typography>
                        <Typography variant="body">
                            {data.collegeState && data.collegeState.length > 0 ? data.collegeState : 'N/A'}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="paper">
                <div className="paper-icon-container">
                    <IconButton
                        Icon={EditIcon}
                        color="primary"
                        onClick={() => handleReviewEdit(2)}
                        tooltip="Edit online activity"
                    />
                </div>
                <Typography variant="subtitle" weight={500} color="secondary">
                    Online Activity
                </Typography>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" weight={500}>
                            How often do you check your email?
                        </Typography>
                        <Typography variant="body">{data.emailFrequency}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            What social media platforms do you actively use?
                        </Typography>
                        <Socials socials={data.socialMedias} />
                    </div>
                </div>
            </div>
            <div className="paper">
                <div className="paper-icon-container">
                    <IconButton
                        Icon={EditIcon}
                        color="primary"
                        onClick={() => handleReviewEdit(3)}
                        tooltip="Edit music/comedy"
                    />
                </div>
                <Typography variant="subtitle" weight={500} color="secondary">
                    Music/Comedy
                </Typography>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" weight={500}>
                            What are your favorite bands / singers or songwriters / comedians?
                        </Typography>
                        <Typography variant="body">{data.favouriteArtist}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Pick one from above and explain what excited you about them.
                        </Typography>
                        <Typography variant="body">{data.favouriteExplanation}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            How often do you attend shows?
                        </Typography>
                        <Typography variant="body">{data.showFrequency}</Typography>
                    </div>
                </div>
            </div>
            <div className="paper">
                <div className="paper-icon-container">
                    <IconButton
                        Icon={EditIcon}
                        color="primary"
                        onClick={() => handleReviewEdit(4)}
                        tooltip="Edit other details"
                    />
                </div>
                <Typography variant="subtitle" weight={500} color="secondary">
                    Others
                </Typography>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" weight={500}>
                            What kind of Smart phone do you have?
                        </Typography>
                        <Typography variant="body">{data.whatKindSmartPhone}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            How much free time do you have per week?
                        </Typography>
                        <Typography variant="body">{data.freeTime.toString()}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            What takes up your free time?
                        </Typography>
                        <Typography variant="body">{data.FreeTimeActivity}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Why do you want to be Street Marketing Representative for The Syndicate?
                        </Typography>
                        <Typography variant="body">{data.whyCareerGoal}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Have you ever been on a street team?
                        </Typography>
                        <Typography variant="body">{data.streetTeamExperience}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Is there anything else you would like us to know?
                        </Typography>
                        <Typography variant="body">
                            {data.comments && data.comments.length > 0 ? data.comments : 'N/A'}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="action-container">
                <Button color="primary" onClick={postApplication} loading={loading}>
                    Submit Application
                </Button>
            </div>
        </>
    );
};

export default Review;
