import Survey, { SurveyElement, SurveyElementType } from "../../../../models/Survey";

export enum SurveyBuilderActionType {
    SET_SURVEY,    
    ADD_SURVEY_ELEMENT,
    REMOVE_SURVEY_ELEMENT,
    UPDATE_SURVEY_ELEMENT,
    SELECT_SURVEY_ELEMENT
}

export type SurveyBuilderAction =
    | { type: SurveyBuilderActionType.SET_SURVEY; payload: Survey }
    | {type: SurveyBuilderActionType.ADD_SURVEY_ELEMENT; payload: SurveyElementType}
    | {type: SurveyBuilderActionType.REMOVE_SURVEY_ELEMENT; payload: number}
    | {type: SurveyBuilderActionType.SELECT_SURVEY_ELEMENT; payload: number}
    | {type: SurveyBuilderActionType.UPDATE_SURVEY_ELEMENT; payload: SurveyElement};