import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../../../components/IconButton';
import { ActivityStatus } from '../../../models/Activity';
import { UserCampaign } from '../../../models/Campaign';
import { ReactComponent as ManagerIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as AcceptIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as DeclineIcon } from '../../../assets/icons/x-circle.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/clipboard.svg';
import { ActivityService } from '../../../api';

type Props = {
    data: UserCampaign;
    getData: () => void;
    openReport?:(selectedId:string)=>void;
};

const MyCampaignsActions: React.FC<Props> = ({ data, getData, openReport}) => {
    const handleAcceptInvitation = () => {
        ActivityService.updateActivityStatus({ id: data.id, status: ActivityStatus.Active }).then(()=>getData());
    };

    const handleDeclineInvitation = () => {
        ActivityService.updateActivityStatus({ id: data.id, status: ActivityStatus.Declined }).then(()=>getData());
    };
    return (
        <>
            <Link to={`/users/${data.managerId}/detail`}>
                <IconButton tooltip="Manager Profile" Icon={ManagerIcon} color="info" />
            </Link>
            {data.activityStatus === ActivityStatus.Invited && (
                <>
                    <IconButton
                        tooltip="Accept Invitation"
                        color="success"
                        Icon={AcceptIcon}
                        onClick={handleAcceptInvitation}
                    />
                    <IconButton
                        tooltip="Decline Invitation"
                        color="error"
                        Icon={DeclineIcon}
                        onClick={handleDeclineInvitation}
                    />
                </>
            )}
          {openReport && data.activityStatus !== ActivityStatus.Invited && data.activityStatus !== ActivityStatus.Applied && data.activityStatus !== ActivityStatus.Declined &&<IconButton tooltip='View reports' Icon={ReportIcon} color="info" onClick={()=>openReport(data.campaignId)}/>}

        </>
    );
};

export default MyCampaignsActions;
