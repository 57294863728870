import React from 'react';
import './dateinput.scss';

type Props = {
    onChange: (name: string, value: string) => void;
    name: string;
    value?: string;
    disabled?: boolean;
    error?: boolean;
    errorText?: string;
};

type DateType = {
    day?: string;
    month?: string;
    year?: string;
};

const setInitialValues = (value?: string) => {
    if (value) {
        const dateObj = new Date(value);
        return {
            day: dateObj.getUTCDate().toString().padStart(2, '0'),
            month: (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'),
            year: dateObj.getUTCFullYear().toString().padStart(4, '0'),
        };
    } else {
        return {};
    }
};

const DateInput: React.FC<Props> = ({ name, value, disabled, onChange, error, errorText }) => {
    const [dateValue, setDateValue] = React.useState<DateType>(setInitialValues(value));

    const handleValueChange = (newDate: DateType) => {
        if (isDayValid(newDate.day) && isMonthValid(newDate.month) && isYearValid(newDate.year)) {
            const newValue = `${newDate.year}-${newDate.month}-${newDate.day}`;
            onChange(name, newValue);
        }
    };

    const isDayValid = (value?: string) => {
        return value && parseInt(value) <= 32 && parseInt(value) >= 0;
    };

    const isMonthValid = (value?: string) => {
        return value && parseInt(value) <= 12 && parseInt(value) >= 0;
    };

    const isYearValid = (value?: string) => {
        return value && parseInt(value) <= 9999 && parseInt(value) >= 0;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = parseInt(e.target.value);
        let stringifiedValue = value.toString();
        switch (e.target.name) {
            case 'day':
                stringifiedValue = stringifiedValue.padStart(2, '0');
                if (isDayValid(stringifiedValue)) {
                    setDateValue({ ...dateValue, [e.target.name]: stringifiedValue });
                    handleValueChange({ ...dateValue, [e.target.name]: stringifiedValue });
                }
                break;
            case 'month':
                stringifiedValue = stringifiedValue.padStart(2, '0');
                if (isMonthValid(stringifiedValue)) {
                    setDateValue({ ...dateValue, [e.target.name]: stringifiedValue });
                    handleValueChange({ ...dateValue, [e.target.name]: stringifiedValue });
                }
                break;
            case 'year':
                stringifiedValue = value.toString().padStart(4, '0');
                if (isYearValid(stringifiedValue)) {
                    setDateValue({ ...dateValue, [e.target.name]: stringifiedValue });
                    handleValueChange({ ...dateValue, [e.target.name]: stringifiedValue });
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className="date-input-container">
            <div className={`date-fields${error? ' error': ''}`}>
                <input
                    type="number"
                    placeholder="MM"
                    onChange={handleChange}
                    disabled={disabled}
                    name="month"
                    value={dateValue.month}
                    min="00"
                    max="12"
                    step="1"
                    className="month"
                />
                <span>/</span>
                <input
                    type="number"
                    placeholder="DD"
                    onChange={handleChange}
                    disabled={disabled}
                    name="day"
                    value={dateValue.day}
                    min="00"
                    max="32"
                    step="1"
                    className="day"
                />
                <span>/</span>
                <input
                    type="number"
                    placeholder="YYYY"
                    onChange={handleChange}
                    disabled={disabled}
                    name="year"
                    value={dateValue.year}
                    min="0000"
                    max="9999"
                    step="1"
                    className="year"
                />
            </div>
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default DateInput;
