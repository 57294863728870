import { ActivityStatus } from '../models/Activity';
import { CampaignStatus } from '../models/Campaign';
import { ProjectStatus } from '../models/Project';
import { UserReportStatus } from '../models/Report';

const getColorByProjectStatus = (status: ProjectStatus) => {
    switch (status) {
        case ProjectStatus.Completed:
            return 'success';
        case ProjectStatus.Active:
            return 'info';
        case ProjectStatus.Archived:
            return 'grey';
        default:
            return 'primary';
    }
};

const getColorByCampaignStatus = (status: CampaignStatus) => {
    switch (status) {
        case CampaignStatus.Completed:
            return 'success';
        case CampaignStatus.Ready:
            return 'info';
        case CampaignStatus.Archived:
            return 'grey';
        default:
            return 'primary';
    }
};

const getColorByActivityStatus = (status: ActivityStatus) => {
    switch (status) {
        case ActivityStatus.Invited:
            return 'warning';
        case ActivityStatus.Active:
            return 'info';
        case ActivityStatus.Applied:
            return 'warning';
        case ActivityStatus.Declined:
            return 'error';
        case ActivityStatus.Completed:
            return 'success';
        case ActivityStatus.Incomplete:
            return 'error';
        default:
            return 'primary';
    }
};

const getColorByReportStatus = (status: UserReportStatus) => {
    switch (status) {
        case UserReportStatus['Pending Review']:
            return 'info';
        case UserReportStatus['Need More Info']:
            return 'warning';
        case UserReportStatus['To Do']:
                return 'tertiary';
        case UserReportStatus.Approved:
            return 'success';
        case UserReportStatus['Incomplete']:
                return 'error';
        case UserReportStatus['Rejected']:
            return 'error';
        default:
            return 'primary';
    }
};

export { getColorByProjectStatus, getColorByCampaignStatus, getColorByActivityStatus, getColorByReportStatus };
