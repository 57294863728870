import React from 'react';
import { ContentService } from '../../api';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import Content from '../../models/Content';
import { AuthContext } from '../Authentication/state';

const FeaturedAnnouncement: React.FC = () => {
    const [featuredAnnouncement, setFeaturedAnnouncement] = React.useState<Content | null>(null);
    const [hasSeenFeatured, setHasSeenFeatured] = React.useState(false);
    const {user} = React.useContext(AuthContext).state;

    React.useEffect(() => {
        ContentService.getFeaturedAnnouncement().then((response) => {
            const featuredId = localStorage.getItem('featuredAnnouncementId');
            const isDifferentUser = localStorage.getItem('SynnerNationUserId') !== user?.id;
            if (featuredId !== response.id || isDifferentUser) {
                setFeaturedAnnouncement(response);
                localStorage.setItem('featuredAnnouncementId', response.id as string);
                localStorage.setItem('SynnerNationUserId', user?.id as string);
                setHasSeenFeatured(true);
            }
        });
    }, []);

    return (
        <Modal
            isOpen={hasSeenFeatured}
            title="Featured Announcement"
            close={() => setHasSeenFeatured(false)}
            size="large"
        >
            <>
                {featuredAnnouncement && (
                    <div className="featured-announcement">
                        <Typography variant="subheading">{featuredAnnouncement.title}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: featuredAnnouncement.data }}></div>
                    </div>
                )}
            </>
        </Modal>
    );
};

export default FeaturedAnnouncement;
