import { ActivityStats, ActivityStatus, SynnerActivity } from '../models/Activity';
import { UserCampaign } from '../models/Campaign';
import { AvailableSynner, Invitation } from '../models/Invitation';
import { ProjectType } from '../models/Project';
import UserInCampaign from '../models/UserInCampaign';
import { exportToCSV } from '../utils';
import instance from './instance';

function getUserCampaigns(userId?: string):Promise<UserCampaign[]> {
    const req = userId ? instance.get(`user-campaigns?userId=${userId}`) : instance.get(`user-campaigns`);
    return req.then((response)=> {
        const temp = [...response.data];
        temp.forEach((element) => {
            element.startDate = new Date(element.startDate);
            element.endDate = new Date(element.startDate);
        });
        return temp;
    });
}

function getUsersInCampaign(campaignId: string):Promise<UserInCampaign[]> {
    return instance.get(`campaigns/${campaignId}/users`).then((response)=> {
        return response.data;
    })
}

function getActionableActivities(): Promise<UserCampaign[]> {
    return instance.get(`actionable-activities`).then((response) => {
        return response.data;
    });
}

function deleteActivityFromCampaign(id: string): Promise<boolean> {
    return instance.delete(`/activity/${id}`).then((response) => {
        return response.data;
    });
}

function applyForCampaign(campaignId: string): Promise<boolean> {
    return instance.post(`/activity/apply/${campaignId}`).then((response) => {
        return response.data;
    });
}

function InviteToCampaign(activity: Invitation[]): Promise<boolean> {
    return instance.post(`/activity/invite`, activity).then((response) => {
        return response.data;
    });
}

function getAvailableSynners(request: {
    campaignId: string;
    distance?: number;
    tags?: string[];
    page?: number;
    pageSize?: number;
    searchText?: string;
    workType?: ProjectType;
}): Promise<{ items: AvailableSynner[]; total: number }> {
    return instance.post(`users-for-campaign`, request).then((response) => {
        return {
            items: response.data.items ? response.data.items : [],
            total: response.data.total ? response.data.total : 0,
        };
    });
}

function getAvailableSynnersCSV(request: {
    campaignId: string;
    distance?: number;
    tags?: string[];
    page?: number;
    pageSize?: number;
    searchText?: string;
    workType?: ProjectType;
}): Promise<boolean> {
    return instance.post(`users-for-campaign-csv`, request).then((response) => {
        exportToCSV(response.data.content, response.data.name);
        return true;
    });
}

function updateSynnerRole(request: { id: string; role: string }): Promise<boolean> {
    return instance.put(`/activity/role`, request).then((response) => {
        return response.data;
    });
}

function updateActivityStatus(request: { id: string; status: ActivityStatus }): Promise<boolean> {
    return instance.put(`/activity/status`, request).then((response) => {
        return response.data;
    });
}

function getRecentActivityForSynner(synnerId: string): Promise<SynnerActivity[]> {
    return instance.get(`/activity/recent/${synnerId}`).then((response) => {
        return response.data;
    });
}

function getActivityStats():Promise<ActivityStats> {
    return instance.get(`/activity/stats`).then((response) => {
        return response.data;
    });
}

export {
    getActionableActivities,
    deleteActivityFromCampaign,
    applyForCampaign,
    InviteToCampaign,
    getAvailableSynners,
    updateSynnerRole,
    updateActivityStatus,
    getRecentActivityForSynner,
    getActivityStats,
    getAvailableSynnersCSV,
    getUserCampaigns,
    getUsersInCampaign
};
