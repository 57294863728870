import React from 'react';
import Campaign, { CampaignStatus } from '../../models/Campaign';
import { useParams } from 'react-router';
import Project from '../../models/Project';
import { CampaignService, ProjectService } from '../../api';
import Typography from '../../components/Typography';
import CampaignForm from './CampaignForm';

const initCampaign: Campaign = {
    projectId: '',
    status: CampaignStatus.Ready,
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    startDate: '',
    endDate: '',
    description: '',
    stipend: '',
    expectedSynners: '',
};

const CreateCampaign: React.FC = () => {
    const [project, setProject] = React.useState<Project | null>(null);
    const { projectId } = useParams<{ projectId: string }>();

    React.useEffect(() => {
        ProjectService.getProject(projectId).then((response) => {
            setProject(response);
        });
    }, [projectId]);

    const handleSubmit = (newCampaign: Campaign): Promise<Campaign> => {
        return CampaignService.createCampaign(newCampaign);
    };

    return (
        <div className="campaigns">
            <Typography variant="title" textAlign="center">
                CREATE A CAMPAIGN
            </Typography>
            <hr />
            <br />
            {project && (
                <>
                    <Typography variant="body">
                        *Creating campaign for <strong>{project.name.toUpperCase()}</strong> project
                    </Typography>
                    <CampaignForm
                        selectedCampaign={{
                            ...initCampaign,
                            projectId: projectId,
                            type: project.type,
                            description: project.description ? project.description : ''
                        }}
                        handleSubmit={handleSubmit}
                    />
                </>
            )}
        </div>
    );
};

export default CreateCampaign;
