import React from 'react';
import Button from '../../../components/Button';
import ContentEditor from '../../../components/Editor';
import Typography from '../../../components/Typography';

type Props = {
    data: string;
    handleSubmit: (data: string) => void;
};

const SurveyWebContentDetail: React.FC<Props> = ({ data, handleSubmit }) => {
    const [detail, setDetail] = React.useState(data);

    React.useEffect(() => {
        setDetail(data);
    }, [data]);

    const handleChange = (_name: string, value: string) => {
        setDetail(value);
    };

    return (
        <div className="detail">
            <Typography variant="subtitle" color="primary">
                WEB CONTENT DETAILS
            </Typography>
            <hr />
            <ContentEditor onChange={handleChange} name="webcontent" value={detail} height={360}/>
            <div className="action-container">
                <Button onClick={() => handleSubmit(detail)}>Update Item</Button>
            </div>
        </div>
    );
};

export default SurveyWebContentDetail;
