import React from "react";
import ReactDOM from "react-dom";
import "./tooltip.scss";

interface Props {
  children: React.ReactElement;
  /** content that appears on click or hover */
  text: string;
  /** max width of the tooltip. */
  maxWidth?: number;
  /** condition to not show the tooltip */
  disabled?: boolean;

  className?: string;
  /** space in between element and tooltip */
  spacing?: number;
}

const Tooltip: React.FC<Props> = ({
  children,
  text,
  maxWidth,
  className,
  disabled = false,
  spacing = 6,
}) => {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const elementPos = React.useRef({ top: 0, right: 0, bottom: 0, width: 0 });

  React.useEffect(() => {
    if (isOpen) {
      setPosition();
    }
  }, [isOpen]);

  const showTooltip = (parent: Element) => {
    setIsOpen(true);
    elementPos.current = parent.getBoundingClientRect();
  };

  const hideTooltip = () => {
    setIsOpen(false);
  };

  const setPosition = () => {
    if (tooltipRef.current) {
      const { top, right, bottom, width } = elementPos.current;      
      if (maxWidth) {
        tooltipRef.current.style.maxWidth = `${maxWidth}px`;
      }
      const tooltipWidth = tooltipRef.current.offsetWidth;
      const tooltipHeight = tooltipRef.current.offsetHeight;
      let topPos = 0;
      let leftPos = 0;
      //ideally we want to place it on top of the element;
      topPos = top - tooltipHeight - spacing;
      const isOnBottom = topPos < 0;
      //if out of view place it on the bottom of the element;
      if (isOnBottom) {
        topPos = bottom + spacing;
      }
      //ideally we want to align it center to the element;
      leftPos = right - width / 2 - tooltipWidth / 2;
      // if out of view adjust position
      if (leftPos < spacing) leftPos = spacing;
      if (leftPos + tooltipWidth > window.innerWidth - spacing)
        leftPos = window.innerWidth - spacing - tooltipWidth;
      const newClass = "tooltip" + (className ? ` ${className}` : "");
      tooltipRef.current.className = newClass;
      tooltipRef.current.style.top = `${topPos}px`;
      tooltipRef.current.style.left = `${leftPos}px`;
      tooltipRef.current.style.visibility = 'visible';
    }
  };

  return (
    <>
      {!disabled
        ? React.cloneElement(children, {
            ...children.props,
            "aria-label": text,
            tabIndex: 0,
            onFocus: (e: React.FocusEvent) => {
              showTooltip(e.currentTarget);
              children.props.onFocus?.(e);
            },
            onBlur: (e: React.FocusEvent) => {
              hideTooltip();
              children.props.onBlur?.(e);
            },
            onMouseOver: (e: React.MouseEvent) => {
              showTooltip(e.currentTarget);
              children.props.onMouseOver?.(e);
            },
            onMouseOut: (e: React.MouseEvent) => {
              hideTooltip();
              children.props.onMouseOut?.(e);
            },
          })
        : children}
      {!disabled &&
        isOpen &&
        ReactDOM.createPortal(
          <div className="tooltip" ref={tooltipRef}>
            {text}
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
