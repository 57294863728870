import React from 'react';
import { ContentService } from '../../api';
import Content, { ContentType } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import EditablePage from '../../components/EditablePage';
import DefaultListItemTemplate from '../ContentManager/DefaultListItemTemplate';

const Faq: React.FC = () => {
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const [faqs, setFaqs] = React.useState<Content[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        ContentService.getContents(ContentType.Faq).then((response) => {
            setFaqs(response.items);
        });
    }, []);

    return (
        <EditablePage>
            <div className="faqs">
                {isEdit && (
                    <Button
                        onClick={() => history.push('/faq/create-faq')}
                        color="primary"
                        className="d-block mx-auto"
                    >
                        Create New Faq
                    </Button>
                )}
                {faqs.map((content, index) => (
                    <DefaultListItemTemplate content={content} key={index} viewDetail={(id)=>history.push(`/faq/${id}`)}/>
                ))}
            </div>
        </EditablePage>
    );
};

export default Faq;
