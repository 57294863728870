import React from 'react';
import Campaign from '../../../models/Campaign';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/external-link.svg';
import IconButton from '../../../components/IconButton';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/trello.svg';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Authentication/state';
import { UserRole } from '../../../models/User';

type Props = {
    campaign: Campaign;
};
const CampaignActions: React.FC<Props> = ({ campaign }) => {
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    return (
        <>
            <Link to={`/campaigns/${campaign.id}/detail`}>
                <IconButton Icon={ExternalLinkIcon} color="info" tooltip="Campaign Details" />
            </Link>
            {isAdminManager && (
                <Link to={`/projects/${campaign.projectId}/detail`}>
                    <IconButton Icon={ProjectIcon} color="info" tooltip="Project Details" />
                </Link>
            )}
        </>
    );
};

export default CampaignActions;
