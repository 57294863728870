import React from 'react';
import Typography from '../../../components/Typography';
import { SurveyUploadData } from '../../../models/Survey';
import { urlIsValid } from '../../../utils';

type Props = {
    data: SurveyUploadData;
    value: string | undefined;
};
const FileDetail: React.FC<Props> = ({ data, value }) => {
    return (
        <div>
            <Typography variant="subtitle">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <br/>
            {urlIsValid(value) && (
                <div style={{width: "240px"}}>
                    <object
                        data={value}
                        width="240">
                    </object>
                    <a href={value} target="_blank" rel="noreferrer" className='hoverable-link text-action'>
                    <Typography variant="body" color='info' textAlign='center'>download file(s)</Typography>
                    </a>
                </div>
            )}
        </div>
    );
};

export default FileDetail;
