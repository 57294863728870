import React from 'react';
import './checkbox.scss';

type Props = {
    onChange: () => void;
    disabled?: boolean;
    label?: string;
    checked: boolean;
    required?: boolean;
};

const Checkbox: React.FC<Props> = ({ onChange, checked, disabled, label, required }) => {
    const id = "checkbox-"+Math.random().toString(36);
    return (
        <div className="checkbox-container">
            <input
                type="checkbox"
                id={id}
                name="check"
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                required={required}
            />
            <label htmlFor={id}>{label?label:''}</label>
        </div>
    );
};

export default Checkbox;
