import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '../../components/Typography';
import Survey from '../../models/Survey';
import SurveyViewer from '../Surveys/SurveyViewer';
import './report.scss';
import { ReportService, SurveyService } from '../../api';
import UserReport from '../../models/Report';
import Card from '../../components/Card';
import ReportCampaign from './ReportCampaign';

type LocationState = {
    campaignId: string;
    surveyId: string;
};

const CreateReport: React.FC = () => {
    const [newReport, setNewReport] = React.useState<UserReport | null>(null);
    const [survey, setSurvey] = React.useState<Survey | null>(null);
    const history = useHistory();
    const location = useLocation();    
    const { campaignId, surveyId } = location.state as LocationState;

    React.useEffect(() => {
        if (location.state) {
            if (campaignId && surveyId) {
                setNewReport({ campaignId: campaignId, surveyId: surveyId, data: {} });
                SurveyService.getSurvey(surveyId).then((response) => {
                    setSurvey(response);
                });
            } else {
                history.goBack();
            }
        } else {
            history.goBack();
        }
    }, [location]);

    const handleSubmit = (report: UserReport) => {
        return ReportService.submitReport(report);
    };

    return (
        <div className="reports">
            <ReportCampaign campaignId={campaignId}/>
            {survey && (
                <Card>
                    <>
                        <Typography variant="title">{survey.title}</Typography>
                        <Typography variant="body">{survey.description}</Typography>
                        <br />
                        <hr />
                        <br />
                        {newReport && (
                            <SurveyViewer
                                elements={survey.elements}
                                handleSubmit={handleSubmit}
                                userReport={newReport}
                                formView={true}
                            />
                        )}
                    </>
                </Card>
            )}
        </div>
    );
};

export default CreateReport;
