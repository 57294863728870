import React from 'react';
import './report.scss';
import {UserReportGrid } from '../../models/Report';
import { ReportService } from '../../api';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../Authentication/state';
import {useWindowDimensions} from '../../utils';
import { DataLoadingContext } from '../../components/DataLoading';
import FullGridList from './ReportList/FullGridList';
import MiniGridList from './ReportList/MiniGridList';

const Reports: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [reports, setReports] = React.useState<UserReportGrid[]>([]);
    const {user}= React.useContext(AuthContext).state;
    const {userId}= useParams<{userId: string}>();
    const {width}= useWindowDimensions();

    React.useEffect(() => {
        getData();
    }, [userId]);

    const getData=()=> {
        toggleLoading(true);
        const id = userId? userId: user?.id as string;
        ReportService.getReports(id).then((response) => {
            setReports(response);
        }).finally(()=>toggleLoading(false));
    }

    return (
        <div className="reports">
            {width>1000? <FullGridList data={reports} getData={getData}/>:<MiniGridList data={reports} getData={getData}/>}
        </div>
    );
};

export default Reports;
