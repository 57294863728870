import UserReport, { UserReportGrid, UserReportStatus, UserSubmission } from '../models/Report';
import { UploadFileType } from '../models/Upload';
import instance from './instance';

function getReports(userId: string): Promise<UserReportGrid[]> {
    return instance.get(`user-reports/${userId}`).then((response) => {
        response.data.forEach((el:UserReportGrid)=>el.updatedAt = new Date(el.updatedAt));
        return response.data;
    });
}

function GetUserSubmissionsForCampaign(synnerId: string, campaignId: string): Promise<UserSubmission[]> {
    return instance.get(`/campaign-reports/${campaignId}/${synnerId}`).then((response)=> {
        return response.data;
    })
}

function getReport(id: string): Promise<UserReport> {
    return instance.get(`report/${id}`).then((response) => {
        return { ...response.data, data: JSON.parse(response.data.data) };
    });
}

function deleteReport(id: string): Promise<boolean> {
    return instance.delete(`report/${id}`).then((response) => {
        return response.data;
    });
}

function submitReport(report: UserReport): Promise<boolean> {
    const request: unknown = { ...report, data: JSON.stringify(report.data) };
    return instance.post('report', request).then((response) => {
        return response.data;
    });
}

function updateReport(report: UserReport): Promise<boolean> {
    return instance.put('report', { ...report, data: JSON.stringify(report.data) }).then((response) => {
        return response.data;
    });
}

function uploadReportFiles(file: File | File[]): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formData: any = new FormData();
    formData.append('type', UploadFileType.ReportFile.toString());
    if (Array.isArray(file)) {
        file.forEach((element) => {
            formData.append('files', element);
        });
    } else {
        formData.append('file', file);
    }
    return instance.post(`/upload`, formData).then((response) => {
        return response.data;
    });
}

function addComment(commentReq: { id: string; comments: string }): Promise<string> {
    return instance.put('report/comment', commentReq).then((response) => {
        return response.data;
    });
}

function updateStatus(statusReq: { id: string; status: UserReportStatus }): Promise<string> {
    return instance.put('report/status', statusReq).then((response) => {
        return response.data;
    });
}

export {
    getReports,
    getReport,
    submitReport,
    updateReport,
    deleteReport,
    uploadReportFiles,
    addComment,
    updateStatus,
    GetUserSubmissionsForCampaign
};
