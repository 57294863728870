import React from 'react';
import { ContentService } from '../../api';
import Content, { ContentType } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import EditablePage from '../../components/EditablePage';
import DefaultListItemTemplate from '../ContentManager/DefaultListItemTemplate';
import { DataLoadingContext } from '../../components/DataLoading';

const Handbook: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const [handbooks, setHandBooks] = React.useState<Content[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        toggleLoading(true);
        ContentService.getContents(ContentType.Handbook).then((response) => {
            setHandBooks(response.items);
        }).finally(()=>toggleLoading(false));
    }, []);

    return (
        <EditablePage>
            <div className="handbooks">
                {isEdit && (
                    <Button
                        onClick={() => history.push('/handbook/create-handbook')}
                        color="primary"
                        className="d-block mx-auto"
                    >
                        Create New Handbook
                    </Button>
                )}
                {handbooks.map((content, index) => (
                    <DefaultListItemTemplate content={content} key={index} viewDetail={(id)=>history.push(`/handbook/${id}`)}/>
                ))}
            </div>
        </EditablePage>
    );
};

export default Handbook;
