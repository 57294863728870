import React from 'react';
import { ProjectService } from '../../api';
import './projects.scss';
import Project from '../../models/Project';
import { useWindowDimensions } from '../../utils';
import { DataLoadingContext } from '../../components/DataLoading';
import FullGridList from './ProjectList/FullGridList';
import MiniGridList from './ProjectList/MiniGridList';
import ProjectWrapper from './ProjectWrapper';
import { useLocation } from 'react-router-dom';

const Projects: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [projects, setProjects] = React.useState<Project[]>([]);
    const { width } = useWindowDimensions();

    const location = useLocation();

    React.useEffect(() => {
        getData();
    }, [location.pathname]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ProjectService.getProjects(location.pathname === "/projects/archived")
            .then((response) => {
                setProjects(response);
            })
            .finally(() => toggleLoading(false));
    };

    return (
        <ProjectWrapper>
            {width > 1000 ? (
                <FullGridList data={projects} getData={getData} />
            ) : (
                <MiniGridList data={projects} getData={getData} />
            )}
        </ProjectWrapper>
    );
};
export default Projects;
