import React from 'react';
import './social.scss';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as RedditIcon } from '../../assets/icons/reddit.svg';
import { ReactComponent as PinterestIcon } from '../../assets/icons/pinterest.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import { ReactComponent as TikTokIcon } from '../../assets/icons/tiktok.svg';
import SocialMedia, { SocialMediaType } from '../../models/SocialMedia';
import { getListFromEnum, urlIsValid } from '../../utils';
import Typography from '../Typography';
import InputSelect from '../InputSelect';
import InputField from '../InputField';
import Button from '../Button';

type Props = {
    socials: SocialMedia[];
    onChange?: (value: SocialMedia[]) => void;
    showFollowers?:boolean; //defaults to true
};

const Socials: React.FC<Props> = ({socials, onChange, showFollowers=true}) => {
    const isEditable = onChange ? true : false;
    const [showError, setShowError] = React.useState(false);
    const [newSocial, setNewSocial] = React.useState<SocialMedia>({
        type: -1 as number,
        profileUrl: ''
    });

    const isSocialValid = (): boolean => {
        if ((newSocial.type as number) !=-1 && urlIsValid(newSocial.profileUrl)) {
            return true;
        } else {
            return false;
        }
    };

    const handleRemove = (index: number) => {
        const temp: Array<SocialMedia> = [...socials];
        temp.splice(index, 1);
        onChange && onChange(temp);
    };

    const handleChange = (name: string, value: string | number) => {
        setNewSocial({ ...newSocial, [name]: value });
    };

    const onAddSocial = () => {
        if (isSocialValid()) {
            const temp: Array<SocialMedia> = [...socials];
            const existingTypeIndex = temp.findIndex((el: SocialMedia) => el.type ===newSocial.type);
            if (existingTypeIndex !== -1) {
                temp.splice(existingTypeIndex, 1);
            }
            temp.push(newSocial);
            onChange && onChange(temp);
            setNewSocial({
                type: -1 as number,
                profileUrl: ''
            });
            setShowError(false);
        } else {
            setShowError(true);
        }
    };

    const socialOptions = getListFromEnum(Object.values(SocialMediaType));
    return (
        <>
            {isEditable && (
                <div className="social-input">
                    <div>
                        <Typography variant="body">Account</Typography>
                        <InputSelect
                            options={socialOptions}
                            name="type"
                            placeholder="social media"
                            onChange={handleChange}
                            value={newSocial.type}
                            error={showError && (newSocial.type as number)!==-1}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">Profile Url</Typography>
                        <InputField
                            onChange={handleChange}
                            name="profileUrl"
                            value={newSocial.profileUrl}
                            error={showError && !urlIsValid(newSocial.profileUrl)}
                            errorText="Please enter valid url"
                        />
                    </div>
                    {showFollowers && <div>
                        <Typography variant="body">Number of followers</Typography>
                        <InputField
                            onChange={handleChange}
                            name="followerCount"
                            type="number"
                            value={newSocial.followerCount}
                        />
                    </div>}
                    <Button color="secondary" onClick={onAddSocial}>
                        Add
                    </Button>
                </div>
            )}
            <div className={`social-list ${showFollowers? '':'no-followers'}`}>
                {socials.map((social, index) => (
                    <div className={`social-component-container ${isEditable ? '': 'no-border'}`} key={index}>
                        <a href={social.profileUrl} target="_blank" rel="noreferrer">
                            <SocialMediaIcon type={social.type} />
                        </a>
                        {(showFollowers && social.followerCount) ? (
                            <p className="followers">
                                <span>{social.followerCount}</span> followers
                            </p>
                        ): ''}
                        {isEditable && (
                            <span className="close-icon" onClick={()=>handleRemove(index)} title ="remove">
                                &#10006;
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};
const SocialMediaIcon: React.FC<{ type: SocialMediaType }> = ({ type }) => {
    switch (type) {
        case SocialMediaType.Facebook:
            return <FacebookIcon />;
        case SocialMediaType.Twitter:
            return <TwitterIcon />;
        case SocialMediaType.Instagram:
            return <InstagramIcon />;
        case SocialMediaType.Reddit:
            return <RedditIcon />;
        case SocialMediaType.Pinterest:
            return <PinterestIcon />;
        case SocialMediaType.LinkedIn:
            return <LinkedinIcon />;
        case SocialMediaType.Youtube:
            return <YoutubeIcon />;
        case SocialMediaType.TikTok:
                return <TikTokIcon/>
        default:
            return <FacebookIcon />;
    }
};

export default Socials;
