import React, { useEffect } from 'react';
import UserIcon from '../../assets/icons/user.svg';
import { urlIsValid } from '../../utils';
import Img from '../Img';
import './avatar.scss';

type Props = {
    source: string;
    name: string;
    shape?: "circle" | "square";
    size: 'small' | 'medium' | 'large';
};

const Avatar: React.FC<Props> = ({ source, name, size, shape = "round" }) => {
    const [imageSrc, setImageSrc] = React.useState<string | null>(null);
    useEffect(() => {
        if (source && urlIsValid(source)) {
            setImageSrc(source);
        } else {
            setImageSrc(UserIcon);
        }
    }, [source]);

    const handleError = () => {
        setImageSrc(UserIcon);
    };
    if (!imageSrc) return null;
    return (
        <div className={`avatar avatar-${size} avatar-${shape}`}>
            <Img
                src={imageSrc}
                onError={handleError}
                alt={name}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Contain the image within the circular container
                }}
            />
        </div>
    );
};

export default Avatar;
