import React from 'react';
import { authReducer, initialState, AuthContext, AuthActionType } from '../Authentication/state';
import { AuthService } from '../../api';
import PageLoader from '../../components/PageLoader';
import { NotificationContext, NotificationActionType } from '../../components/Notification/state';
import { useLocation, useHistory } from 'react-router-dom';
import LogoutIdleTimer from '../../components/LogoutIdleTimer';
import { getRedirectRoute } from '../../components/AppRouter/PrivateRoute';

type Props = {
    children: React.ReactNode;
};

const Authentication: React.FC<Props> = ({ children }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialState);
    const [authenticating, setAuthenticating] = React.useState(true);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        handleAuthentication();
    }, []);

    const handleAuthentication = () => {
        setAuthenticating(true);
        const token = localStorage.getItem('synnernation_token');
        if (token) {
            AuthService.loginWithToken()
                .then((response) => {
                    new Promise((resolve) => {
                        localStorage.setItem('synnernation_token', response.accessToken);
                        dispatch({ type: AuthActionType.LOGIN, payload: response});
                        notificationDispatch({
                            type: NotificationActionType.OPEN,
                            payload: {
                                text: `Welcome back ${response.fname}!`,
                                status: 'success',
                                autoClose: true,
                            },
                        });
                        if (location.pathname === '/') {
                            history.push(getRedirectRoute(response.role));
                        }
                        resolve('OK');
                    }).then(() => {
                        setAuthenticating(false);
                    });
                })
                .catch(() => {
                    setAuthenticating(false);
                });
        } else {
            setAuthenticating(false);
        }
    };

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {authenticating ? (
                <PageLoader />
            ) : (
                <>
                    <LogoutIdleTimer
                        isLoggedIn={state.isLoggedIn}
                        interval={30}
                        logout={() => {                            
                            history.push('/');
                            dispatch({ type: AuthActionType.LOGOUT });
                        }}
                    />
                    {children}
                </>
            )}
        </AuthContext.Provider>
    );
};

export default Authentication;
