import { process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridRowProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../../components/Typography';
import { UserReportGrid, UserReportStatus } from '../../../models/Report';
import { formatDate } from '../../../utils';
import { getColorByReportStatus } from '../../../utils/getColorByStatus';
import {ReactComponent as LinkIcon} from '../../../assets/icons/external-link.svg';
import {ReactComponent as CampaignIcon} from '../../../assets/icons/list.svg';
import IconButton from '../../../components/IconButton';
import { pagerMiniSettings } from '../../../utils/pagerSettings';

type Props = {
    data: UserReportGrid[];
    getData: (showArchived: boolean) => void;
};

const MiniGridList: React.FC<Props> = ({ data }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: UserReportStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `report--${getColorByReportStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children  as React.ReactNode);
    };

    return (
        <Grid
        style={{ height: '100%' }}  
        sortable={true}
        pageable={pagerMiniSettings}
        filterable={true}
        data={process(data, dataState)}
        {...dataState}
        onDataStateChange={(e:GridDataStateChangeEvent) => {
            history.replace({
                ...history.location,
                state: e.dataState
            });
            setDataState(e.dataState);
          }}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => props.dataItem.reportId ? history.push(`/reports/${props.dataItem.reportId}`): history.push({
                pathname: '/report/create-report',
                state: { campaignId: props.dataItem.campaignId, surveyId: props.dataItem.surveyId },
            })}
            rowRender={rowRender}
        >
            <GridColumn
                title="search by campaign"
                field="displayName"
                cell={(props: GridCellProps) => (
                    <td className="grid-item-mobile clickable-grid-custom-item">
                        <div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Name
                                </Typography>
                                <Typography variant="caption" weight={500}>
                                    {props.dataItem.projectName}
                                </Typography>
                                <Typography variant="caption">{props.dataItem.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Title
                                </Typography>
                                <Typography variant="caption">{props.dataItem.surveyName}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Date Updated
                                </Typography>
                                <Typography variant="caption">{formatDate(props.dataItem.dateUpdated)}</Typography>
                            </div>
                            <div className="legend">
                                <span className={`color-${getColorByReportStatus(props.dataItem.status)}`}>
                                    &#11044;
                                </span>
                                <Typography variant="body">
                                    {UserReportStatus[props.dataItem.status]}
                                </Typography>
                            </div>
                        </div>
                      <div className='grid-actions'>
                            <IconButton Icon={CampaignIcon} onClick={()=>history.push(`/campaigns/${props.dataItem.campaignId}/detail`)} color="info" tooltip='View campaign'/>
                            <IconButton Icon={LinkIcon} onClick={()=>history.push(`/reports/${props.dataItem.id}`)} color="info" tooltip='View Report'/>
                        </div>
                    </td>
                )}
            />
        </Grid>
    );
};

export default MiniGridList;
