import React from 'react';
import Button from '../../../components/Button';
import UserReport, { ReportData } from '../../../models/Report';
import { SurveyElement, SurveyElementType } from '../../../models/Survey';
import FieldDetail from './FieldDetail';
import SurveyCheckBox from './SurveyCheckbox';
import SurveyDropdown from './SurveyDropdown';
import SurveyInput from './SurveyInput';
import SurveyTextArea from './SurveyTextArea';
import SurveyRadio from './SurveyRadio';
import SurveyUpload from './SurveyUpload';
import FileDetail from './FileDetail';
import { NotificationActionType, NotificationContext } from '../../../components/Notification/state';
import SurveyWebContent from './SurveyWebContent';
import { useHistory } from 'react-router-dom';

type Props = {
    elements: SurveyElement[];
    formView?: boolean;
    handleSubmit?: (report: UserReport) => Promise<boolean>;
    userReport?: UserReport;
    canSubmit?: boolean;
};

const SurveyViewer: React.FC<Props> = ({
    elements,
    handleSubmit,
    userReport = { campaignId: '', surveyId: '', data: {} },
    formView = false,
    canSubmit = true,
}) => {
    const [report, setReport] = React.useState<UserReport>(userReport);
    const [loading, setLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const history = useHistory();

    const handleChange = (name: string, value: string) => {
        setReport({ ...report, data: { ...report.data, [name]: value } });
    };

    const onSubmit = () => {
        setLoading(true);
        handleSubmit &&
            handleSubmit(report)
                .then(() => {
                    setLoading(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: 'Report successfully submitted',
                            status: 'success',
                            autoClose: true,
                        },
                    });
                    history.goBack();
                })
                .catch((e) => {
                    setLoading(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                });
    };

    if (formView) {
        return (
            <>
                {elements.map((element: SurveyElement, index) => (
                    <div key={index}>
                        {element.type === SurveyElementType.Checkbox && (
                            <SurveyCheckBox
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.Dropdown && (
                            <SurveyDropdown
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.FileUpload && (
                            <SurveyUpload
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.Input && (
                            <SurveyInput
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.TextArea && (
                            <SurveyTextArea
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.RadioButton && (
                            <SurveyRadio
                                handleChange={handleChange}
                                data={element.metaData}
                                value={report.data[element.metaData.name as keyof ReportData]}
                            />
                        )}
                        {element.type === SurveyElementType.WebContent && <SurveyWebContent data={element.metaData} />}
                    </div>
                ))}
                {canSubmit && (
                    <div className="action-container">
                        <Button onClick={onSubmit} loading={loading}>
                            Submit
                        </Button>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <ol className="report-viewer">
                {elements.map((element: SurveyElement, index) => {
                    if (
                        element.type === SurveyElementType.Checkbox ||
                        element.type === SurveyElementType.Dropdown ||
                        element.type === SurveyElementType.RadioButton ||
                        element.type === SurveyElementType.Input ||
                        element.type === SurveyElementType.TextArea
                    ) {
                        return (
                            <li key={index}>
                                <FieldDetail
                                    data={element.metaData}
                                    value={report.data[element.metaData.name as keyof ReportData]}
                                />
                            </li>
                        );
                    }
                    if (element.type === SurveyElementType.FileUpload) {
                        return (
                            <li key={index}>
                                <FileDetail
                                    data={element.metaData}
                                    value={report.data[element.metaData.name as keyof ReportData]}
                                />
                            </li>
                        );
                    }
                    if (element.type === SurveyElementType.WebContent) {
                        <SurveyWebContent data={element.metaData} key={index} />;
                    }
                })}
            </ol>
        );
    }
};

export default SurveyViewer;
