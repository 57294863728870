import {
    EducationDetails,
    MusicComedyDetails,
    OnlineActivityDetails,
    OtherDetails,
    PersonalDetails,
} from '../../../models/Application';
import { ApplicationAction, ApplicationActionType } from './actions';

type Step = {
    label: string;
    index: number;
};
type ApplicationReducerState = {
    activeStep: Step;
    isComplete: boolean;
    isReview: boolean;
    total: number;
    personalDetails: PersonalDetails;
    educationDetails: EducationDetails;
    onlineActivityDetails: OnlineActivityDetails;
    musicComedyDetails: MusicComedyDetails;
    otherDetails: OtherDetails;
};

const steps: Array<Step> = [
    {
        label: 'Getting Started',
        index: 0,
    },
    {
        label: 'Education',
        index: 1,
    },
    {
        label: 'Online Activity',
        index: 2,
    },
    {
        label: 'Music/Comedy',
        index: 3,
    },
    {
        label: 'Others',
        index: 4,
    },
    {
        label: 'Review',
        index: 5,
    },
];

const initPersonalDetails: PersonalDetails = {
    lname: '',
    fname: '',
    addressLine1: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    dateOfBirth: '',
    howSyndicate: '',
    emailAddress: '',
    phoneNumber: '',
    preferredPronouns: ''
};

const initEducationDetails: EducationDetails = {
    isStudent: false,
    schoolYear: '',
    major: '',
    college: '',
    collegeCity: '',
    collegeState: '',
    expectedGraduation: '',
};

const initOnlineActivityDetails: OnlineActivityDetails = {
    emailFrequency: '',
    socialMedias: [],
};

const initMusicComedyDetails = {
    favouriteArtist: '',
    favouriteExplanation: '',
    showFrequency: '',
};

const initOtherDetails = {
    whatKindSmartPhone: '',
    freeTime: [],
    FreeTimeActivity: '',
    whyCareerGoal: '',
    streetTeamExperience: '',
    hasAgreedPolicy: false,
};

export const initialState: ApplicationReducerState = {
    activeStep: steps[0],
    isReview: false,
    total: steps.length,
    isComplete: false,
    personalDetails: initPersonalDetails,
    educationDetails: initEducationDetails,
    onlineActivityDetails: initOnlineActivityDetails,
    musicComedyDetails: initMusicComedyDetails,
    otherDetails: initOtherDetails,
};

export function applicationReducer(state: ApplicationReducerState, action: ApplicationAction): ApplicationReducerState {
    switch (action.type) {
        case ApplicationActionType.SET_ACTIVE_STEP:
            if (action.payload === 5) {
                return { ...state, activeStep: steps[action.payload], isReview: true };
            } else {
                return { ...state, activeStep: steps[action.payload] };
            }

        case ApplicationActionType.SET_COMPLETE:
            return { ...state, isComplete: true };
        case ApplicationActionType.SET_PERSONAL_DETAILS:
            return { ...state, personalDetails: action.payload };
        case ApplicationActionType.SET_EDUCATION_DETAILS:
            return { ...state, educationDetails: action.payload };
        case ApplicationActionType.SET_ONLINE_ACTIVITY_DETAILS:
            return { ...state, onlineActivityDetails: action.payload };
        case ApplicationActionType.SET_MUSIC_COMEDY_DETAILS:
            return { ...state, musicComedyDetails: action.payload };
        case ApplicationActionType.SET_OTHER_DETAILS:
            return { ...state, otherDetails: action.payload };
        default:
            return state;
    }
}
