import React from 'react';
import Content, { ContentStatus, ContentType } from '../../models/Content';
import ContentManager from '../ContentManager';
import Typography from '../../components/Typography';
import EditablePage from '../../components/EditablePage';

const initContact: Content = {
    type: ContentType.Contact,
    status: ContentStatus.Draft,
    title: '',
    description: '',
    data: '',
    isFeatured: false,
};

const CreateContact: React.FC = () => {
    return (
        <EditablePage>
            <div className="handbooks">
                <Typography variant="subtitle" textAlign="center">
                    CREATE CONTACT
                </Typography>
                <ContentManager content={initContact}>
                    <Typography variant="subtitle" color="primary" textAlign="center">
                        Please switch to editor mode to create a contact
                    </Typography>
                </ContentManager>
            </div>
        </EditablePage>
    );
};

export default CreateContact;
