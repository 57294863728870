import Campaign from "../../../../models/Campaign";
import { AvailableSynner } from "../../../../models/Invitation";
import { ProjectType } from "../../../../models/Project";

export enum InvitationActionType {
    SET_CAMPAIGN_ID,
    SET_AVAILABLE_SYNNERS,
    SET_CAMPAIGN_DETAIL,
    SET_SELECTED_SYNNER,
    REMOVE_SELECTED_SYNNERS,
    SET_DISTANCE,
    SET_TAGS,
    SET_TOTAL,
    SET_WORK_TYPE,
    SET_SEARCH_TEXT,
    CLEAR_FILTER,
    SET_PAGE,
    TOGGLE_DATA_FETCH,
}

export type CampaignSynnerAction =
    | { type: InvitationActionType.SET_AVAILABLE_SYNNERS; payload: AvailableSynner[] }
    | { type: InvitationActionType.SET_CAMPAIGN_DETAIL; payload: Campaign}
    | { type: InvitationActionType.SET_SELECTED_SYNNER; payload: AvailableSynner }
    | { type: InvitationActionType.SET_DISTANCE; payload: number }
    | { type: InvitationActionType.SET_TAGS; payload: string [] }
    | { type: InvitationActionType.SET_WORK_TYPE; payload: ProjectType}
    | { type: InvitationActionType.SET_SEARCH_TEXT; payload: string}
    | { type: InvitationActionType.SET_PAGE; payload: {page: number, pageSize: number}}
    | { type: InvitationActionType.CLEAR_FILTER}
    | { type: InvitationActionType.TOGGLE_DATA_FETCH}
    | { type: InvitationActionType.SET_TOTAL; payload: number }
    | {type: InvitationActionType.REMOVE_SELECTED_SYNNERS}