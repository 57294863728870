import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../AppRouter/routeConfig';

const LeftHelperLinks: React.FC<{ items: NavItem[]; handleNavigation: () => void }> = ({ items, handleNavigation }) => {
    return (
        <nav className="left-helper-section" role="navigation">
            <ul>
                {items.map((item, index) => (
                    <li key={index} aria-label="navigation item">
                        <Link to={item.route} onClick={() => handleNavigation()}>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default LeftHelperLinks;
