import { ProjectType } from './Project';

export default interface Survey {
    id?: string;
    type: ProjectType;
    isDefaultForType: boolean;
    title: string;
    status: SurveyStatus;
    totalSubmissions: number;
    description?: string;
    elements: SurveyElement[];
    createdAt?: string;
    createdById?: string;
    updatedAt?: string;
    updatedById?: string;
}

export enum SurveyStatus {
    Draft = 0,
    Published = 1
}

export type SurveyElement = 
    | {type: SurveyElementType.Checkbox; metaData: SurveyCheckboxData}
    | {type: SurveyElementType.Dropdown; metaData: SurveyDropdownData}
    | {type: SurveyElementType.FileUpload; metaData: SurveyUploadData}
    | {type: SurveyElementType.RadioButton; metaData: SurveyRadioData}
    | {type: SurveyElementType.Input; metaData: SurveyInputData}
    | {type: SurveyElementType.TextArea; metaData: SurveyTextAreaData}
    | {type: SurveyElementType.WebContent; metaData: string};

export enum SurveyElementType {    
    WebContent = 0,    
    Input = 1,
    TextArea = 2,
    FileUpload = 3,
    Dropdown = 4,
    RadioButton = 5,
    Checkbox = 6,
}

export type SurveyInputData = {
    name: string,
    title: string,
    description?: string,
    required?: boolean,
    type?: string
}

export type SurveyTextAreaData = {
    name: string,
    title: string,
    description?: string,
    required?: boolean
}

export type SurveyRadioData = {
    name: string,
    title: string,
    description?: string,
    required?: boolean,
    options: string [],
}

export type SurveyCheckboxData = {
    name: string,
    label: string,
    title: string,
    description?: string,
    required?: boolean
}

export type SurveyDropdownData = {
    name: string,
    title: string,
    description?: string,
    required?: boolean,
    options: string [],
}

export type SurveyUploadData = {
    name: string,
    title: string,
    description?: string,
    required?: boolean,
    isMultiple?: boolean
}