import { process, State } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../../components/Typography';
import { ActivityStatus } from '../../../models/Activity';
import { UserCampaign } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import { formatDate, formatLocation } from '../../../utils';
import { getColorByActivityStatus } from '../../../utils/getColorByStatus';
import { pagerMiniSettings } from '../../../utils/pagerSettings';
import { AuthContext } from '../../Authentication/state';
import ProjectAvatar from '../../Projects/ProjectAvatar';
import MyCampaignsActions from './MyCampaignActions';

type Props = {
    data: UserCampaign[];
    getData: () => void;
    openReport: (selectedId: string) => void;
};

const MiniGridList: React.FC<Props> = ({ data, getData, openReport }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { user } = React.useContext(AuthContext).state;
    const showActions = user && user.role === UserRole.Synner;
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    return (
        <Grid
            style={{ height: '100%' }}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            pageable={pagerMiniSettings}
            filterable={true}
            sortable={false}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/campaigns/${props.dataItem.campaignId}/detail`)}
        >
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn
                title="search by name"
                field="displayName"
                cell={(props: GridCellProps) => (
                    <td className="grid-item-mobile clickable-grid-custom-item">
                        <div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Name
                                </Typography>
                                <Typography variant="caption" weight={500}>
                                    {props.dataItem.projectName}
                                </Typography>
                                <Typography variant="caption">{props.dataItem.campaignName}</Typography>
                            </div>
                            <div>
                                {props.dataItem.startDate !== props.dataItem.endDate ? (
                                    <>
                                        <Typography variant="body" weight={800}>
                                            Duration
                                        </Typography>
                                        <Typography variant="caption" color="primary" weight={500}>
                                            {formatDate(props.dataItem.startDate)} -{' '}
                                            {formatDate(props.dataItem.endDate)}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body" weight={800}>
                                            Event Date
                                        </Typography>
                                        <Typography variant="caption" color="primary" weight={500}>
                                            {formatDate(props.dataItem.startDate)}
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Address
                                </Typography>
                                <Typography variant="caption">
                                    {props.dataItem.venue && (
                                        <>
                                            {props.dataItem.venue}
                                            <br />
                                        </>
                                    )}
                                    {formatLocation(
                                        props.dataItem.addressLine1,
                                        props.dataItem.addressLine2,
                                        props.dataItem.city,
                                        props.dataItem.state,
                                        props.dataItem.country,
                                        props.dataItem.zipCode,
                                    )}
                                </Typography>
                            </div>
                            <div className="legend">
                                <span className={`color-${getColorByActivityStatus(props.dataItem.activityStatus)}`}>
                                    &#11044;
                                </span>
                                <Typography variant="body">
                                    {ActivityStatus[props.dataItem.activityStatus]}
                                </Typography>
                            </div>
                        </div>
                        {showActions && (
                            <div className="grid-actions">
                                <MyCampaignsActions
                                    data={props.dataItem as UserCampaign}
                                    getData={getData}
                                    openReport={openReport}
                                />
                            </div>
                        )}
                    </td>
                )}
            />
        </Grid>
    );
};

export default MiniGridList;
