import React from 'react';
import { ActivityService } from '../../../api';
import Button from '../../../components/Button';
import ErrorInfo from '../../../components/ErrorInfo';
import { AvailableSynner, Invitation } from '../../../models/Invitation';
import { InvitationActionType, InvitationContext } from './state';

const Selections: React.FC = () => {
    const { state, dispatch } = React.useContext(InvitationContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleRemove = (synner: AvailableSynner) => {
        dispatch({
            type: InvitationActionType.SET_SELECTED_SYNNER,
            payload: synner,
        });
    };
    const handleInvite = () => {
        setLoading(true);
        const invitations: Invitation[] = [];
        state.selectedSynners.forEach((synner) => {
            invitations.push({
                campaignId: state.campaignDetail?.id as string,
                synnerId: synner.id
            });
        });
        ActivityService.InviteToCampaign(invitations)
            .then(() => {
                setLoading(false);
                dispatch({
                    type: InvitationActionType.REMOVE_SELECTED_SYNNERS,
                });
                dispatch({
                    type: InvitationActionType.TOGGLE_DATA_FETCH
                })
            })
            .catch((error) => {
                setLoading(false);
                setError(error.message);
            });
    };

    return (
        <>
            <div className="selection-container">
                <div className="selections">
                    {state.selectedSynners.map((synner, index) => (
                        <div className="selection-item" key={index}>
                            <p>{synner.name}</p>
                            <div
                                className="item-action"
                                onClick={() => handleRemove(synner)}
                                tabIndex={0}
                                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && handleRemove(synner)
                                }
                            >
                                <span className="close-icon" title ="remove">
                                    &#10006;
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <Button
                        onClick={handleInvite}
                        loading={loading}
                        disabled={state.selectedSynners.length === 0}
                    >
                        Invite
                    </Button>
                </div>
            </div>
            {error.length>0 && <><ErrorInfo error={error} onClose={()=>setError('')}/><br/></>}
        </>
    );
};

export default Selections;
