import { process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridFilterCellProps,
    GridRowProps,
    GridToolbar,
} from '@progress/kendo-react-grid';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import GridDropDownFilter from '../../../components/GridDropDownFilter';
import Tag from '../../../components/Tag';
import Project, {
    ProjectStatus,
    ProjectType
} from '../../../models/Project';
import { formatDate, getListFromEnum } from '../../../utils';
import { getColorByProjectStatus } from '../../../utils/getColorByStatus';
import pagerSettings from '../../../utils/pagerSettings';
import ProjectAvatar from '../ProjectAvatar';
import ProjectActions from './ProjectActions';

type Props = {
    data: Project[];
    getData: () => void;
};

const FullGridList: React.FC<Props> = ({ data, getData }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: ProjectStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `project--${getColorByProjectStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    const projectTypes = getListFromEnum(Object.values(ProjectType));

    const statuses = getListFromEnum(Object.values(ProjectStatus)).filter((el) => el.value !== ProjectStatus.Archived);

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const TypeFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={projectTypes} defaultValue={{ label: 'select type', value: '' }} />
    );

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const Status = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Tag
                    label={ProjectStatus[props.dataItem.status]}
                    color={getColorByProjectStatus(props.dataItem.status)}
                />
            </td>
        );
    };

    const Type = (props: GridCellProps) => {
        return <td>{ProjectType[props.dataItem.type]}</td>;
    };
    return (
        <Grid
            style={{
                height: '100%',
            }}
            sortable={true}
            pageable={pagerSettings}
            filterable={true}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/projects/${props.dataItem.id}/detail`)}
            rowRender={rowRender}
        >
            <GridToolbar>
                <Link to="/projects/create-project"><Button>Create Project</Button></Link>
            </GridToolbar>
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn field="name" title="Name" />
            {history.location.pathname !=="/projects/archived" && <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />}
            <GridColumn field="type" title="Type" cell={Type} filterCell={TypeFilterCell} />

            <GridColumn
                field="createdAt"
                title="Date Created"
                cell={DateCell}
                filterable={false}
                format="{0:s}"
                width="160px"
            />
            <GridColumn
                cell={(props: GridCellProps) => (
                    <td className="grid-actions">
                        <ProjectActions project={props.dataItem as Project} refreshData = {getData}/>
                    </td>
                )}
                filterable={false}
                sortable={false}
            />
        </Grid>
    );
};

export default FullGridList;
