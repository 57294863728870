import React from 'react';
import { ApplicationService } from '../../api';
import InputSelect from '../../components/InputSelect';
import Typography from '../../components/Typography';
import { Applicant, ApplicantionStatus } from '../../models/Application';
import { formatDate, getListFromEnum } from '../../utils';
type Props = {
    applicant: Applicant;
    handleStatusChange: () => void;
};
const ApplicationStatusActionBar: React.FC<Props> = ({ applicant, handleStatusChange }) => {
    const [status, setStatus] = React.useState<number>(-1);
    const [availableStatuses, setAvailableStatuses] = React.useState<{label: string, value: string | number}[]>([]);

    React.useEffect(()=> {
        setStatus(applicant.status);
        const temp = getListFromEnum(Object.values(ApplicantionStatus)).filter((el)=>Number(el.value)>=applicant.status);
        setAvailableStatuses(temp);
    }, [applicant.status]);

    const handleChange = (value: string | number) => {
        setStatus(value as ApplicantionStatus);
        if(value !== status) ApplicationService.changeStatus(applicant.id, value as ApplicantionStatus).then(()=>{            
            handleStatusChange();
        })
    };

    return (
        <div className="status-action-bar">
            <Typography variant="subtitle" weight={500} color="secondary">
                Application Timeline
            </Typography>
            <div className="timeline-status">
                <div className="timeline">
                    <Typography variant="caption">
                        <strong>Application received: </strong>
                        {formatDate(applicant.applicationReceivedAt)}
                    </Typography>
                    {applicant.interviewRequestedAt && (
                        <Typography variant="caption">
                            <strong>Interview Requested: </strong> {formatDate(applicant.interviewRequestedAt)}
                        </Typography>
                    )}
                    {applicant.approvedAt && (
                        <Typography variant="caption">
                            <strong>Application approved: </strong>
                            {formatDate(applicant.approvedAt)}
                        </Typography>
                    )}
                    {applicant.rejectedAt && (
                        <Typography variant="caption">
                            <strong>Application rejected: </strong>
                            {formatDate(applicant.rejectedAt)}
                        </Typography>
                    )}
                </div>
                {status !== ApplicantionStatus.Approved && applicant.status !== ApplicantionStatus.Denied && (
                    <div className="action-items">
                        <Typography variant="caption">Application Status</Typography>
                        <InputSelect
                            placeholder="Change status"
                            onChange={(_name, value) => handleChange(value)}
                            options={availableStatuses}
                            name="status"
                            value={status}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApplicationStatusActionBar;
