import React from 'react';
import { ActivityService } from '../../api';
import Typography from '../../components/Typography';
import { ActivityStats } from '../../models/Activity';

const CampaignStats: React.FC = () => {
    const [stats, setStats] = React.useState<ActivityStats | null>(null);

    React.useEffect(() => {
        ActivityService.getActivityStats().then((response) => {
            setStats(response);
        });
    }, []);
    return <>{stats &&  (
        <div className="dashboard-grid-item campaign-stats">
            <div className="dashboard-grid-item-header">
                <Typography variant="subtitle">Campaigns</Typography>
            </div>
            <div className="dashboard-grid-item-content">
                <Typography variant="subtitle">
                    <span className="color-info typography--title">{stats.totalCompletedCampaigns}</span> completed
                </Typography>
                <Typography variant="subtitle">
                    <span className="color-info typography--title">{stats.totalInvolvedCampaigns}</span> scheduled
                </Typography>
            </div>
        </div>
    ) }</>;
};

export default CampaignStats;
