import React from 'react';
import { UserService } from '../../api';
import Typography from '../../components/Typography';
import Profile, { SynnerTag } from '../../models/Profile';
import Button from '../../components/Button';
import CreatableSelect from 'react-select/creatable';
import { components, OptionProps } from 'react-select';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/x.svg';

const TagManager: React.FC<{ synner: Profile; handleUpdateTags: (tags: string[]) => void }> = ({
    synner,
    handleUpdateTags,
}) => {
    const [availableTags, setAvailableTags] = React.useState<SynnerTag[]>([]);
    const [synnerTags, setSynnerTags] = React.useState<SynnerTag[]>([]);

    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        UserService.getTags().then((response) => {
            setAvailableTags(response);
        });
    };

    React.useEffect(() => {
        if (synner.tags) {
            const temp = availableTags.filter((el) => synner.tags?.includes(el.label));
            setSynnerTags(temp);
        }
    }, [availableTags]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (newValue: any) => {
        setSynnerTags([...newValue]);
    };

    const updateTags = () => {
        const arrayString: string[] = [];
        synnerTags.forEach((el) => {
            arrayString.push(el.label);
        });
        handleUpdateTags(arrayString);
    };

    const Option: React.FC<OptionProps<SynnerTag>> = (props: OptionProps<SynnerTag>) => {
        const [editedValue, setEditedValue] = React.useState(props.data.label);
        const optionRef = React.useRef<HTMLDivElement | null>(null);
        const inputRef = React.useRef<HTMLInputElement | null>(null);
        const handleSave = () => {
            UserService.editTag({ label: props.data.label, value: editedValue }).then(() => {
                getData();
            });
            optionRef.current?.classList.remove('edit-view');
        };
        return (
            <div className="tag-option" ref={optionRef}>
                <components.Option {...props} className="tag-option-item" />
                <input
                    type="text"
                    onChange={(e) => setEditedValue(e.target.value)}
                    value={editedValue}
                    onKeyDownCapture={(e) => {
                        e.stopPropagation();
                    }}
                    ref={inputRef}
                    autoFocus
                />
                <div className="tag-option-action">
                    <EditIcon
                        onClick={(e) => {
                            optionRef.current?.classList.add('edit-view');
                            inputRef.current?.focus();
                            setMenuIsOpen(true);
                            e.stopPropagation();
                        }}
                        className="edit-icon"
                    />
                    <SaveIcon
                        onClickCapture={(e) => {
                            e.stopPropagation();
                            handleSave();
                        }}
                        className="save-icon"
                    />
                    <CancelIcon
                        onClickCapture={(e) => {
                            optionRef.current?.classList.remove('edit-view');
                            setEditedValue(props.data.label);
                            e.stopPropagation();
                        }}
                        className="cancel-icon"
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <br />
            <hr />
            <Typography variant="body">Manage Tags</Typography>
            <CreatableSelect
                onChange={handleChange}
                options={availableTags}
                value={synnerTags}
                isMulti={true}
                components={{ Option }}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                closeMenuOnSelect={false}
            />
            <div className="tag-manager-action">
                <Button color="primary" onClick={updateTags}>
                    Update Tags
                </Button>
            </div>
        </>
    );
};

export default TagManager;
