import React from 'react';
import './dataloader.scss';

const DataLoading: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    const [loading, setLoading] = React.useState(false);

    const toggleLoading = (isLoading:boolean) => {
        setLoading(isLoading);
    };

    return (
        <DataLoadingContext.Provider value={{ dataLoading: loading, toggleLoading }}>
            <div className={`linear-loader ${loading ? "loading": ""}`}></div>
            {children}
        </DataLoadingContext.Provider>
    );
};

export const DataLoadingContext = React.createContext<{
    dataLoading: boolean;
    toggleLoading: (isLoading: boolean) => void;
}>({
    dataLoading: false,
    toggleLoading: () => undefined,
});

export default DataLoading;
