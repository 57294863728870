import instance from './instance';

import Campaign, { CampaignStatus, CampaignSurvey } from '../models/Campaign';

function getCampaigns(includeArchived = false): Promise<Campaign[]> {
    return instance.get(`campaigns?includeArchived=${includeArchived}`).then((response) => {
        const temp = [...response.data];
        temp.forEach((element) => {
            element.startDate = new Date(element.startDate);
            element.endDate = new Date(element.startDate);
        });
        return temp;
    });
}

function getCampaign(id: string): Promise<Campaign> {
    return instance.get(`campaign/${id}`).then((response) => {
        return response.data;
    });
}

function deleteCampaign(id: string): Promise<boolean> {
    return instance.delete(`campaign/${id}`).then((response) => {
        return response.data;
    });
}

function createCampaign(campaign: Campaign): Promise<Campaign> {
    return instance.post('campaign', campaign).then((response) => {
        return response.data;
    });
}

function updateCampaign(campaign: Campaign): Promise<Campaign> {
    return instance.put('campaign', campaign).then((response) => {
        return response.data;
    });
}

function updateCampaignSurveys(campaignId: string, surveys: CampaignSurvey[]): Promise<boolean> {
    const req = surveys.map((el) => el.id);
    return instance.put(`campaign/surveys`, { id: campaignId, surveys: req }).then((response) => {
        return response.data;
    });
}

function updateCampaignStatus(campaignId:string, status:CampaignStatus): Promise<boolean> {
    return instance.put(`campaign/update-status`, {id:campaignId, status: status}).then((response)=>{
        return response.data;
    })
}

export {
    getCampaigns,
    deleteCampaign,
    createCampaign,
    updateCampaign,
    getCampaign,
    updateCampaignSurveys,
    updateCampaignStatus
};
