import React from 'react';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import { MusicComedyDetails } from '../../models/Application';
import InputSelect from '../../components/InputSelect';

type Props = {
    data: MusicComedyDetails;
    handleSave: (data: MusicComedyDetails) => void;
    handleButtonClick: (action: string) => void;
};

const MusicComedy: React.FC<Props> = ({ data, handleSave, handleButtonClick }) => {
    const [musicComedyDetails, setMusicComedyDetails] = React.useState(data);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setMusicComedyDetails(data);
    }, [data]);

    const handleChange = (name: string, value: string | number) => {
        setMusicComedyDetails({ ...musicComedyDetails, [name]: value });
    };

    const handleSubmit = (): void => {
        const isValid =
            musicComedyDetails.favouriteArtist.length > 0 &&
            musicComedyDetails.favouriteExplanation.length &&
            musicComedyDetails.showFrequency.length > 0;

        if (!isValid) {
            setShowError(true);
        } else {
            handleSave(musicComedyDetails);
            handleButtonClick('NEXT');
        }
    };

    const handleBack = ()=> {
        handleButtonClick('BACK');
    }

    const frequency = [
        {
            label: 'multiple times a week',
            value: 'multiple times a week',
        },
        {
            label: 'once a week',
            value: 'once a week',
        },
        {
            label: 'multiple times a month',
            value: 'multiple times a month',
        },        
        {
            label: 'once a month',
            value: 'once a month',
        },                
        {
            label: 'multiple times a year',
            value: 'multiple times a year',
        },                        
        {
            label: 'once a year',
            value: 'once a year',
        },                       
        {
            label: 'never',
            value: 'never',
        },
    ];

    return (
        <>
            <div>
                <Typography variant="body" color="secondary">
                    What are your favorite bands / singers or songwriters / comedians?
                    <span className="color-error">*</span>
                </Typography>
                <TextArea
                    onChange={handleChange}
                    name="favouriteArtist"
                    value={musicComedyDetails.favouriteArtist}
                    error={showError && musicComedyDetails.favouriteArtist.length === 0}
                    errorText="required"
                />
            </div>
            <div>
                <div>
                    <Typography variant="body">
                        Pick one from above and explain what excited you about them.
                        <span className="color-error">*</span>
                    </Typography>
                    <TextArea
                        onChange={handleChange}
                        name="favouriteExplanation"
                        value={musicComedyDetails.favouriteExplanation}
                        error={showError && musicComedyDetails.favouriteExplanation.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body" color="secondary">
                        How often do you attend shows? <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={frequency}
                        value={musicComedyDetails.showFrequency}
                        name="showFrequency"
                        placeholder="frequency"
                        onChange={handleChange}
                        error={showError && musicComedyDetails.showFrequency.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="action-container">
                <Button color="primary" onClick={handleBack}>
                    Back
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default MusicComedy;
