import React from 'react';
import IconButton from '../IconButton';
import { AlertDialogActionType, AlertDialogContext } from './state';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-circle.svg';
import Typography from '../Typography';
import '../Modal/modal.scss';
import Button from '../Button';

const AlertDialog: React.FC = () => {
    const { alertDialogState, alertDialogDispatch } = React.useContext(AlertDialogContext);
    const close = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.CLOSE,
        });
    };
    const handleConfirm =()=> {
        if(alertDialogState.handleConfirm) {
            alertDialogState.handleConfirm();
        }
        close();
    }
    return (
        <>
            {alertDialogState.isOpen && (
                <div className="modal-container" onClick={close}>
                    <div className={`modal-wrapper modal-wrapper--small`} onClick={(e)=>e.stopPropagation()}>
                        <div className='header'>
                            <div className="modal-close">
                                <IconButton Icon={CloseIcon} onClick={close} tooltip="close" />
                            </div>
                            <Typography variant="subtitle" color="secondary" weight={500}>
                                {alertDialogState.title}
                            </Typography>
                        </div>
                        <div className="modal-content">
                            <Typography variant="body">{alertDialogState.description}</Typography>
                            <div className="action-bar">
                                <Button color='secondary' onClick={close}>Cancel</Button>
                                {alertDialogState.handleConfirm && <Button color='secondary' onClick={handleConfirm}>{alertDialogState.confirmButtonText? alertDialogState.confirmButtonText:'Confirm'}</Button>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AlertDialog;
