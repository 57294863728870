import React from 'react';
import Profile from '../../models/Profile';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import InputSelect from '../../components/InputSelect';
import Radio from '../../components/Radio';
import Typography from '../../components/Typography';
import {
    dateIsValid,
    emailIsValid,
    formatDateOnly,
    formatLocation,
    formatPhoneNumber,
    getStates,
    phoneNumberIsValid,
    zipCodeIsValid,
} from '../../utils';
import Socials from '../../components/Socials';
import SocialMedia from '../../models/SocialMedia';
import { AuthService } from '../../api';
import { UserRole } from '../../models/User';
import DateInput from '../../components/DateInput';

type Props = {
    isEditView: boolean;
    profileData: Profile;
    cancelUpdate: () => void;
    updateProfile: (updatedProfile: Profile) => Promise<boolean>;
    handleUpdateError: ()=> void;
};

const ProfileInfo: React.FC<Props> = ({ profileData, cancelUpdate, updateProfile, isEditView, handleUpdateError }) => {
    const [profileInfo, setProfileInfo] = React.useState(profileData);
    const [showError, setShowError] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [emailExists, setEmailExists] = React.useState(false);

    const states = getStates();
    //const paymentOptions = getListFromEnum(Object.values(PaymentOption));

    const handleChange = (name: string, value: string | boolean | number) => {
        setProfileInfo({ ...(profileInfo as Profile), [name]: value });
    };

    const handleCancel = () => {
        setProfileInfo(profileData);
        cancelUpdate();
    };

    const handleSocialChange = (value: SocialMedia[]) => {
        setProfileInfo({ ...(profileInfo as Profile), socialMedias: value });
    };

    const handleSubmit = () => {
        const isValid =
            profileInfo &&
            profileInfo.fname.length > 0 &&
            profileInfo.lname.length > 0 &&
            dateIsValid(profileInfo.dateOfBirth) &&
            emailIsValid(profileInfo.emailAddress) &&
            (profileInfo.workEmailAddress && profileInfo.workEmailAddress.length > 0
                ? emailIsValid(profileInfo.workEmailAddress)
                : true) &&
            phoneNumberIsValid(profileInfo.phoneNumber) &&
            (profileInfo.workPhoneNumber && profileInfo.workPhoneNumber.length > 0
                ? phoneNumberIsValid(profileInfo.workPhoneNumber)
                : true) &&
            profileInfo.addressLine1.length > 0 &&
            profileInfo.city.length > 0 &&
            profileInfo.state.length > 0 &&
            zipCodeIsValid(profileInfo.zipCode)
        if (!isValid) {
            handleUpdateError();
            setShowError(true);
        } else {            
            setLoading(true);
            updateProfile(profileInfo).then(() => {
                if(profileInfo.role === UserRole.OnBoarding) window.location.reload();
                setLoading(false);
            });
        }
    };

    const handleEmailBlurred = () => {
        if(emailIsValid(profileInfo.emailAddress) && profileData.emailAddress !==profileInfo.emailAddress){
            AuthService.doesEmailExists(profileInfo.emailAddress)
            .then((response) => {
                setEmailExists(response);
            }).catch(() => {
                setEmailExists(false);
            })
        }
    }

    return (
        <>
            <Typography variant="title" weight={500} color="secondary">
                Personal Information
            </Typography>
            <hr />
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        First Name <span className="color-error">*</span>
                    </Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="fname"
                            value={profileInfo.fname}
                            error={showError && profileInfo.fname.length === 0}
                            errorText="required"
                        />
                    ) : (
                        <Typography variant="caption">{profileInfo.fname}</Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">
                        Last Name <span className="color-error">*</span>
                    </Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="lname"
                            value={profileInfo.lname}
                            error={showError && profileInfo.lname.length === 0}
                            errorText="required"
                        />
                    ) : (
                        <Typography variant="caption">{profileInfo.lname}</Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">Pronouns</Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="preferredPronouns"
                            value={profileInfo.preferredPronouns}
                        />
                    ) : (
                        <Typography variant="caption">
                            {profileInfo.preferredPronouns && profileInfo.preferredPronouns.length > 0
                                ? profileInfo.preferredPronouns
                                : 'N/A'}
                        </Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">
                        Date Of Birth <span className='typography--caption'>(MM/DD/YYYY)</span> <span className="color-error">*</span>
                    </Typography>
                    {isEditView ? (
                        <DateInput onChange={handleChange} value={profileInfo.dateOfBirth} name="dateOfBirth" error={showError && !dateIsValid(profileInfo.dateOfBirth)} errorText="Please enter a valid date of birth"/> 
                    ) : (
                        <Typography variant="caption">{formatDateOnly(profileInfo.dateOfBirth)}</Typography>
                    )}
                </div>
            </div>
            <br/>
            <Typography variant="title" weight={500} color="secondary">
                Contact Information
            </Typography>
            <hr />
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Personal Email <span className="color-error">*</span>
                    </Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="emailAddress"
                            onBlur={handleEmailBlurred}
                            value={profileInfo.emailAddress}
                            error={emailExists || (showError && !emailIsValid(profileInfo.emailAddress))}
                            errorText={!emailExists ? `Invalid email address` : 'user with email already exists'}
                        />
                    ) : (
                        <Typography variant="caption">{profileInfo.emailAddress}</Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">Work Email</Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="workEmailAddress"
                            value={profileInfo.workEmailAddress}
                            error={
                                showError &&
                                (profileInfo.workEmailAddress ? !emailIsValid(profileInfo.workEmailAddress) : false)
                            }
                            errorText="Invalid email address"
                        />
                    ) : (
                        <Typography variant="caption">
                            {profileInfo.workEmailAddress && profileInfo.workEmailAddress.length > 0
                                ? profileInfo.workEmailAddress
                                : 'N/A'}
                        </Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">
                        Phone Number <span className="color-error">*</span>
                    </Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="phoneNumber"
                            value={profileInfo.phoneNumber}
                            error={showError && !phoneNumberIsValid(profileInfo.phoneNumber)}
                            errorText="Invalid phone number"
                        />
                    ) : (
                        <Typography variant="caption">{formatPhoneNumber(profileInfo.phoneNumber)}</Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">Home Phone</Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="homePhoneNumber"
                            value={profileInfo.homePhoneNumber}
                            error={
                                showError &&
                                (profileInfo.homePhoneNumber ? !phoneNumberIsValid(profileInfo.homePhoneNumber) : false)
                            }
                            errorText="Invalid phone number"
                        />
                    ) : (
                        <Typography variant="caption">
                            {profileInfo.homePhoneNumber && profileInfo.homePhoneNumber.length > 0
                                ? formatPhoneNumber(profileInfo.homePhoneNumber)
                                : 'N/A'}
                        </Typography>
                    )}
                </div>
                <div>
                    <Typography variant="body">Work Phone</Typography>
                    {isEditView ? (
                        <InputField
                            onChange={handleChange}
                            name="workPhoneNumber"
                            value={profileInfo.workPhoneNumber}
                            error={
                                showError &&
                                (profileInfo.workPhoneNumber ? !phoneNumberIsValid(profileInfo.workPhoneNumber) : false)
                            }
                            errorText="Invalid phone number"
                        />
                    ) : (
                        <Typography variant="caption">
                            {profileInfo.workPhoneNumber && profileInfo.workPhoneNumber.length > 0
                                ? formatPhoneNumber(profileInfo.workPhoneNumber)
                                : 'N/A'}
                        </Typography>
                    )}
                </div>
            </div>
            <br/>
            <Typography variant="title" weight={500} color="secondary">
                Mailing Address
            </Typography>
            {isEditView && <Typography variant="caption" color="secondary">
                *We do not ship to P.O. Boxes
            </Typography>}
            <hr />
            {isEditView ? (
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body">
                            Street Address <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={profileInfo.addressLine1}
                            name="addressLine1"
                            error={showError && profileInfo.addressLine1.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">Apt/Unit/Suite</Typography>
                        <InputField onChange={handleChange} name="addressLine2" value={profileInfo.addressLine2} />
                    </div>

                    <div>
                        <Typography variant="body">
                            City <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={profileInfo.city}
                            name="city"
                            error={showError && profileInfo.city.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            State <span className="color-error">*</span>
                        </Typography>
                        <InputSelect
                            options={states}
                            value={profileInfo.state}
                            name="state"
                            placeholder="State"
                            onChange={handleChange}
                            error={showError && profileInfo.state.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Zip Code <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={profileInfo.zipCode}
                            name="zipCode"
                            type="number"
                            error={showError && !zipCodeIsValid(profileInfo.zipCode)}
                            errorText="Invalid zip Code"
                        />
                    </div>
                </div>
            ) : (
                <Typography variant="caption">
                    {formatLocation(
                        profileInfo.addressLine1,
                        profileInfo.addressLine2,
                        profileInfo.city,
                        profileInfo.state,
                        profileInfo.country,
                        profileInfo.zipCode,
                    )}
                </Typography>
            )}
            <br/>
            {(isEditView || profileInfo.socialMedias.length > 0) && (
                <Typography variant="title" weight={500} color="secondary">
                    Social Media
                </Typography>
            )}
            <hr />
            <Socials socials={profileInfo.socialMedias} onChange={isEditView ? handleSocialChange : undefined} />
            <br/>
            <Typography variant="title" weight={500} color="secondary">
                Preferences
            </Typography>
            <hr />  
            {/* <div>
                <Typography variant="body">
                    How would you like to receive payments? <span className="color-error">*</span>
                </Typography>
                {isEditView ? (
                    <InputSelect
                    options={paymentOptions}
                    value={profileInfo.preferredPaymentMethod}
                    name="preferredPaymentMethod"
                    placeholder="Payment method"
                    onChange={handleChange}
                />
                ) : (
                    <Typography variant="caption">{PaymentOption[profileInfo.preferredPaymentMethod]}</Typography>
                )}
            </div> 
            <br/>          */}
            <div>
                <Typography variant="body">
                    Would you like to share your contact info with other Synners?
                </Typography>
                {isEditView && <Typography variant="caption">*This will not include your Mailing Address.</Typography>}
                {isEditView ? (
                    <>
                        <Radio
                            onChange={() => handleChange('viewableBySynners', true)}
                            checked={profileInfo.viewableBySynners}
                            label="Yes"
                        />
                        <Radio
                            onChange={() => handleChange('viewableBySynners', false)}
                            checked={!profileInfo.viewableBySynners}
                            label="No"
                        />
                    </>
                ) : (
                    <Typography variant="caption">{profileInfo.viewableBySynners ? 'YES' : 'NO'}</Typography>
                )}
            </div>
            <br/>
            {profileInfo.viewableBySynners && (
                <div>
                    <Typography variant="body">
                        Would you like to show all of your profile information to other Synners?
                    </Typography>
                    {isEditView ? (
                        <>
                            <Radio
                                onChange={() => handleChange('showProfileInfo', true)}
                                checked={profileInfo.showProfileInfo}
                                label="Yes"
                            />
                            <Radio
                                onChange={() => handleChange('showProfileInfo', false)}
                                checked={!profileInfo.showProfileInfo}
                                label="No"
                            />
                        </>
                    ) : (
                        <Typography variant="caption">{profileInfo.showProfileInfo ? 'YES' : 'NO'}</Typography>
                    )}
                </div>
            )}
            {isEditView && (
                <div className="action-container">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit} loading={loading}>
                        Update
                    </Button>
                </div>
            )}
        </>
    );
};

export default ProfileInfo;
