import { Grid, GridCellProps, GridColumn, GridRowProps } from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProjectService } from '../../../api';
import { DataLoadingContext } from '../../../components/DataLoading';
import Typography from '../../../components/Typography';
import Campaign, { CampaignStatus } from '../../../models/Campaign';
import { formatDate, formatLocation, useWindowDimensions } from '../../../utils';
import { getColorByCampaignStatus } from '../../../utils/getColorByStatus';
import CampaignAddressCell from '../../Campaigns/CampaignList/CampaignAddressCell';

const ProjectCampaigns: React.FC = () => {
    const [data, setData] = React.useState<Campaign[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const { id } = useParams<{ id: string }>();
    const { width } = useWindowDimensions();
    const history = useHistory();

    React.useEffect(() => {
        toggleLoading(true);
        ProjectService.getProjectCampaigns(id)
            .then((response) => {
                setData(response);
            })
            .finally(() => toggleLoading(false));
    }, [id]);

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: CampaignStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = {
            className: `campaign--${getColorByCampaignStatus(status)}`,
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children  as React.ReactNode);
    };

    return (
        <div className="project-campaigns">
            <Typography variant="subtitle" color="secondary">
                PROJECT CAMPAIGNS
            </Typography>
            <hr />
            <br />
            <Grid
                style={{ height: '100%' }} 
                data={data}
                // eslint-disable-next-line react/prop-types
                onRowClick={(props) => history.push(`/campaigns/${props.dataItem.id}/detail`)}
                rowRender={rowRender}
            >
                {width <= 1000 && (
                    <GridColumn
                        cell={(props: GridCellProps) => (
                            <td>
                                <Typography variant="body" weight={600}>
                                    {props.dataItem.name}
                                </Typography>
                                <Typography variant="caption">
                                    {props.dataItem.venue && (
                                        <>
                                            <b>{props.dataItem.venue}</b>
                                            <br />
                                        </>
                                    )}
                                    {formatLocation(
                                        props.dataItem.addressLine1,
                                        props.dataItem.addressLine2,
                                        props.dataItem.city,
                                        props.dataItem.state,
                                        props.dataItem.country,
                                        props.dataItem.zipCode,
                                    )}
                                </Typography>
                            </td>
                        )}
                    />
                )}
                {width > 1000 && <GridColumn title="Name" field="name" />}
                {width > 1000 && <GridColumn title="Location" cell={CampaignAddressCell} />}
                <GridColumn field="startDate" title="Start Date" cell={DateCell} width="100px"/>
                <GridColumn field="endDate" title="End Date" cell={DateCell} width="100px"/>
            </Grid>
        </div>
    );
};
export default ProjectCampaigns;
