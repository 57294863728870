import React from 'react';
import Typography from '../../components/Typography';
import PaymentInvoice from '../../models/Payment';
import { Link } from 'react-router-dom';
import { ReactComponent as CampaignIcon } from '../../assets/icons/list.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/trello.svg';
import { ReactComponent as VendorIcon } from '../../assets/icons/user.svg';
import IconButton from '../../components/IconButton';

type Props = {
    invoice: PaymentInvoice;
};

const InvoiceDetail: React.FC<Props> = ({ invoice }) => {
    return (
        <>
            <div className="grid-actions" style={{ float: 'right' }}>
                <Link to={`/projects/${invoice['projectId']}/detail`}>
                    <IconButton Icon={ProjectIcon} color="info" tooltip="Project detail" />
                </Link>
                <Link to={`/campaigns/${invoice['campaignId']}/detail`}>
                    <IconButton Icon={CampaignIcon} color="info" tooltip="Campaign detail" />
                </Link>
                <Link to={`/users/${invoice['vendorId']}/detail`}>
                    <IconButton Icon={VendorIcon} color="info" tooltip="Vendor detail" />
                </Link>
            </div>
            <Typography variant="subtitle" weight={800}>
                Amount
            </Typography>
            <Typography variant="subtitle">${invoice.amount}</Typography>
            <Typography variant="subtitle" weight={800}>
                Payment Source
            </Typography>
            <Typography variant="subtitle">{invoice.methodOfPayment}</Typography>
            {invoice.notes && invoice.notes.length > 0 && (
                <>
                    <Typography variant="subtitle" weight={800}>
                        Notes
                    </Typography>
                    <Typography variant="subtitle">{invoice.notes}</Typography>
                </>
            )}
            <Typography variant="body" weight={600}>
                Vendor
            </Typography>
            <Typography variant="body">{invoice.displayName}</Typography>
            {invoice.client && invoice.client.length > 0 && (
                <>
                    <Typography variant="body" weight={600}>
                        Client
                    </Typography>
                    <Typography variant="body">{invoice.client}</Typography>
                </>
            )}
            <Typography variant="body" weight={600}>
                Campaign
            </Typography>
            <Typography variant="body">{invoice.campaignName}</Typography>
            <Typography variant="body" weight={600}>
                Project
            </Typography>
            <Typography variant="body">{invoice.projectName}</Typography>
        </>
    );
};

export default InvoiceDetail;
