import React from 'react';
import { ActivityService } from '../../../api';
import { DataLoadingContext } from '../../../components/DataLoading';
import Modal from '../../../components/Modal';
import { UserCampaign } from '../../../models/Campaign';
import { useWindowDimensions } from '../../../utils';
import CampaignWrapper from '../CampaignWrapper';
import FullGridList from './FullGridList';
import MiniGridList from './MiniGridList';
import './my-campaign.scss';
import UserSubmissions from '../UserSubmissions';

const MyCampaigns: React.FC = () => {
    const [data, setData] = React.useState<UserCampaign[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [selectedCampaignId, setSelectedCampaignId] = React.useState('');

    const { width } = useWindowDimensions();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ActivityService.getUserCampaigns()
            .then((response) => setData(response))
            .finally(() => toggleLoading(false));
    };
    return (
        <CampaignWrapper>
            <>
                {width > 1000 ? (
                    <FullGridList data={data} getData={getData} openReport={(campaignId)=>setSelectedCampaignId(campaignId)}/>
                ) : (
                    <MiniGridList data={data} getData={getData} openReport={(campaignId)=>setSelectedCampaignId(campaignId)}/>
                )}
                <Modal
                    isOpen={selectedCampaignId.length !== 0}
                    close={() => setSelectedCampaignId('')}
                    size="medium"
                    title="Campaign Reports"
                >
                    <UserSubmissions campaignId={selectedCampaignId} />
                </Modal>
            </>
        </CampaignWrapper>
    );
};

export default MyCampaigns;
