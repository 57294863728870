import React from 'react';
import './typography.scss';

type Props = {
    variant: variant;
    color?: string;
    children: React.ReactNode;
    textAlign?: align;
    weight?: fontWeight;
};

type variant = 'heading' | 'subheading' | 'title' | 'subtitle' | 'body' | 'caption';

type fontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 800;

type align = 'left' | 'center' | 'right';

const Typography: React.FC<Props> = ({ variant, color, weight, textAlign, children}) => {
    const getContent = (): React.ReactNode => {
        const colorClass = color ? `color-${color} `: '';
        const textAlignClass = textAlign ? `text-${textAlign} ` : '';
        const fontWeightClass = weight ? `font-${weight}` : '';
        const variantClass = `typography--${variant} `;
        switch (variant) {
            case 'heading':
                return (
                    <h1 className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</h1>
                );
            case 'subheading':
                return (
                    <h2 className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</h2>
                );
            case 'title':
                return (
                    <h4 className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</h4>
                );
            case 'subtitle':
                return (
                    <h5 className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</h5>
                );
            case 'body':
                return (
                    <p className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</p>
                );
            case 'caption':
                return (
                    <p className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</p>
                );
            default:
                return (
                    <p className={`${variantClass}${colorClass}${textAlignClass}${fontWeightClass}`}>{children}</p>
                );
        }
    };

    return <> {getContent()}</>;
};
export default Typography;
