import User from '../../../models/User';

export enum AuthActionType {
    LOGIN,
    LOGOUT,
    SET_EDIT
}

//actions
export type AuthAction = { type: AuthActionType.LOGIN; payload: User } | { type: AuthActionType.LOGOUT } | {type: AuthActionType.SET_EDIT};
