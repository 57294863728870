import React from 'react';
import { useHistory } from 'react-router-dom';
import { CampaignService } from '../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import InputSelect from '../../components/InputSelect';
import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import Campaign from '../../models/Campaign';
import { ProjectType } from '../../models/Project';
import { amountIsValid, endDateIsValid, FormatDateInputValue, getStates, zipCodeIsValid } from '../../utils';
import './campaigns.scss';
import DateInput from '../../components/DateInput';

const CampaignForm: React.FC<{
    selectedCampaign: Campaign;
    handleSubmit: (updatedCampaign: Campaign) => Promise<Campaign>;
}> = ({ selectedCampaign, handleSubmit }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const [campaign, setCampaign] = React.useState(selectedCampaign);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const states = getStates();
    const history = useHistory();

    React.useEffect(() => {
        setCampaign({
            ...selectedCampaign,
            startDate: FormatDateInputValue(selectedCampaign.startDate) as string,
            endDate: FormatDateInputValue(selectedCampaign.endDate) as string,
        });
    }, []);

    const submitCampaign = () => {
        if (campaign.type === ProjectType['Day-of-Show'] || campaign.type === ProjectType['Competitive Event'])
            handleChange('endDate', campaign.startDate as string);
        const isValid =
            campaign.name.length > 0 &&
            campaign.description.length > 0 &&
            campaign.projectId.length > 0 &&
            campaign.city.length > 0 &&
            campaign.state.length > 0 &&
            (campaign.zipCode && campaign.zipCode.length > 0 ? zipCodeIsValid(campaign.zipCode) : true) &&
            campaign.country.length > 0 &&
            endDateIsValid(campaign.startDate, campaign.endDate) &&
            amountIsValid(campaign.stipend) &&
            campaign.expectedSynners.toString().length > 0;
        if (!isValid) {
            setShowError(true);
        } else {
            setLoading(true);
            handleSubmit(campaign)
                .then(() => {
                    setLoading(false);
                    history.goBack();
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Campaign ${campaign.name} was successfully updated.`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                })
                .catch((e) => {
                    setLoading(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                });
        }
    };

    const handleChange = (name: string, value: string | number) => {
        setCampaign({ ...campaign, [name]: value });
    };

    const handleOpenDeleteCampaignDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Campaign',
                description: `Any user associated with the campaign and reports submitted to the campaign will be affected. Are you sure you want to delete campaign ${campaign?.name as string}?`,
                handleConfirm: () => handleDelete(campaign?.id as string),
            },
        });
    };
    const handleDelete = (id: string) => {
        CampaignService.deleteCampaign(id).then(() => {
            history.push('/campaigns');
        });
    };

    return (
        <>
            <Typography variant="caption" textAlign="right" weight={500}>
                <span className="color-error">*</span> indicates required
            </Typography>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Campaign Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={campaign.name}
                        name="name"
                        error={showError && campaign.name.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid">
                <Typography variant="body">
                    Description <span className="color-error">*</span>
                </Typography>
                <TextArea
                    onChange={handleChange}
                    value={campaign.description}
                    name="description"
                    error={showError && campaign.description.length === 0}
                    errorText="required"
                />
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        {campaign.type === ProjectType['Day-of-Show'] ||
                        campaign.type === ProjectType['Competitive Event']
                            ? 'Event Date'
                            : 'Start Date'}{' '}
                        <span className="color-error">*</span>
                    </Typography>
                    <DateInput
                        onChange={handleChange}
                        value={campaign.startDate as string}
                        name="startDate"
                        error={showError && campaign.startDate ? (campaign.startDate as string).length == 0 : false}
                        errorText="campaign date is not valid"
                    />
                </div>
                {campaign.type !== ProjectType['Day-of-Show'] && campaign.type !== ProjectType['Competitive Event'] && (
                    <div>
                        <Typography variant="body">
                            End Date <span className="color-error">*</span>
                        </Typography>
                        <DateInput
                            onChange={handleChange}
                            value={campaign.endDate as string}
                            name="endDate"
                            error={showError && !endDateIsValid(campaign.startDate, campaign.endDate)}
                            errorText="Campaign end date is not valid"
                        />
                    </div>
                )}
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Stipend <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={campaign.stipend}
                        type="number"
                        name="stipend"
                        error={showError && !amountIsValid(campaign.stipend)}
                        errorText="Invalid stipend amount"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Positions Available <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={campaign.expectedSynners}
                        name="expectedSynners"
                        type="number"
                        error={showError && campaign.expectedSynners.toString().length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">Venue</Typography>
                    <InputField onChange={handleChange} value={campaign.venue} name="venue" />
                </div>
                <div>
                    <Typography variant="body">Street Address</Typography>
                    <InputField onChange={handleChange} value={campaign.addressLine1} name="addressLine1" />
                </div>
                <div>
                    <Typography variant="body">Apt/Unit/Suite</Typography>
                    <InputField onChange={handleChange} name="addressLine2" value={campaign.addressLine2} />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        City <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={campaign.city}
                        name="city"
                        error={showError && campaign.city.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        State <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={states}
                        value={campaign.state}
                        name="state"
                        placeholder="State"
                        onChange={handleChange}
                        error={showError && campaign.state.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">Zip Code</Typography>
                    <InputField
                        onChange={handleChange}
                        value={campaign.zipCode}
                        name="zipCode"
                        type="number"
                        error={showError && (campaign.zipCode.length > 0 ? !zipCodeIsValid(campaign.zipCode) : false)}
                        errorText="Invalid zip Code"
                    />
                </div>
            </div>
            <div className="action-container">
                {campaign.id && (
                    <Button color="error" onClick={handleOpenDeleteCampaignDialog}>
                        Delete
                    </Button>
                )}
                <Button color="primary" onClick={() => history.goBack()}>
                    Cancel
                </Button>
                <Button color="primary" onClick={submitCampaign} loading={loading}>
                    Submit
                </Button>
            </div>
        </>
    );
};

export default CampaignForm;
