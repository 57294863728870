import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Button from '../Button';
import Modal, { ModalActionBar } from '../Modal';
import { NotificationActionType, NotificationContext } from '../Notification/state';
import Typography from '../Typography';

type Props = {
    logout: () => void;
    isLoggedIn: boolean;
    interval: number; //minutes
};

const LogoutIdleTimer: React.FC<Props> = ({ logout, interval, isLoggedIn }) => {
    const [isLogoutInfoOpen, setIsLogoutInfoOpen] = React.useState(false);
    const [counter, setCounter] = React.useState(30);
    const sessionTimeOutRef: { current: NodeJS.Timeout | null } = React.useRef(null);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const onIdle = () => {
        HandleIdle();
      }

    const { reset } = useIdleTimer({
        onIdle,
        timeout: interval * 60 * 1000 - 30000
      })

    React.useEffect(() => {
        if (isLogoutInfoOpen) {
            //decrease counter if modal is open
            const timer = setInterval(() => setCounter(counter > 0 ? counter - 1 : counter), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, isLogoutInfoOpen]);

    const HandleIdle = () => {
        //open logout confirmation
        setIsLogoutInfoOpen(true);
        sessionTimeOutRef.current = setTimeout(handleLogout, 30 * 1000);
        //time out session after 30 second countdown
        setTimeout(handleLogout, interval * 60 * 1000);
    };

    const stayActive = () => {
        //prevent session close
        setIsLogoutInfoOpen(false);
        clearTimeout(sessionTimeOutRef.current as NodeJS.Timeout);
    };

    const handleLogout = () => {
        setIsLogoutInfoOpen(false);
        logout();
        reset();
        notificationDispatch({
            type: NotificationActionType.OPEN,
            payload: {
                text: 'You have been logged out due to inactivity.',
                status: 'info',
                autoClose: false,
            },
        });
    };

    return isLoggedIn ? (
        <>
            <Modal isOpen={isLogoutInfoOpen} close={stayActive} title="Session Expiry" size="small">
                <Typography variant="body">
                    Your session will expire in {counter} seconds due to inactivity. Would you like to remain logged
                    in?
                </Typography>
                <ModalActionBar>
                    <Button onClick={stayActive} color="secondary">
                        Confirm
                    </Button>
                </ModalActionBar>
            </Modal>
        </>
    ) : <></>;
};

export default LogoutIdleTimer;
