import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../AppRouter/routeConfig';

const TopNavItems: React.FC<{ items: NavItem[]; handleNavigation: () => void }> = ({ items, handleNavigation }) => {
    return (
        <nav className="top-navigation-section" role="navigation">
            <ul>
                {items.map((item, index) => (
                    <li key={index} aria-label="navigation item" role="tab">
                        <Link to={item.route} onClick={() => handleNavigation()}>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default TopNavItems;
