import React from 'react';
import TextArea from '../../../components/TextArea';
import Typography from '../../../components/Typography';
import { SurveyTextAreaData } from '../../../models/Survey';

type Props = {
    value?: string;
    data: SurveyTextAreaData;
    handleChange: (name: string, value: string) => void;
};

const SurveyTextArea: React.FC<Props> = ({ value, data, handleChange }) => {
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <TextArea
                onChange={handleChange}
                name={data.name}
                required={data.required}
                value={value}
            />
        </div>
    );
};

export default SurveyTextArea;
