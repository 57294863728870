import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../AppRouter/routeConfig';
import Typography from '../Typography';

type Props = {
    handleNavigation: () => void;
    activeItem: string;
    items: NavItem[];
    showFullContent?: boolean;
};
const LeftNavItems: React.FC<Props> = ({ handleNavigation, activeItem, items, showFullContent = false }) => {
    return (
        <div className="nav-list">
            {items.map((element: NavItem, index) => {
                const { Icon, label, route } = element;
                return (
                    <Link
                        className={`nav-item${activeItem === element.label ? ' active' : ''}`}
                        key={index}
                        to={route}
                        onClick={() => handleNavigation()}
                    >
                        {Icon && (
                            <div title ={element.label}>
                                <Icon />
                            </div>
                        )}
                        {showFullContent && <Typography variant="body">{label}</Typography>}
                    </Link>
                );
            })}
        </div>
    );
};

export default LeftNavItems;
