import React from 'react';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { ModalActionBar } from '../../components/Modal';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import { amountIsValid, getListFromEnum } from '../../utils';
import { InvoiceRequest, PaymentOption } from '../../models/Payment';
import InputSelect from '../../components/InputSelect';
import { FinancialService } from '../../api';

type Props = {
    invoice: InvoiceRequest;
    onSuccess: () => void;
};
const paymentOptions = getListFromEnum(Object.values(PaymentOption));

const UserCampaignInvoice: React.FC<Props> = ({ invoice, onSuccess }) => {
    const [_invoice, setInvoice] = React.useState(invoice);
    const [error, setError] = React.useState(false);

    const handleSubmit = () => {
        if (!amountIsValid(_invoice.amount)) {
            setError(true);
        } else {
            const req = invoice.id
                ? FinancialService.updateInvoice(_invoice)
                : FinancialService.createInvoice(_invoice);
            req.then(() => {
                onSuccess();
            });
            setError(false);
        }
    };
    return (
        <>
            <Typography variant="subtitle">Enter the invoice amount</Typography>
            <div className="invoice-input">
                <span>$</span>
                <InputField
                    name="amount"
                    type="number"
                    onChange={(_name, value) => setInvoice({ ..._invoice, amount: value })}
                    error={error}
                    value={_invoice.amount}
                    errorText="enter a valid amount"
                />
            </div>
            <InputSelect
                options={paymentOptions}
                onChange={(_name, value) => setInvoice({ ..._invoice, paymentMethod: value as PaymentOption })}
                name="methodOfPayment"
                placeholder="Payment method"
                value={_invoice.paymentMethod}
            />
            <TextArea
                name="note"
                onChange={(_name, value) => setInvoice({ ..._invoice, notes: value })}
                placeholder="Provide additional comments"
                value={_invoice.notes}
            ></TextArea>
            <ModalActionBar>
                <Button onClick={handleSubmit}>{_invoice.id ? 'Update' : 'Create'}</Button>
            </ModalActionBar>
        </>
    );
};

export default UserCampaignInvoice;
