import React from 'react';
import './apply.scss';
import Logo from '../../assets/images/logo-no-subtitle.png';
import Typography from '../../components/Typography';
import GettingStarted from './GettingStarted';
import ProgressBar from '../../components/ProgressBar';
import Education from './Education';
import OnlineActivity from './OnlineActivity';
import Other from './Other';
import MusicComedy from './MusicComedy';
import { ApplicationActionType, applicationReducer, initialState } from './state';
import {
    EducationDetails,
    MusicComedyDetails,
    OnlineActivityDetails,
    OtherDetails,
    PersonalDetails,
} from '../../models/Application';
import Review from './Review';
import Completed from './Completed';
import { Link } from 'react-router-dom';

const ApplyPage: React.FC = () => {
    
    const [state, dispatch] = React.useReducer(applicationReducer, initialState);

    const handleButtonClick = (action: string): void => {
        if (action === 'BACK') {
            dispatch({
                type: ApplicationActionType.SET_ACTIVE_STEP,
                payload: state.activeStep.index - 1,
            });
        } else {
            dispatch({
                type: ApplicationActionType.SET_ACTIVE_STEP,
                payload: state.activeStep.index + 1,
            });
        }
    };
    const handleReviewEdit = (index: number) => {
        dispatch({
            type: ApplicationActionType.SET_ACTIVE_STEP,
            payload: index,
        });
    };

    const handleComplete = () => {
        
            dispatch({
                type: ApplicationActionType.SET_COMPLETE,
            });
        
        
        
    };

    const getPercent = (): number => {
        return (Number(state.activeStep.index + 1) / Number(state.total)) * 100;
    };

    const getContentByStep = () => {
        switch (state.activeStep.index) {
            case 0:
                return (
                    <GettingStarted
                        data={state.personalDetails}
                        handleButtonClick={handleButtonClick}
                        handleSave={(data: PersonalDetails) =>
                            dispatch({
                                type: ApplicationActionType.SET_PERSONAL_DETAILS,
                                payload: data,
                            })
                        }
                    />
                );
            case 1:
                return (
                    <Education
                        data={state.educationDetails}
                        handleButtonClick={handleButtonClick}
                        handleSave={(data: EducationDetails) =>
                            dispatch({
                                type: ApplicationActionType.SET_EDUCATION_DETAILS,
                                payload: data,
                            })
                        }
                    />
                );
            case 2:
                return (
                    <OnlineActivity
                        data={state.onlineActivityDetails}
                        handleButtonClick={handleButtonClick}
                        handleSave={(data: OnlineActivityDetails) =>
                            dispatch({
                                type: ApplicationActionType.SET_ONLINE_ACTIVITY_DETAILS,
                                payload: data,
                            })
                        }
                    />
                );
            case 3:
                return (
                    <MusicComedy
                        data={state.musicComedyDetails}
                        handleButtonClick={handleButtonClick}
                        handleSave={(data: MusicComedyDetails) =>
                            dispatch({
                                type: ApplicationActionType.SET_MUSIC_COMEDY_DETAILS,
                                payload: data,
                            })
                        }
                    />
                );
            case 4:
                return (
                    <Other
                        data={state.otherDetails}
                        handleButtonClick={handleButtonClick}
                        handleSave={(data: OtherDetails) =>
                            dispatch({
                                type: ApplicationActionType.SET_OTHER_DETAILS,
                                payload: data,
                            })
                        }
                    />
                );
            case 5:
                return (
                    <Review
                        handleReviewEdit={handleReviewEdit}
                        handleComplete={handleComplete}
                        data={{
                            ...state.personalDetails,
                            ...state.educationDetails,
                            ...state.onlineActivityDetails,
                            ...state.musicComedyDetails,
                            ...state.otherDetails,
                        }}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="page apply-page">
            <section>
                <Link to="/">
                    <div className="logo-container">
                        <img src={Logo} alt="Logo" className="logo" />
                    </div>
                </Link>
                {!state.isComplete ? (
                    <>
                        <Typography variant="subtitle" color="primary" weight={600}>
                            CULTURE-CONNECTED | IDEAS IN ACTION
                        </Typography>
                        <br/>
                        <Typography variant="title" weight={600}>
                            {state.isReview
                                ? 'Please review your application'
                                : 'Please fill out the following details.'}
                        </Typography>
                        <br/>
                        {!state.isReview && <ProgressBar progress={getPercent()} variant="secondary" />}
                        <div>
                            {!state.isReview && (
                                <Typography variant="title" weight={500} color="secondary">
                                    {state.activeStep.label}
                                </Typography>
                            )}
                            {!state.isReview && (
                                <Typography variant="caption" textAlign="right" weight={500}>
                                    <span className="color-error">*</span> indicates required
                                </Typography>
                            )}
                            {getContentByStep()}
                        </div>
                    </>
                ) : (
                    <Completed />
                )}
            </section>
        </div>
    );
};

export default ApplyPage;
