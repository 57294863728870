import React from 'react';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import Toggle from '../Toggle/inedx';
import Typography from '../Typography';
import './editablepage.scss';

const EditablePage: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    const authState = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const isAuthorized = (authState.user?.role === UserRole.Admin || authState.user?.role === UserRole.Manager);

    const handleChange = () => {
        dispatch({
            type: AuthActionType.SET_EDIT,
        });
    };
    return (
        <>
            {isAuthorized && (
                <div className="editor-toggle">
                    <Typography variant="caption" color="white">
                        Editor mode
                    </Typography>
                    <Toggle name="editorView" value={authState.isEditorView} onChange={() => handleChange()} />
                </div>
            )}
            {children}
        </>
    );
};

export default EditablePage;
