import React from 'react';
import './upload.scss';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import Button from '../Button';

type Props = {
    maxSize: number;
    isMultiple?: boolean;
    loading?: boolean;
    handleUpload: (files: File[] | File) => void;
    isAvatar?:boolean;
    fileTypes?: string[]
};

const Upload: React.FC<Props> = ({ maxSize, handleUpload, isMultiple = false, loading= false, isAvatar=false, fileTypes }) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [sizeError, setSizeError] = React.useState('');
    const [typeError, setTypeError] = React.useState('');
    const fileUploadInputRef = React.useRef<HTMLInputElement>(null);

    const handleFileSelect = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files || [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fileList = Object.keys(files).map((file: any) => files[file]);
        
        let sizeErrorTxt = '';        
        let typeErrorTxt = '';
        maxSize  && fileList.map((el)=> {
            if(Math.round((el.size / 1024)) >= maxSize) {
                sizeErrorTxt = el.name + ` exceeds the ${maxSize/1024}MB size limit. \t`;
            } 
        });

        setSizeError(sizeErrorTxt);        
        fileTypes && fileList.map((el)=> {           
            const extention = el.name.split('.').pop() as string;
            if (!fileTypes.includes(extention)) {
                typeErrorTxt = el.name + ` is not of required type ${fileTypes.toString()}`;
            }
        });
        setTypeError(typeErrorTxt);
        setFiles(fileList);
    };

    const handleChooseFile = () => {
        if (fileUploadInputRef.current) {
            fileUploadInputRef.current.click();
        }
    };

    const getFileNames = (): string => {
        if (files.length > 1) {
            return `${files.length} files`;
        } else if (files.length === 1) {
            return `${files[0].name}`;
        } else {
            return 'no files selected';
        }
    };

    const removeFile = (index: number) => {
        const fileList = [...files];
        fileList.splice(index, 1);
        setFiles(fileList);
    };

    return (
        <div className="upload-container">
            <input type="hidden" value={maxSize} />
            <label>
                <div className="file-area">
                    <input
                        type="file"
                        tabIndex={-1}
                        ref={fileUploadInputRef}
                        name="upload-component"
                        multiple={isMultiple}
                        onChange={handleFileSelect}
                        className="default-input"
                    />
                    <div className="custom-input-details">
                        <input type="button" onClick={handleChooseFile} value="Choose Files" />
                        <p>{getFileNames()}</p>
                    </div>
                    {sizeError.length>0 && <p className='color-error'>{sizeError}</p>}
                    {typeError.length>0 && <p className='color-error'>{typeError}</p>}
                </div>
            </label>
            {files.map((file: File, index) => (
                <Preview file={file} key={index} removeFile={() => removeFile(index)} isAvatar={isAvatar}/>
            ))}
            <div style={{display: 'flex', justifyContent:'flex-end', marginTop: '12px'}}>
                <Button color="primary" onClick={() => handleUpload(isMultiple ? files : files[0])} disabled={files.length === 0 || sizeError.length>0 || typeError.length>0} loading={loading}>
                    Upload
                </Button>
            </div>
        </div>
    );
};

const Preview: React.FC<{ file: File; removeFile: () => void, isAvatar: boolean }> = ({ file, removeFile, isAvatar }) => {
    const [source, setSource] = React.useState<string | ArrayBuffer | null>(null);
    const [type, setType] = React.useState<'image' | 'text' | 'pdf' | 'excel' | 'other' | null>(null);
    const reader = new FileReader();

    React.useEffect(() => {
        if (file.type.match('image')) {
            setType('image');
            reader.readAsDataURL(file);
        } else if (file.type.match('text')) {
            setType('text');
            reader.readAsText(file);
        } else {
            setType('other');
        }
    }, [file]);

    reader.onload = (e) => {
        if (e.target) {
            const src = e.target.result;
            setSource(src);
        }
    };

    const getPreviewByType = () => {
        switch (type) {
            case 'text':
                return <div className="icon">
                <FileIcon />
            </div>;
            case 'image':
                return <img alt="preview" src={source as string}  className={`preview-image ${isAvatar?'avatar':''}`}/>;
            default:
                return (
                    <div className="icon">
                        <FileIcon />
                    </div>
                );
        }
    };

    return (
        <div className="preview-upload">
            {getPreviewByType()}
            <div className="preview-info">
                <div className="info-item">
                    <p>
                        <strong>Name</strong>
                    </p>
                    <p>{file.name.slice(0, 12)}{file.name.length>12?'...':''}</p>
                    <p>
                        <strong>Size</strong>
                    </p>
                    <p>{(file.size / (1024 * 1024)).toFixed(2)}MB</p>
                </div>
                <div className="preview-action">
                    <input type="button" onClick={removeFile} value="remove" />
                </div>
            </div>
        </div>
    );
};

export default Upload;
