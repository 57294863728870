import React from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';
import Typography from '../../../components/Typography';

const CampaignNameCell: React.FC<GridCellProps> = (props) => {
    return (
        <div className='clickable-grid-custom-item hoverable-link'>
            <Typography variant="body" weight={800}>
                {props.dataItem.projectName}
            </Typography>
            <Typography variant="body">{props.dataItem.name?props.dataItem.name:props.dataItem.campaignName}</Typography>
        </div>
    );
};

export default CampaignNameCell;
