import React from 'react';
import Typography from '../../../components/Typography';
import { SurveyElement, SurveyElementType } from '../../../models/Survey';
import SurveyCheckBox from '../SurveyViewer/SurveyCheckbox';
import SurveyDropdown from '../SurveyViewer/SurveyDropdown';
import SurveyInput from '../SurveyViewer/SurveyInput';
import SurveyRadio from '../SurveyViewer/SurveyRadio';
import SurveyTextArea from '../SurveyViewer/SurveyTextArea';
import SurveyUpload from '../SurveyViewer/SurveyUpload';
import SurveyWebContent from '../SurveyViewer/SurveyWebContent';
import PreviewActions from './PreviewActions';
import { surveyBuilderContext } from './state';

const Preview: React.FC = () => {
    const { survey, selectedElementIndex } = React.useContext(surveyBuilderContext).state;

    const handleChange = () => {
        return;
    };

    return survey.elements.length > 0 ? (
        <div className="survey-preview">
            <Typography variant="title">
                {survey.title}
            </Typography>
            <Typography variant="body">{survey.description}</Typography>
            <hr />
            {survey.elements.map((element: SurveyElement, index) => (
                <div key={index} className={`preview-element${index === selectedElementIndex ? ' active' : ''}`}>
                    <PreviewActions index={index} />
                    <div className="item">
                        {element.type === SurveyElementType.Checkbox && (
                            <SurveyCheckBox handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.Dropdown && (
                            <SurveyDropdown handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.FileUpload && (
                            <SurveyUpload handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.Input && (
                            <SurveyInput handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.TextArea && (
                            <SurveyTextArea handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.RadioButton && (
                            <SurveyRadio handleChange={handleChange} data={element.metaData} />
                        )}
                        {element.type === SurveyElementType.WebContent && <SurveyWebContent data={element.metaData} />}
                    </div>
                </div>
            ))}
        </div>
    ) : <></>;
};

export default Preview;
