import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as PaidIcon } from '../../assets/icons/archive.svg';
import { ReactComponent as UnpaidIcon } from '../../assets/icons/dollar-sign.svg';

type CampaignTabs = 'Unpaid Invoices' | 'Paid Invoices';

const FinancialWrapper: React.FC<{ children: React.ReactChild }> = ({ children }) => {
    const [active, setActive] = React.useState<CampaignTabs>();
    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname === '/financials') setActive('Unpaid Invoices');
        if (location.pathname === '/financials/completed') setActive('Paid Invoices');
    }, [location]);

    return (
        <div className="selection-wrapper">
            <div className="tabs">
                <Link to="/financials">
                    <div className={`selection ${active === 'Unpaid Invoices' ? 'active' : ''}`}>
                        <UnpaidIcon />
                        <span>Pending Invoices</span>
                    </div>
                </Link>
                <Link to="/financials/completed">
                    <div className={`selection ${active === 'Paid Invoices' ? 'active' : ''}`}>
                        <PaidIcon />
                        <span>Archived Invoices</span>
                    </div>
                </Link>
            </div>
            <div className="financials">{children}</div>
        </div>
    );
};
export default FinancialWrapper;
