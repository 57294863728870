import React from 'react';
import './users.scss';
import { UserService } from '../../api';
import { useHistory, useLocation } from 'react-router-dom';
import Profile from '../../models/Profile';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridRowClickEvent,
    GridToolbar,
    GridDataStateChangeEvent,
    GridRowProps,
} from '@progress/kendo-react-grid';
import { filterBy, process, State } from '@progress/kendo-data-query';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as DisableIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as EnableIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import Avatar from '../../components/Avatar';
import { formatDate, formatPhoneNumber, useWindowDimensions } from '../../utils';
import Typography from '../../components/Typography';
import { AuthContext } from '../Authentication/state';
import { UserRole } from '../../models/User';
import { DataLoadingContext } from '../../components/DataLoading';
import UserModal from './UserModal';
import UserDeletionModal from './UserDeletionModal';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import pagerSettings, { pagerMiniSettings } from '../../utils/pagerSettings';
import UserWrapper from './UserWrapper';
import { ExcelExport } from '@progress/kendo-react-excel-export';

const Representatives: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [reps, setReps] = React.useState<Array<Profile>>([]);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const [isUserModal, setUserModal] = React.useState(false);
    const { user } = React.useContext(AuthContext).state;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [selectedRepId, setSelectedRepId] = React.useState<string | null>(null);
    const [selectedRepName, setSelectedRepName] = React.useState<string | null>(null);
    const [showDisabled, setShowDisabled] = React.useState<boolean>();
    const isAdmin = user && user.role === UserRole.Admin;
    const isManager = user && user.role === UserRole.Manager;
    const history = useHistory();
    const location = useLocation();
    const { width } = useWindowDimensions();

    React.useEffect(() => {
        if(location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
        setShowDisabled(location.pathname === "/users/disabled");
        getData();
    }, [location.pathname]);

    const getData = () => {
        toggleLoading(true);
        UserService.getUsers(user ? user.role : UserRole.Synner, location.pathname === "/users/disabled")
            .then((response) => {
                setReps(response);
            })
            .finally(() => toggleLoading(false));
    };

    
    const UserAvatar = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Avatar size="small" name={props.dataItem.fname} source={props.dataItem.photoUrl} />
            </td>
        );
    };

    const FormatPhoneNumber = (props: GridCellProps) => {
        return <td>{formatPhoneNumber(props.dataItem[props.field as string], true)}</td>;
    };

    const handleRowSelection = (props: GridRowClickEvent) => {
        handleSelection(props.dataItem);
    };

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };
    
    const AlumniCell = (props: GridCellProps) => {
        if(props.dataItem.isDeleted && props.dataItem.isAlumni) {
            return <td><p className='text-center color-info'>alumni</p></td>
        }
        return <td><p className='text-center color-error'>dismissed</p></td>;
    };

    const handleSelection = (rep: Profile) => {
        history.push(`/users/${rep.id}/detail`);
    };

    const handleEdit = (rep: Profile) => {
        setSelectedRepId(rep.id);
        setUserModal(true);
    };
    const openDeleteUserDialog = (id: string, name: string) => {
        setSelectedRepId(id);
        setSelectedRepName(name);
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setSelectedRepId(null);
        setSelectedRepName(null);
        setIsDeleteDialogOpen(false);
    };

    const enableUserDialog = (id: string, name: string) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Enable User',
                description: `Are you sure you want to enable ${name}?`,
                handleConfirm: () => handleEnableUser(id),
            },
        });
    };

    const handleEnableUser = (id: string) => {
        UserService.enableUser(id).then(() => {
            getData();
        });
    };

    const sendMigrationEmail = (id: string) => {
        toggleLoading(true);
        UserService.sendMigrationEmail(id).finally(() => toggleLoading(false));
    };
    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const isDismissed = props.dataItem.isDeleted && !props.dataItem.isAlumni;
        if(isDismissed) {   
            // eslint-disable-next-line @typescript-eslint/no-explicit-any         
            const trProps: any = { className: `user--disabled` };
            return React.cloneElement(trElement, { ...trProps }, trElement.props.children  as React.ReactNode);
        }
        else {
            return trElement;
        }
    };

    const UserActions = (rep: Profile) => {
        return (
            <>
                <IconButton
                    Icon={ExternalLinkIcon}
                    onClick={() => handleSelection(rep)}
                    color="info"
                    tooltip="View User"
                />
                {!rep.isDeleted ? (
                    <>
                        {isAdmin && (
                            <IconButton
                                Icon={EditIcon}
                                onClick={() => handleEdit(rep)}
                                color="info"
                                tooltip="Edit User"
                            />
                        )}
                        {isAdmin && (
                            <IconButton
                                Icon={DisableIcon}
                                onClick={() => openDeleteUserDialog(rep.id, rep.name as string)}
                                color="error"
                                tooltip="Disable User"
                            />
                        )}
                        {isAdmin && rep.role === UserRole.OnBoarding && (
                            <IconButton
                                Icon={SendIcon}
                                onClick={() => sendMigrationEmail(rep.id)}
                                color="info"
                                tooltip="Send Migration Email"
                            />
                        )}
                    </>
                ) : (
                    <IconButton
                        Icon={EnableIcon}
                        onClick={() => enableUserDialog(rep.id, rep.name as string)}
                        color="info"
                        tooltip="Enable User"
                    />
                )}
            </>
        );
    };

    const MobileCell = (props: GridCellProps) => {
        return (
            <td className="grid-item-mobile clickable-grid-custom-item">
                <div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Name
                        </Typography>
                        <Typography variant="caption">{props.dataItem.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Email Address
                        </Typography>
                        <Typography variant="caption">{props.dataItem.emailAddress}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Total Campaigns
                        </Typography>
                        <Typography variant="caption">{props.dataItem.totalCompletedCampaigns}</Typography>
                    </div>
                </div>
                <div className="grid-actions">
                    <UserActions {...props.dataItem} />
                </div>
            </td>
        );
    };

    
    const _export = React.useRef<ExcelExport | null>(null);
    const exportExport = () => {
      if (_export.current !== null) {
        const data = dataState.filter? filterBy(reps, dataState.filter): reps
        _export.current.save(data);
      }
    };

    
    return (
        <UserWrapper>
            <>
            <div className="representatives-list">
            <ExcelExport ref={_export} fileName = "UsersExport.xlsx">
                <Grid
                    style={{ height: '100%' }}
                    sortable={true}
                    pageable={width > 1000 ? pagerSettings : pagerMiniSettings}
                    filterable={true}
                    data={process(reps, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {                        
                        history.replace({
                            ...history.location,
                            state: e.dataState
                        });
                        setDataState(e.dataState);
                    }}
                    onRowClick={handleRowSelection}
                    rowRender={rowRender}
                >
                    {isAdmin && (
                        <GridToolbar>
                            <Button onClick={() => setUserModal(true)}>Create</Button>
                            <Button onClick = {exportExport}>Export</Button>
                        </GridToolbar>
                        
                    )}
                    <GridColumn width="80px" sortable={false} cell={UserAvatar} field="profileUrl" title=" " filterable={false} />
                    {width > 1000 && <GridColumn field="name" title="Name" />}
                    {width > 1000 && <GridColumn field="emailAddress" title="Email Address" width="190px"/>}
                    {width > 1000 && <GridColumn cell={FormatPhoneNumber} field="phoneNumber" title="Phone Number" />}
                    {width > 1000 && isAdmin && <GridColumn field="role" title="Role" filterable={true} width={160}/>}
                    
                    {width > 1000 && (isAdmin || isManager) && (
                        <GridColumn
                            field="city"
                            title="City"
                            filterable={true}
                        />
                    )}
                    {width > 1000 && (isAdmin || isManager) && (
                        <GridColumn
                            field="state"
                            title="State"
                            filterable={true}
                            width="100px"
                        />
                    )}
                    {width > 1000 && isAdmin && showDisabled && (
                        <GridColumn field="isAlumni" title="Status" filterable={false} cell={AlumniCell}/>
                    )}
                    {width > 1000 && (
                        <GridColumn
                            field="totalCompletedCampaigns"
                            title="Total Campaigns"
                            width="70px"
                            filterable={false}
                        />
                    )}
                    {width > 1000 && !showDisabled && (
                        <GridColumn
                            field="dateRegistered"
                            title="Member Since"
                            cell={DateCell}
                            filterable={false}
                            width="130px"
                        />
                    )}
                    {width > 1000 && (
                        <GridColumn
                            cell={(props: GridCellProps) => (
                                <td className="grid-actions">
                                    <UserActions {...props.dataItem} />
                                </td>
                            )}
                            filterable={false}
                            sortable={false}
                            width={showDisabled?140:160}
                        />
                    )}
                    {width <= 1000 && <GridColumn cell={MobileCell} field="name" title="search by name" />}
                </Grid>
                </ExcelExport>
            </div>
            <UserModal
                isOpen={isUserModal}
                close={() => {
                    setUserModal(false);
                    setSelectedRepId(null);
                }}
                userId={selectedRepId}
                getData={getData}
            />
            <UserDeletionModal
                isOpen={isDeleteDialogOpen}
                close={closeDeleteDialog}
                id={selectedRepId}
                name={selectedRepName}
                getData={getData}
            />
            </>
        </UserWrapper>
    );
};

export default Representatives;
