import instance from './instance';

import Project, { ProjectManager, ProjectStatus } from '../models/Project';
import Campaign from '../models/Campaign';
import { UploadFileType } from '../models/Upload';

function getProjects(includeArchived = false): Promise<Project[]> {
    return instance.get(`projects?includeArchived=${includeArchived}`).then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.data.forEach((project:any)=>{
            project.createdAt = new Date(project.createdAt);
        })
        return response.data;
    });
}

function getRecentProjects(): Promise<Project[]> {
    return instance.get('recent-projects').then((response) => {
        return response.data;
    });
}

function getProject(id: string): Promise<Project> {
    return instance.get(`project/${id}`).then((response) => {
        return response.data;
    });
}

function deleteProject(id: string): Promise<boolean> {
    return instance.delete(`project/${id}`).then((response) => {
        return response.data;
    });
}

function createProject(project: Project): Promise<Project> {
    return instance.post('project', project).then((response) => {
        return response.data;
    });
}

function updateProject(project: Project): Promise<Project> {
    return instance.put('project', project).then((response) => {
        return response.data;
    });
}

function getManagers(): Promise<ProjectManager[]> {
    return instance.get(`managers`).then((response) => {
        return response.data;
    });
}

function getProjectCampaigns(id: string): Promise<Campaign[]> {
    return instance.get(`/project/campaigns?projectId=${id}`).then((response) => {
        return response.data;
    });
}

function uploadProjectImage(id: string, file: File): Promise<string> {
    const formData = new FormData();
    formData.append('projectId', id);
    formData.append('type', UploadFileType.ProjectPicture.toString());
    formData.append('file', file);
    return instance.post(`/upload`, formData).then((response) => {
        return response.data;
    });
}

function updateProjectStatus(projectId:string, status:ProjectStatus): Promise<boolean> {
    return instance.put(`project/update-status`, {id:projectId, status: status}).then((response)=>{
        return response.data;
    })
}

export {
    getProjects,
    getRecentProjects,
    deleteProject,
    getManagers,
    createProject,
    updateProject,
    getProject,
    getProjectCampaigns,
    uploadProjectImage,
    updateProjectStatus
};
