import React from 'react';
import Typography from '../../../components/Typography';
import {ReactComponent as InfoIcon} from '../../../assets/icons/info.svg';

const NoSelection:React.FC=()=> {
    return <div className='detail'>
        <Typography variant="subtitle" textAlign='center'>            
            <InfoIcon className='color-info'/>
            <br/>
            Select an item to make updates 
        </Typography>
    </div>
}

export default NoSelection;