import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProjectService } from '../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import InputSelect from '../../components/InputSelect';
import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import Socials from '../../components/Socials';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import Project, { ProjectManager, ProjectType } from '../../models/Project';
import SocialMedia from '../../models/SocialMedia';
import { getListFromEnum, urlIsValid } from '../../utils';
import ProjectUpload from './ProjectDetail/ProjectUpload';

const ProjectForm: React.FC<{
    selectedProject: Project;
    handleSubmit: (updatedProject: Project) => Promise<Project>;
}> = ({ selectedProject, handleSubmit }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const [project, setProject] = React.useState(selectedProject);
    const [managers, setManagers] = React.useState<{ label: string; value: string }[]>([]);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const ProjectTypeList = getListFromEnum(Object.values(ProjectType));

    React.useEffect(() => {
        ProjectService.getManagers().then((response) => {
            const temp: { label: string; value: string }[] = [];
            response.forEach((el: ProjectManager) => {
                temp.push({
                    label: `${el.fname} ${el.lname}`,
                    value: el.id,
                });
            });
            setManagers(temp);
        });
    }, []);

    const submitProject = () => {
        const isValid =
            project.name.length > 0 &&
            project.managerId.length > 0 &&
            project.description.length > 0 &&
            project.clientName.length > 0 &&
            (project.type as number) !== -1 && 
            (project.website && project.website.length > 0 ? urlIsValid(project.website) : true);
        if (!isValid) {
            setShowError(true);
        } else {
            setLoading(true);
            const temp: Project = project;
            temp.status = parseInt(temp.status.toString());
            temp.type = parseInt(temp.type.toString());
            handleSubmit(temp)
                .then(() => {
                    setLoading(false);
                    history.goBack();
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Project ${project.name} was successfully updated.`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                })
                .catch((e) => {
                    setLoading(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                });
        }
    };

    const handleChange = (name: string, value: string | number) => {
        setProject({ ...project, [name]: value });
    };

    const handleSocialChange = (values: SocialMedia[]) => {
        setProject({ ...project, socialMedias: values });
    };

    const handleUpload = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            ProjectService.uploadProjectImage(project.id as string, file)
                .then((response) => {
                    setProject({ ...project, photoUrl: response });
                    resolve(response);
                })
                .catch((error) => reject(error.message));
        });
    };

    const openDeleteProjectDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Project',
                description: `Campaigns associated with this project will be affected. Are you sure you want to delete project ${project?.name as string}?`,
                handleConfirm: () => handleDeleteProject(project?.id as string),
            },
        });
    };

    const handleDeleteProject = (id: string) => {
        ProjectService.deleteProject(id).then(() => {
            history.push('/projects');
        });
    };

    return (
        <>
            <Typography variant="caption" textAlign="right" weight={500}>
                <span className="color-error">*</span> indicates required
            </Typography>
            <div className="form-grid-half">
                {project.id && <ProjectUpload source={project.photoUrl} onUpload={handleUpload} />}
                <div>
                    <div>
                        <Typography variant="body">
                            Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={project.name}
                            name="name"
                            error={showError && project.name.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Project type <span className="color-error">*</span>
                        </Typography>
                        <InputSelect
                            onChange={handleChange}
                            value={project.type}
                            name="type"
                            options={ProjectTypeList}
                            placeholder="type"
                            error = {showError && (project.type as number) === -1}
                            errorText="please select a project type"
                        />
                    </div>
                </div>
            </div>
            <div className="form-grid-third">
                {managers.length > 0 && (
                    <div>
                        <Typography variant="body">
                            Project Manager <span className="color-error">*</span>
                        </Typography>
                        <InputSelect
                            onChange={handleChange}
                            value={project.managerId}
                            name="managerId"
                            options={managers}
                            placeholder="manager"
                            error={showError && project.managerId.length === 0}
                            errorText="required"
                        />
                    </div>
                )}
                <div>
                    <Typography variant="body">Website</Typography>
                    <InputField
                        onChange={handleChange}
                        value={project.website}
                        name="website"
                        error={project.website && project.website.length > 0 ? !urlIsValid(project.website) : false}
                        errorText="Please provide a valid url. eg. https://www.google.com"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Client Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={project.clientName}
                        name="clientName"
                        error={showError && project.clientName.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div>
                <Typography variant="body">Social Media</Typography>
                <Socials socials={project.socialMedias} onChange={handleSocialChange} showFollowers={false} />
            </div>
            <div>
                <Typography variant="body">
                    Description <span className="color-error">*</span>
                </Typography>
                <TextArea
                    onChange={handleChange}
                    value={project.description}
                    name="description"
                    error={showError && project.description.length === 0}
                    errorText="required"
                />
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">Notes</Typography>
                    <TextArea onChange={handleChange} value={project.staffNote} name="staffNote" />
                </div>
                <div>
                    <Typography variant="body">Comments and updates</Typography>
                    <TextArea onChange={handleChange} value={project.misc} name="misc" />
                </div>
            </div>
            <div className="action-container">
                {project.id && (
                    <Button color="error" onClick={openDeleteProjectDialog}>
                        Delete
                    </Button>
                )}
                <Button color="primary" onClick={() => history.goBack()}>
                    Cancel
                </Button>
                <Button color="primary" onClick={submitProject} loading={loading}>
                    Submit
                </Button>
            </div>
        </>
    );
};

export default ProjectForm;
