export default interface User {
    id: string;
    fname: string;
    lname: string;    
    role: UserRole;
    accessToken: string;
    emailAddress: string;
    phoneNumber:string;
    photoUrl:string;
}

export enum UserRole {
    Admin = "Admin",
    Visitor = "Visitor",
    Trainee = "Trainee",
    OnBoarding = "OnBoarding",
    Synner = "Synner",
    Manager = "Manager",
}
