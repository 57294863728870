import {
    getSelectedState,
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridRowProps,
    GridSelectionChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import React from 'react';
import { FinancialService } from '../../api';
import PaymentInvoice, { PaymentOption } from '../../models/Payment';
import { formatDate, formatDateOnly, useWindowDimensions } from '../../utils';
import pagerSettings, { pagerMiniSettings } from '../../utils/pagerSettings';
import { getter, process, State } from '@progress/kendo-data-query';
import './financials.scss';
import { ReactComponent as CompleteIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Typography from '../../components/Typography';
import IconButton from '../../components/IconButton';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import { ProjectType } from '../../models/Project';
import FinancialWrapper from './FinancialWrapper';
import UserCampaignInvoice from './UserCampaignInvoice';
import Modal from '../../components/Modal';
import InvoiceDetail from './InvoiceDetail';

const DATA_ITEM_KEY = 'id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const Financials: React.FC = () => {
    const [data, setData] = React.useState<PaymentInvoice[]>([]);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { width } = useWindowDimensions();
    const [hasSelection, setHasSelection] = React.useState(false);
    const _export = React.useRef<ExcelExport | null>(null);
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const [selected, setSelected] = React.useState<PaymentInvoice>();
    const [modal, setModal] = React.useState<'NONE' | 'EDIT' | 'DETAIL'>('NONE');
    const location = useLocation();
    const [isArchived, setIsArchived] = React.useState(location.pathname === '/financials/completed');

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        setIsArchived(location.pathname === '/financials/completed');
    }, [location]);

    React.useEffect(() => {
        setHasSelection(false);
    }, [isArchived]);

    React.useEffect(() => {
        let temp = false;
        for (const property in selectedState) {
            if (selectedState[property]) {
                temp = true;
                break;
            }
        }
        setHasSelection(temp);
    }, [selectedState]);

    const getData = () => {
        FinancialService.getFinancials(isArchived).then((response) => setData(response));
    };

    const completePayment = (id: string) => {
        FinancialService.completeInvoices([id]).then(() => {
            getData();
        });
    };

    const handleDeleteConfirmation = (id: string) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Invoice',
                description: 'Are you sure you want to delete this invoice?',
                handleConfirm: () => deletePayment(id),
            },
        });
    };

    const deletePayment = (id: string) => {
        FinancialService.deleteInvoice(id).then(() => {
            getData();
        });
    };

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkboxElement: any = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState: {
            [id: string]: boolean | number[];
        } = {};

        event.dataItems.forEach((item: unknown) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/prop-types
        const trProps: any = { className: `payment--${props.dataItem['isCompleted'] ? 'complete' : 'processing'}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    const DateCell = (props: GridCellProps) => {
        return (
            <td>{props.dataItem[props.field as string] ? formatDate(props.dataItem[props.field as string]) : ''}</td>
        );
    };

    const AmountCell = (props: GridCellProps) => {
        return <td>${Number.parseFloat(props.dataItem[props.field as string]).toFixed(2)}</td>;
    };

    const FinancialActions = (props: GridCellProps) => {
        return (
            <>
                {!props.dataItem['isCompleted'] && (
                    <IconButton
                        Icon={CompleteIcon}
                        color="success"
                        tooltip="Mark as completed"
                        onClick={() => completePayment(props.dataItem['id'])}
                    />
                )}
                {!props.dataItem['isCompleted'] && (
                    <>
                        <IconButton
                            Icon={DeleteIcon}
                            color="error"
                            tooltip="Delete Invoice"
                            onClick={() => handleDeleteConfirmation(props.dataItem['id'])}
                        />
                        <IconButton
                            Icon={EditIcon}
                            color="info"
                            tooltip="Edit Invoice"
                            onClick={() => {
                                setSelected(props.dataItem);
                                setModal('EDIT');
                            }}
                        />
                    </>
                )}
                <IconButton
                    Icon={ExternalLinkIcon}
                    color="info"
                    tooltip="View Details"
                    onClick={() => {
                        setSelected(props.dataItem);
                        setModal('DETAIL');
                    }}
                />
            </>
        );
    };
    const addDescriptionToData = () => {
        const dataWithDescription: PaymentInvoice[] = data.map((e: PaymentInvoice) => {
            return {
                ...e,
                description: `${ProjectType[e.type]} | \n Project: ${e.projectName} \n | Campaign: ${
                    e.campaignName
                } \n | Start Date: ${formatDateOnly(e.startDate)} \n | End Date: ${formatDateOnly(e.endDate)}`,
            };
        });

        const newData = dataWithDescription.map((e: PaymentInvoice) => {
            return { ...e, amount: `$${e.amount}` };
        });

        return newData;
    };

    const exportToExcel = () => {
        if (_export.current !== null) {
            const newData = addDescriptionToData();
            _export.current.save(process(newData, dataState));
        }
    };

    const handleMultipleComplete = () => {
        const temp: string[] = [];
        for (const property in selectedState) {
            if (selectedState[property]) {
                temp.push(property);
            }
        }
        FinancialService.completeInvoices(temp).then(() => {
            getData();
        });
    };

    return (
        <FinancialWrapper>
            <>
                <ExcelExport data={data} ref={_export}>
                    <ExcelExportColumn field="vendor" title="Vendor Name" width={200} />
                    <ExcelExportColumn field="client" title="Client Name" width={200} />
                    <ExcelExportColumn field="amount" title="Amount" width={100} />
                    <ExcelExportColumn field="methodOfPayment" title="Payment Method" width={100} />
                    <ExcelExportColumn field="dateCreated" title="Date Accepted" width={100} />
                    {isArchived && (
                        <>
                            <ExcelExportColumn field="dateCompleted" title="Date Paid" width={100} />
                        </>
                    )}
                    <ExcelExportColumn field="description" title="Description" />
                </ExcelExport>
                <Grid
                    style={{ height: '100%' }}
                    sortable={true}
                    pageable={width > 1000 ? pagerSettings : pagerMiniSettings}
                    filterable={true}
                    data={process(
                        data.map((item) => ({
                            ...item,
                            ...(!isArchived ? { [SELECTED_FIELD]: selectedState[idGetter(item)] } : {}),
                        })),
                        dataState,
                    )}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: !isArchived,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    rowRender={rowRender}
                >
                    <GridToolbar>
                        {hasSelection && <Button onClick={handleMultipleComplete}>Mark as completed</Button>}
                        <Button onClick={exportToExcel}>Export</Button>
                    </GridToolbar>
                    {width <= 1000 && (
                        <GridColumn
                            field="vendor"
                            title="Search by Vendor"
                            cell={(props: GridCellProps) => (
                                <td className="grid-item-mobile clickable-grid-custom-item">
                                    <div>
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                Vendor
                                            </Typography>
                                            <Typography variant="caption" weight={500}>
                                                {props.dataItem.vendor}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                Client
                                            </Typography>
                                            <Typography variant="caption">{props.dataItem.client}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                Amount
                                            </Typography>
                                            <Typography variant="caption">
                                                ${Number.parseFloat(props.dataItem.amount).toFixed(2)}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                Payment Method
                                            </Typography>
                                            <Typography variant="caption">{props.dataItem.methodOfPayment}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                Date Created
                                            </Typography>
                                            <Typography variant="caption">
                                                {formatDate(props.dataItem.dateCreated)}
                                            </Typography>
                                        </div>
                                        {props.dataItem.dateCompleted && (
                                            <div>
                                                <Typography variant="body" weight={500}>
                                                    Date Completed
                                                </Typography>
                                                <Typography variant="caption">
                                                    {formatDate(props.dataItem.dateCompleted)}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid-actions">
                                        <FinancialActions {...props} />
                                    </div>
                                </td>
                            )}
                        />
                    )}
                    {width > 1000 && !isArchived && (
                        <GridColumn
                            field={SELECTED_FIELD}
                            filterable={false}
                            sortable={false}
                            headerSelectionValue={data.findIndex((item) => !selectedState[idGetter(item)]) === -1}
                            width="54px"
                        />
                    )}
                    {width > 1000 && <GridColumn title="Vendor" field="vendor" />}
                    {width > 1000 && <GridColumn title="Client" field="client" />}
                    {width > 1000 && <GridColumn field="amount" title="Amount" cell={AmountCell} />}
                    {width > 1000 && <GridColumn field="methodOfPayment" title="Method" />}
                    {width > 1000 && (
                        <GridColumn
                            cell={DateCell}
                            field="dateCreated"
                            title="Date Created"
                            filterable={false}
                            sortable={true}
                            filter="date" format="{0:s}"
                        />
                    )}
                    {width > 1000 && isArchived && (
                        <GridColumn
                            cell={DateCell}
                            field="dateCompleted"
                            title="Date Paid"
                            filterable={false}
                            sortable={true}
                            filter="date" format="{0:s}"
                        />
                    )}
                    {width > 1000 && (
                        <GridColumn
                            filterable={false}
                            sortable={false}
                            width="285px"
                            cell={(props) => (
                                <td className="grid-actions">
                                    <FinancialActions {...props} />
                                </td>
                            )}
                        />
                    )}
                </Grid>
                {selected && (
                    <Modal
                        isOpen={selected !== undefined && modal === 'EDIT'}
                        close={() => {
                            setSelected(undefined);
                            setModal('NONE');
                        }}
                        size="medium"
                        title="Update Invoice"
                    >
                        <UserCampaignInvoice
                            invoice={{
                                id: selected.id,
                                synnerId: selected.vendorId,
                                amount: selected.amount.toString(),
                                notes: selected.notes,
                                campaignId: selected.campaignId,
                                paymentMethod:
                                    selected.methodOfPayment === 'CHECK' ? PaymentOption.CHECK : PaymentOption.PAYPAL,
                            }}
                            onSuccess={() => {
                                setSelected(undefined);
                                setModal('NONE');
                                getData();
                            }}
                        />
                    </Modal>
                )}
                {selected && (
                    <Modal
                        isOpen={selected !== undefined && modal === 'DETAIL'}
                        close={() => {
                            setSelected(undefined);
                            setModal('NONE');
                        }}
                        size="medium"
                        title="Invoice Detail"
                    >
                        <InvoiceDetail invoice={selected} />
                    </Modal>
                )}
            </>
        </FinancialWrapper>
    );
};

export default Financials;
