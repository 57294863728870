import React from 'react';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { OnlineActivityDetails} from '../../models/Application';
import InputSelect from '../../components/InputSelect';
import SocialMedia  from '../../models/SocialMedia';
import Socials from '../../components/Socials';

type Props = {
    data: OnlineActivityDetails;
    handleSave: (data: OnlineActivityDetails) => void;
    handleButtonClick: (action: string) => void;
};

const OnlineActivity: React.FC<Props> = ({ data, handleSave, handleButtonClick }) => {
    const [onlineActivityDetails, setOnlineActivityDetails] = React.useState(data);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setOnlineActivityDetails(data);
    }, [data]);

    const handleChange = (name: string, value: string | Array<string> | number) => {
        setOnlineActivityDetails({ ...onlineActivityDetails, [name]: value });
    };

    const handleSubmit = (): void => {
        const isValid = onlineActivityDetails.emailFrequency.length > 0;
        if (!isValid) {
            setShowError(true);
        } else {
            handleSave(onlineActivityDetails);
            handleButtonClick('NEXT');
        }
    };

    const handleBack = () => {
        handleButtonClick('BACK');
    };

    const handleSocialChange=(value: SocialMedia[])=> {
        setOnlineActivityDetails({ ...onlineActivityDetails, socialMedias: value });
    }

    const frequency = [
        {
            label: 'multiple times a day',
            value: 'multiple times a day',
        },
        {
            label: 'once a day',
            value: 'once a day',
        },
        {
            label: 'multiple times a week',
            value: 'multiple times a week',
        },
        {
            label: 'once a week',
            value: 'once a week',
        },
        {
            label: 'never',
            value: 'never',
        },
    ];

    return (
        <>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        How often do you check your email? <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={frequency}
                        value={onlineActivityDetails.emailFrequency}
                        name="emailFrequency"
                        placeholder="frequency"
                        onChange={handleChange}
                        error={showError && onlineActivityDetails.emailFrequency.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <Typography variant="body">What social media platforms do you actively use?</Typography>            
            <Socials socials={onlineActivityDetails.socialMedias} onChange={handleSocialChange}/>
            <div className="action-container">
                <Button color="primary" onClick={handleBack}>
                    Back
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default OnlineActivity;
