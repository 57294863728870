import CalendarEvent from '../models/CalendarEvent';
import instance from './instance';

function getCalendarEvents(): Promise<CalendarEvent[]> {
    return instance.get(`events`).then((response) => {
        return response.data;
    });
}

function getMyCalendarEvents(): Promise<CalendarEvent[]> {
    return instance.get(`user-events`).then((response)=> {
        return response.data;
    })
}

export { getCalendarEvents, getMyCalendarEvents };
