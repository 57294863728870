import React from 'react';
import { ReportService } from '../../../api';
import Typography from '../../../components/Typography';
import { UserReportStatus, UserSubmission } from '../../../models/Report';
import { getColorByReportStatus } from '../../../utils/getColorByStatus';
import { AuthContext } from '../../Authentication/state';
import { ReactComponent as LinkIcon } from '../../../assets/icons/external-link.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import './usersubmissions.scss';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils';
import { UserRole } from '../../../models/User';

type Props = {
    campaignId: string;
    synnerId?: string;
};

const UserSubmissions: React.FC<Props> = ({ campaignId, synnerId }) => {
    const { user } = React.useContext(AuthContext).state;
    const userId = synnerId ? synnerId : user?.id;
    const [submissions, setSubmissions] = React.useState<UserSubmission[]>([]);
    const [noData, setNoData] = React.useState(false);
    const canCreate = user && user.role === UserRole.Synner;

    React.useEffect(() => {
        setNoData(false);
        getData();
    }, [campaignId, synnerId]);

    const getData = () => {
        ReportService.GetUserSubmissionsForCampaign( userId as string, campaignId).then((response) => {
            setNoData(response.length === 0);
            setSubmissions(response);
        });
    };

    return (
        <div
            className="campaign-report"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {submissions.map((submission, index) => (
                <div className="survey-template" key={index}>
                    <Typography variant="body" weight={500}>
                        {submission.surveyName}
                    </Typography>
                    <Typography variant="caption">
                        <strong>Due Date: </strong>
                        {formatDate(submission.dueDate)}
                    </Typography>
                    <Typography variant="caption">
                        <i>*{submission.totalSubmissions} submission(s) required</i>
                    </Typography>
                    <div className="submissions">
                        {submission.reports.map((el, index) => (
                            <Link to={`/reports/${el.reportId}`} key={index}>
                                <div className="submission">
                                    <div>
                                        <Typography variant="caption" color="info">
                                            Open report
                                        </Typography>
                                        <Typography variant="caption">
                                            {formatDate(el.dateUpdated as string)}
                                            <br />
                                            <span
                                                className={`color-${getColorByReportStatus(
                                                    el.status as UserReportStatus,
                                                )}`}
                                            >
                                                &#11044;
                                            </span>
                                            &nbsp;{UserReportStatus[el.status as UserReportStatus]}
                                        </Typography>
                                    </div>
                                    <LinkIcon />
                                </div>
                            </Link>
                        ))}
                        {!submission.surveyCompleted && (
                            <>
                                {canCreate ? (
                                    <Link
                                        to={{
                                            pathname: '/report/create-report',
                                            state: { campaignId: campaignId, surveyId: submission.surveyId },
                                        }}
                                    >
                                        <div className="submission">
                                            <Typography variant="caption" color="info">
                                                Create report
                                            </Typography>
                                            <PlusIcon />
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="submission">
                                        <Typography variant="caption" color="error">
                                            Report needs submission
                                        </Typography>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}
            {noData && (
                <Typography variant="body" color="info">
                    No reports are associated with this campaign.
                </Typography>
            )}
        </div>
    );
};

export default UserSubmissions;
