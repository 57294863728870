import React from 'react';
import './register.scss';
import Logo from '../../assets/images/logo-no-subtitle.png';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { useHistory, useParams } from 'react-router-dom';
import { passwordAreEqual, passwordIsValid } from '../../utils';
import { AuthService } from '../../api';

const initRegister = {
    email: '',
    password: '',
    confirmPassword: '',
};
const Register: React.FC = () => {
    const [isSuccess, setIsSuccess] = React.useState(false);
    const { token, email } = useParams<{ token: string; email: string }>();
    const [register, setRegister] = React.useState({ ...initRegister, email: decodeURIComponent(email) });
    const [error, setError] = React.useState('');    
    const [isLoading, setIsLoading]= React.useState(false);

    const history = useHistory();

    React.useEffect(()=> {
        checkTokenValidity();
    }, [token]);

    const handleChange = (name: string, value: string) => {
        setRegister({ ...register, [name]: value });
    };

    const checkTokenValidity =()=> {
        AuthService.checkTokenValidity(token, email).then((respose)=>{
            if(!respose) {
                handleRedirectToForgot();
            }
        }).catch(()=>setError('There was an error validating the token. Please try again later.'));
    }
    
    const handleRegister = () => {
        setIsLoading(true);
        AuthService.resetPassword(token, register.email, register.password)
            .then(() => {
                setIsSuccess(true);
                setError('');
                setIsLoading(false);
                handleRedirectToLogin();
            })
            .catch((e) => {
                setError(e.message);
                setIsLoading(false);
            });
    };

    const handleRedirectToLogin = () => {
        setTimeout(()=>history.push('/'), 5000);
    }

    const handleRedirectToForgot = () => {
        history.push(`/register/${token}/${email}`);
    }

    return (
        <div className="page register-page">
            <section className="paper">
                <div className="content">
                    <div className="grid-item logo">
                        <div className="logo-container">
                            <img src={Logo} alt="Logo" className="logo" />
                        </div>
                        <Typography variant="title" weight={500} color="secondary">
                            Welcome to Synnernation
                        </Typography>
                        <Typography variant="body">
                            Please create a password for your account.
                        </Typography>
                        <div className='password-req'>
                            <Typography variant='caption'>Your password must be at least 8 characters in length and must contain one lowercase, one uppercase letter, one number and one special character.</Typography>
                        </div>
                    </div>
                    <div className="grid-item">
                        {!isSuccess ? (
                            <>                                
                                {error.length > 0 && (
                                    <Typography variant="body" color="error">
                                        {error}
                                    </Typography>
                                )}
                                <br/>
                                <Typography variant = "body"> Email</Typography>
                                <InputField
                                    onChange={handleChange}
                                    name="email"
                                    required={true}
                                    value={register.email}
                                    disabled={true}
                                />
                                <Typography variant = "body">Password</Typography>
                                <InputField
                                    onChange={handleChange}
                                    name="password"
                                    type="password"
                                    required={true}
                                    value={register.password}
                                    error={register.password.length > 0 && !passwordIsValid(register.password)}
                                />
                                <Typography variant = "body"> Confirm Password</Typography>                               
                                <InputField
                                    onChange={handleChange}
                                    name="confirmPassword"
                                    type="password"
                                    required={true}
                                    value={register.confirmPassword}
                                    error={
                                        register.confirmPassword.length > 0 &&
                                        !passwordAreEqual(register.password, register.confirmPassword)
                                    }
                                />
                                <Button
                                    color="primary"
                                    onClick={handleRegister}
                                    disabled={
                                        !passwordIsValid(register.password) ||
                                        !passwordAreEqual(register.password, register.confirmPassword)
                                    }
                                    loading={isLoading}
                                >
                                    CREATE PASSWORD
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="title" color="secondary" textAlign="center">
                                 Thank you for joining Synnernation
                                </Typography>
                                <Typography variant="body" textAlign="center">
                                    You will be redirected to the login page shortly. Hang tight in the meantime!!
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Register;
