import React from 'react';
import Typography from '../../components/Typography';
import Project, { ProjectStatus } from '../../models/Project';
import ProjectForm from './ProjectForm';
import { ProjectService } from '../../api';

const initProject: Project = {
    managerId: '',
    status: ProjectStatus.Active,
    type: -1 as number,
    name: '',
    clientName: '',
    description: '',
    staffNote: '',
    socialMedias: [],
    misc: '',
};

const CreateProject: React.FC = () => {

    const handleSubmit = (newProject: Project):Promise<Project> => {
        return ProjectService.createProject(newProject);
    };

    return (
        <div className="projects">
            <Typography variant="title" textAlign='center'>CREATE NEW PROJECT</Typography>
            <hr/>
            <br/>
            <ProjectForm selectedProject={initProject} handleSubmit={handleSubmit}/>
        </div>
    );
};
export default CreateProject;
