import React, {useRef} from 'react';
import './input.scss';

type Props = {
    onChange: (name:string, value: string) => void;
    onBlur?: () => void;
    type?: React.HTMLInputTypeAttribute;
    disabled?: boolean;
    name: string;
    outlineLabel?: string;
    className?: string;
    id?: string | undefined;
    error?: boolean;
    errorText?:string;
    required?:boolean;    
    variant?: string;
    placeholder?: string;
    value?: string | number;
};

const InputField: React.FC<Props> = ({ onChange, onBlur, type="text", disabled=false, name, id, outlineLabel=" ", className, error, errorText, required, variant="standard", placeholder = " ", value }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange=(e:React.ChangeEvent<HTMLInputElement>): void=> {
        onChange(e.target.name, e.target.value)
    }
    return (
        <div className="input-field-container">
            <input type={type} className={`input-field ${className? className: ''} ${error? 'error': ''}`} placeholder={variant!=="outline" ? placeholder : " "} onBlur={onBlur} onChange={handleChange} disabled={disabled} name={name} id={id} required={required} value={value} ref={inputRef} onWheel={()=>{inputRef?.current?.blur()}}/>
            {/* {variant === "outline" && <label htmlFor={name} className={`input-label-outline${(value && value.toString().length>0) ?" focused": ''}`} onClick={()=>inputRef.current && inputRef.current.focus()}>{required && '*'}{outlineLabel}</label>} */}
            {variant === "outline" && <label htmlFor={name} className="input-label-outline" onClick={()=>inputRef.current && inputRef.current.focus()}>{required && '*'}{outlineLabel}</label>}
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default InputField;
