import React from 'react';
import { SurveyElementType } from '../../../models/Survey';
import { ReactComponent as CheckBoxIcon } from '../../../assets/icons/check-square.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-cloud.svg';
import { ReactComponent as HTMLIcon } from '../../../assets/icons/code.svg';

type Props = {
    type: SurveyElementType;
};
const ToolIcon: React.FC<Props> = ({ type }) => {
    switch (type) {
        case SurveyElementType.Checkbox:
            return <CheckBoxIcon />;
        case SurveyElementType.Dropdown:
            return <ListIcon />;
        case SurveyElementType.FileUpload:
            return <UploadIcon />;
        case SurveyElementType.Input:
            return <span>&#9000;</span>;
        case SurveyElementType.TextArea:
            return <span>P</span>;
        case SurveyElementType.RadioButton:
            return <span>&#x274D;</span>;
        case SurveyElementType.WebContent:
            return <HTMLIcon />;
        default:
            return <></>;
    }
};

export default ToolIcon;
