import React from 'react';
import { surveyBuilderContext } from './state';
import { useHistory } from 'react-router-dom';
import { SurveyService } from '../../../api';
import Button from '../../../components/Button';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/AlertDialog/state';
import ErrorInfo from '../../../components/ErrorInfo';
import LoaderGif from '../../../assets/images/loader.gif';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-circle.svg';

const SurveyActions: React.FC = () => {
    const [error, setError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(true);
    const { survey } = React.useContext(surveyBuilderContext).state;

    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const history = useHistory();

    React.useEffect(()=> {
        setIsSuccess(false);
    }, [survey])

    const handleSubmit = () => {
        const request = survey.id ? SurveyService.updateSurvey(survey) : SurveyService.createSurvey(survey);
        setIsLoading(true);
        request
            .then(() => {
                setIsLoading(false);
                setIsSuccess(true);
                setError('');
                if (!survey.id) {
                    history.push('/surveys');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    const handleDeleteDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Survey',
                description: `Are you sure you want to delete survey ${survey.title}? Users won't be able to view reports that were created using this survey.`,
                handleConfirm: () => handleDeleteSurvey(survey.id as string),
            },
        });
    };

    const handleDeleteSurvey = (id: string) => {
        setIsLoading(true);
        SurveyService.deleteSurvey(id)
            .then(() => {
                setIsLoading(false);
                setError('');
                history.push('/surveys');
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    return survey.elements.length > 0 && survey.title.length > 0 ? (
        <div className="survey-action-container">
            {error.length > 0 && (
                <>
                    <ErrorInfo error={error} onClose={() => setError('')} />
                    <br />
                </>
            )}
            <div className='survey-actions'>
                {survey.id && (
                    <Button onClick={handleDeleteDialog} color="error">
                        Delete Template
                    </Button>
                )}
                <Button onClick={handleSubmit} color="secondary">
                    Save Template
                </Button>
            </div> 
            <div className="content-loading">
                {isLoading &&<img src={LoaderGif} alt="loader" />}
                {isSuccess &&<CheckIcon />}
            </div>
        </div>
    ) : null;
};

export default SurveyActions;
