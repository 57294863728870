import { process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridFilterCellProps,
    GridToolbar,
} from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ActivityService } from '../../../api';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/Button';
import { DataLoadingContext } from '../../../components/DataLoading';
import GridDropDownFilter from '../../../components/GridDropDownFilter';
import Modal from '../../../components/Modal';
import Tag from '../../../components/Tag';
import Typography from '../../../components/Typography';
import { ActivityStatus } from '../../../models/Activity';
import { CampaignStatus } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import UserInCampaign from '../../../models/UserInCampaign';
import { getListFromEnum, useWindowDimensions } from '../../../utils';
import { getColorByActivityStatus } from '../../../utils/getColorByStatus';
import pagerSettings, { pagerMiniSettings } from '../../../utils/pagerSettings';
import { AuthContext } from '../../Authentication/state';
import UserSubmissions from '../UserSubmissions';
import UserCampaignAdminAction from './UserCampaignAdminAction';
import { ReactComponent as DollarIcon } from '../../../assets/icons/dollar-sign.svg';
import UserCampaignInvoice from '../../Financials/UserCampaignInvoice';
import { PaymentOption } from '../../../models/Payment';

const CampaignUsers: React.FC<{ campaignId: string; status: CampaignStatus, stipend: string }> = ({ campaignId, status, stipend }) => {
    const [data, setData] = React.useState<UserInCampaign[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { user } = React.useContext(AuthContext).state;
    const isAuthorized = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    const history = useHistory();
    const { width } = useWindowDimensions();
    const [selectedUser, setSelectedUser] = React.useState<UserInCampaign>();
    const [actionModal, setActionModal] = React.useState<'REPORT' | 'INVOICE' | ''>('');

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ActivityService.getUsersInCampaign(campaignId)
            .then((response) => {
                setData(response);
            })
            .finally(() => toggleLoading(false));
    };

    const canInviteSynner = () => {
        return status === CampaignStatus.Ready && isAuthorized;
    };

    const UserAvatar = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item activity-thumbnail">
                {isAuthorized && props.dataItem.invoiceIsCreated && (
                    <div className="invoiced-overlay">
                        <DollarIcon />
                    </div>
                )}
                <Avatar size="small" name={props.dataItem.name} source={props.dataItem.photoUrl} />
            </td>
        );
    };

    const statuses = getListFromEnum(Object.values(ActivityStatus));

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const Status = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Tag
                    label={ActivityStatus[props.dataItem.activityStatus]}
                    color={getColorByActivityStatus(props.dataItem.activityStatus)}
                />
            </td>
        );
    };

    const handleCreateInvoice = () => {
        setSelectedUser(undefined);
        setActionModal('');
        getData();
    };

    return (
        <div className="users-in-campaign">
            <Typography variant="subtitle" color="secondary">
                SYNNERS IN CAMPAIGN
            </Typography>
            <hr />
            <br />
            <Grid
                style={{ height: '100%' }}
                sortable={true}
                pageable={width > 1000 ? pagerSettings : pagerMiniSettings}
                filterable={true}
                data={process(data, dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                }}
                // eslint-disable-next-line react/prop-types
                onRowClick={(props) =>
                    user && user.role !== UserRole.Trainee && history.push(`/users/${props.dataItem.id}/detail`)
                }
            >
                {canInviteSynner() && (
                    <GridToolbar>
                        <Button color="primary" onClick={() => history.push(`/campaigns/${campaignId}/invitation`)}>
                            Invite Synners
                        </Button>{' '}
                    </GridToolbar>
                )}
                <GridColumn
                    width="80px"
                    sortable={false}
                    cell={UserAvatar}
                    field="photoUrl"
                    title=" "
                    filterable={false}
                />
                {width > 1000 && <GridColumn field="name" title="Name" />}
                {width > 1000 && (
                    <GridColumn
                        field="activityStatus"
                        title="Status"
                        cell={Status}
                        filterCell={StatusFilterCell}
                        width="160px"
                    />
                )}
                {width <= 1000 && (
                    <GridColumn
                        field="name"
                        title="search by name"
                        cell={(props: GridCellProps) => (
                            <td className="grid-item-mobile grid-item-mobile">
                                <div>
                                    <Typography variant="subtitle" weight={500}>
                                        {props.dataItem.name}
                                    </Typography>
                                    <div className="legend">
                                        <span
                                            className={`color-${getColorByActivityStatus(
                                                props.dataItem.activityStatus,
                                            )}`}
                                        >
                                            &#11044;
                                        </span>
                                        <Typography variant="body">
                                            {ActivityStatus[props.dataItem.activityStatus]}
                                        </Typography>
                                    </div>
                                </div>

                                {isAuthorized && (
                                    <div className="grid-actions">
                                        <UserCampaignAdminAction
                                            data={props.dataItem as UserInCampaign}
                                            getData={getData}
                                            openReport={(synner) => {
                                                setSelectedUser(synner);
                                                setActionModal('REPORT');
                                            }}
                                            openInvoice={(synner) => {
                                                setSelectedUser(synner);
                                                setActionModal('INVOICE');
                                            }}
                                            campaignstatus={status}
                                        />
                                    </div>
                                )}
                            </td>
                        )}
                    />
                )}
                {isAuthorized && width > 1000 && (
                    <GridColumn
                        cell={(props: GridCellProps) => (
                            <td className="grid-actions">
                                <UserCampaignAdminAction
                                    data={props.dataItem as UserInCampaign}
                                    getData={getData}
                                    openReport={(synner) => {
                                        setSelectedUser(synner);
                                        setActionModal('REPORT');
                                    }}
                                    openInvoice={(synner) => {
                                        setSelectedUser(synner);
                                        setActionModal('INVOICE');
                                    }}
                                    campaignstatus={status}
                                />
                            </td>
                        )}
                        width={width > 1000 ? '280px' : '80px'}
                        filterable={false}
                        sortable={false}
                    />
                )}
            </Grid>
            <Modal
                isOpen={selectedUser !== undefined && actionModal === 'REPORT'}
                close={() => {
                    setSelectedUser(undefined);
                    setActionModal('');
                }}
                size="medium"
                title="Campaign Reports"
            >
                <UserSubmissions campaignId={campaignId} synnerId={selectedUser?.id} />
            </Modal>
            <Modal
                isOpen={selectedUser !== undefined && actionModal === 'INVOICE'}
                close={() => {
                    setSelectedUser(undefined);
                    setActionModal('');
                }}
                size="medium"
                title="Create Invoice"
            >
                <UserCampaignInvoice
                    invoice={{
                        amount: stipend,
                        synnerId: selectedUser?.id as string,
                        campaignId: campaignId,
                        notes: '',
                        paymentMethod: PaymentOption.CHECK,
                    }}
                    onSuccess={handleCreateInvoice}
                />
            </Modal>
        </div>
    );
};

export default CampaignUsers;
