import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './projectwrapper.scss';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';

type ProjectTabs = 'All Projects' | 'Archived Projects';

const ProjectWrapper: React.FC<{children: React.ReactChild}> = ({children}) => {
    const [active, setActive] = React.useState<ProjectTabs>();
    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname === '/projects/archived') setActive('Archived Projects');
        if (location.pathname === '/projects') setActive('All Projects');
    }, [location]);

    return (
        <div className="selection-wrapper">
            <div className="tabs">
                <Link to="/projects"><div
                    className={`selection ${active === 'All Projects' ? 'active' : ''}`}
                >
                    <GridIcon />
                    <span>Projects</span>
                </div>
                </Link>
                <Link to="/projects/archived">
                <div
                    className={`selection ${active === 'Archived Projects' ? 'active' : ''}`}
                >
                    <ArchiveIcon />
                    <span>Project Archives</span>
                </div>
                </Link>
            </div>
            <div className='projects'>
                    {children}
            </div>
        </div>
    );
};
export default ProjectWrapper;
