import React from 'react';
import { SurveyElement, SurveyElementType } from '../../../models/Survey';
import { SurveyBuilderActionType, surveyBuilderContext } from './state';
import SurveyCheckboxDetail from './SurveyCheckboxDetail';
import SurveyDropdownDetail from './SurveyDropdownDetail';
import SurveyFileuploadDetail from './SurveyFileuploadDetail';
import SurveyInputDetail from './SurveyInputDetail';
import SurveyTextAreaDetail from './SurveyTextAreaDetail';
import SurveyRadioButtonDetail from './SurveyRadioButtonDetail';
import SurveyWebContentDetail from './SurveyWebContentDetail';
import NoSelection from './NoSelection';

const ElementDetail: React.FC = () => {
    const { selectedElement, selectedElementIndex, survey } = React.useContext(surveyBuilderContext).state;
    const dispatch = React.useContext(surveyBuilderContext).dispatch;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (data: any) => {
        const surveyElements = [...survey.elements];
        surveyElements[selectedElementIndex] = { ...(selectedElement as SurveyElement), metaData: data };
        dispatch({
            type: SurveyBuilderActionType.SET_SURVEY,
            payload: { ...survey, elements: surveyElements },
        });
    };
    
    return (
        <>
            {selectedElement && selectedElementIndex !== -1 && (
                <>
                    {selectedElement.type === SurveyElementType.Checkbox && (
                        <SurveyCheckboxDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.Dropdown && (
                        <SurveyDropdownDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.FileUpload && (
                        <SurveyFileuploadDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.Input && (
                        <SurveyInputDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.TextArea && (
                        <SurveyTextAreaDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.RadioButton && (
                        <SurveyRadioButtonDetail data={selectedElement.metaData} handleSubmit={handleSubmit} />
                    )}
                    {selectedElement.type === SurveyElementType.WebContent && (
                        <SurveyWebContentDetail data={selectedElement.metaData} handleSubmit={handleSubmit}/>
                    )}
                </>
            )}
            {survey.elements.length>0 && !selectedElement && <NoSelection/>}
        </>
    );
};

export default ElementDetail;
