export default interface SocialMedia {
    type: SocialMediaType,
    profileUrl: string,
    followerCount?: number
}

export enum SocialMediaType  {
    Facebook = 0,
    Twitter = 1,
    Instagram = 2,    
    TikTok = 3,
    Reddit = 4,    
    LinkedIn = 5,
    Pinterest = 6,
    Youtube = 7,
}