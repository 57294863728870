import React from 'react';
import Button from '../../../components/Button';
import Img from '../../../components/Img';
import Typography from '../../../components/Typography';
import Upload from '../../../components/Upload';

type Props = {
    onUpload: (file: File) => Promise<string>;
    source?: string;
};

const ProjectPicture: React.FC<Props> = ({ source, onUpload }) => {
    const [showUpload, setShowUpload] = React.useState(source ? false : true);
    const [loading, setLoading] = React.useState(false);

    const handleUpload = (file: File | File[]) => {
        onUpload(file as File)
            .then(() => {
                setLoading(false);
                setShowUpload(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <div className="project-image-container">
            {!showUpload ? (
                <>
                    {source && <div style={{maxWidth: "240px"}}><Img src={source} alt="project image" className="project-image" /></div>}
                    <Button onClick={() => setShowUpload(true)}>
                        {source ? 'Edit Image' : 'Add Image'}
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="subtitle">Project Image</Typography>
                    <Upload maxSize={5120} isMultiple={false} handleUpload={handleUpload} loading={loading} fileTypes={["jpg", "jpeg", "png", "gif", "bmp", "svg", "heic", "webp"]}/>
                </>
            )}
        </div>
    );
};

export default ProjectPicture;
