import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';

const AccountSection: React.FC = () => {
    const { user } = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const history = useHistory();

    return (
        <>
            {user && (
                <div
                    className="profile-section"
                    tabIndex={0}
                    onClick={() => history.push('/profile')}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                        e.key === 'Enter' && history.push('/profile')
                    }
                >
                    <div>
                        <p className="profile-name">{user.fname + ' ' + user.lname}</p>
                        <p
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch({ type: AuthActionType.LOGOUT });
                            }}
                            tabIndex={0}
                            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    dispatch({ type: AuthActionType.LOGOUT });
                                }
                            }}
                            className="logout-link"
                        >
                            Logout
                        </p>
                    </div>
                    <Tooltip text="Profile">
                        <div className="action-bar-avatar">
                                <Avatar size="small" source={user.photoUrl} name={user.fname as string} />
                        </div>
                    </Tooltip>

                </div>
            )}
        </>
    );
};

export default AccountSection;
