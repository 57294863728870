import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { useDebounse } from '../../../utils';
import { InvitationActionType, InvitationContext } from './state';
import LoaderGif from '../../../assets/images/loader.gif';

const SearchBar: React.FC<{isLoading: boolean}> = ({isLoading}) => {
    const { dispatch, state } = React.useContext(InvitationContext);
    const debouncedSearch = useDebounse(state.searchText, 1500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({
            type: InvitationActionType.SET_SEARCH_TEXT,
            payload: e.target.value,
        });
    };

    React.useEffect(()=> {
        dispatch({
            type: InvitationActionType.TOGGLE_DATA_FETCH
        });
    }, [debouncedSearch])

    return (
        <div className="searchbar">
            <div className="search-icon">
                <SearchIcon />
            </div>
            <div className="search-input-wrapper">
                <input
                    type="text"
                    className="search-input"
                    placeholder="search synners by name, email"
                    onChange={handleChange}
                    autoFocus={true}
                    value={state.searchText?state.searchText: ''}
                />
                {isLoading && (
                <div className="content-loading">
                    <img src={LoaderGif} alt="loader" />
                </div>
            )}
            </div>
        </div>
    );
};

export default SearchBar;
