import instance from './instance';

import Profile from '../models/Profile';
import { UploadFileType } from '../models/Upload';

function getProfile(): Promise<Profile> {
    return instance.get(`profile`).then((response) => {
        return response.data;
    });
}

function updateProfile(profile: Profile): Promise<boolean> {
    return instance.put(`user`, profile).then((response) => {
        return response.data;
    });
}

function uploadProfilePicture(id: string, file: File): Promise<string> {
    const formData = new FormData();
    formData.append('synnerId', id);
    formData.append('type', UploadFileType.ProfilePicture.toString());
    formData.append('file', file);
    return instance.post(`/upload`, formData).then((response) => {
        return response.data;
    });
}

export { getProfile, updateProfile, uploadProfilePicture };
