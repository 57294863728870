import React from 'react';
import { CampaignService } from '../../api';
import Card from '../../components/Card';
import IconButton from '../../components/IconButton';
import Typography from '../../components/Typography';
import Campaign from '../../models/Campaign';
import { ProjectType } from '../../models/Project';
import { ReactComponent as DownIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpIcon } from '../../assets/icons/chevron-up.svg';
import { formatDate } from '../../utils';

const ReportCampaign: React.FC<{ campaignId: string }> = ({ campaignId }) => {
    const [campaign, setCampaign] = React.useState<Campaign>();
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        CampaignService.getCampaign(campaignId).then((response) => setCampaign(response));
    }, [campaignId]);
    return (
        <>
            {campaign && (
                <div className="report-campaign">
                    <Card>
                        <Typography variant="subheading" color="secondary" weight={600}>
                            {campaign.projectName}
                        </Typography>
                        <Typography variant="subtitle" color="secondary">
                            {campaign.name}
                        </Typography>
                        <IconButton Icon={!expanded ? DownIcon : UpIcon} onClick={() => setExpanded(!expanded)} />
                        <>
                            {expanded && (
                                <>
                                    <br />
                                    <div className="form-grid-third">
                                        <div>
                                            <Typography variant="body" weight={500}>
                                                {campaign.type === ProjectType['Day-of-Show'] ||
                                                campaign.type === ProjectType['Competitive Event']
                                                    ? 'Event Date'
                                                    : 'Start Date'}
                                            </Typography>
                                            <Typography variant="body">{formatDate(campaign.startDate)}</Typography>
                                        </div>
                                        {campaign.type !== ProjectType['Day-of-Show'] &&
                                            campaign.type !== ProjectType['Competitive Event'] && (
                                                <div>
                                                    <Typography variant="body" weight={500}>
                                                        End Date
                                                    </Typography>
                                                    <Typography variant="body">
                                                        {formatDate(campaign.endDate)}
                                                    </Typography>
                                                </div>
                                            )}
                                    </div>
                                    <br />
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            Description
                                        </Typography>
                                        <Typography variant="body">{campaign.description}</Typography>
                                    </div>
                                </>
                            )}
                        </>
                    </Card>
                </div>
            )}
        </>
    );
};

export default ReportCampaign;
