import { process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridRowProps,
    GridToolbar,
} from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import Project, { ProjectStatus, ProjectType } from '../../../models/Project';
import { getColorByProjectStatus } from '../../../utils/getColorByStatus';
import { pagerMiniSettings } from '../../../utils/pagerSettings';
import ProjectAvatar from '../ProjectAvatar';
import ProjectActions from './ProjectActions';
import { formatDate } from '../../../utils';

type Props = {
    data: Project[];
    getData: () => void;
};

const MiniGridList: React.FC<Props> = ({ data, getData }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: ProjectStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `project--${getColorByProjectStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    return (
        <Grid
            style={{ height: '100%' }}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            pageable={pagerMiniSettings}
            filterable={true}
            sortable={false}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/projects/${props.dataItem.id}/detail`)}
            rowRender={rowRender}
        >
            <GridToolbar>
                <Button onClick={() => history.push(`/projects/create-project`)}>Create</Button>
            </GridToolbar>
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn
                title="search by name"
                field="name"
                cell={(props: GridCellProps) => {
                    return (
                        <td className="grid-item-mobile clickable-grid-custom-item">
                            <div>
                                <div>
                                    <Typography variant="body" weight={500}>
                                        Name
                                    </Typography>
                                    <Typography variant="caption">{props.dataItem.name}</Typography>
                                </div>
                                <div>
                                    <Typography variant="body" weight={500}>
                                        Type
                                    </Typography>
                                    <Typography variant="caption">{ProjectType[props.dataItem.type]}</Typography>
                                </div>
                                <div>
                                    <Typography variant="body" weight={500}>
                                        Date Updated
                                    </Typography>
                                    <Typography variant="caption">{formatDate(props.dataItem.updatedAt)}</Typography>
                                </div>
                            </div>
                            <div className="grid-actions">
                                <ProjectActions project={props.dataItem as Project} refreshData = {getData} />
                            </div>
                        </td>
                    );
                }}
            />
        </Grid>
    );
};

export default MiniGridList;
