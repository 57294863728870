import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { formatLocation } from '../../../utils';

const CampaignAddressCell: React.FC<GridCellProps> = (props) => {
    return (
        <td>
            {props.dataItem.venue && (
                <>
                    <b>{props.dataItem.venue}</b>
                    <br />
                </>
            )}
            {formatLocation(
                props.dataItem.addressLine1,
                props.dataItem.addressLine2,
                props.dataItem.city,
                props.dataItem.state,
                props.dataItem.country,
                props.dataItem.zipCode,
            )}
        </td>
    );
};

export default CampaignAddressCell;
