import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ContentService } from '../../api';
import Button from '../../components/Button';
import { DataLoadingContext } from '../../components/DataLoading';
import EditablePage from '../../components/EditablePage';
import Content, { ContentType } from '../../models/Content';
import { AuthContext } from '../Authentication/state';
import DefaultListItemTemplate from '../ContentManager/DefaultListItemTemplate';

const Announcements: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const isEdit = React.useContext(AuthContext).state.isEditorView;
    const [announcements, setAnnouncements] = React.useState<Content[]>([]);
    const [skip, setSkip] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const history = useHistory();

    React.useEffect(() => {
        getData();
    }, [skip]);

    const getData = () => {
        toggleLoading(true);
        ContentService.getContents(ContentType.Announcement, skip).then((response) => {
            setAnnouncements(response.items);
            setTotal(response.total);
        }).finally(()=>toggleLoading(false));
    };

    return (
        <EditablePage>
            <div className="announcements" ref={ref}>
                {isEdit && (
                    <Link to="/announcement/create-announcement"><Button
                        color="primary"
                        className="d-block mx-auto"
                    >
                        Create Announcement
                    </Button></Link>
                )}
                <Pager
                    skip={skip}
                    take={5}
                    total={total}
                    onPageChange={(event: PageChangeEvent) => setSkip(event.skip)}
                    responsive={true}
                    style={{ maxWidth: '720px', margin: '0 auto' }}
                />
                {announcements.map((content, index) => (
                    <DefaultListItemTemplate
                        content={content}
                        key={index}
                        viewDetail={(id) => history.push(`/announcement/${id}`)}
                    />
                ))}
                <Pager
                    skip={skip}
                    take={5}
                    total={total}
                    onPageChange={(event: PageChangeEvent) =>{setSkip(event.skip); ref.current?.scrollIntoView({
                        behavior: "smooth",
                      });}}
                    responsive={true}
                    style={{ maxWidth: '720px', margin: '0 auto' }}
                />
            </div>
        </EditablePage>
    );
};
export default Announcements;
