import React from 'react';
import { ReportService } from '../../api';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import { UserReportStatus } from '../../models/Report';
import { UserRole } from '../../models/User';
import { AuthContext } from '../Authentication/state';

const Comment: React.FC<{ comment: string; status: UserReportStatus; id: string }> = ({ comment, status, id }) => {
    const { user } = React.useContext(AuthContext).state;
    const [updatedComment, setUpdatedComment] = React.useState(comment);
    const [isEdit, setIsEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);

    const handleChange = (_name: string, value: string) => {
        setUpdatedComment(value);
    };

    const handleSave = () => {
        setLoading(true);
        ReportService.addComment({ id: id, comments: updatedComment }).finally(() => {
            setIsEdit(false);
            setLoading(false);
        });
    };
    if (status !== UserReportStatus['Pending Review'] && status !== UserReportStatus['Need More Info']) return null;
    return (
        <div className="report-comment">
            <Typography variant="body">
                <strong>Comments:</strong>
            </Typography>
                <div className='comment-body'>
                    <TextArea onChange={handleChange} name="comment" value={updatedComment} disabled={!isEdit} placeholder="no comments available"/>
                    {isEdit && <Button onClick={handleSave} loading={loading}>
                        Save
                    </Button>}
                    {!isEdit && isAdminManager && <Button onClick={() => setIsEdit(true)}>Edit</Button>}
                </div>
        </div>
    );
};

export default Comment;
