import React from 'react';
import './landing.scss';
import Logo from '../../assets/images/syndicate-logo.png';
import Typography from '../../components/Typography';
import Login from './Login';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../utils';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
    const { width } = useWindowDimensions();
    return (
        <div className="page landing-page">
            <section>
                {width < 640 && (
                    <div className="logo-container">
                        <img src={Logo} alt="Logo" className="logo" />
                    </div>
                )}
                <div className="content">
                    {width >= 640 && (
                        <div className="intro-section">
                            <div className="logo-container">
                                <img src={Logo} alt="Logo" className="logo" />
                            </div>
                            <div>
                                <Typography
                                    variant="subheading"
                                    color="primary"
                                    weight={600}
                                    textAlign={width <= 640 ? 'center' : 'left'}
                                >
                                    CULTURE-CONNECTED <br />
                                    IDEAS IN ACTION
                                </Typography>
                                <br/>
                                <Typography variant="subtitle">
                                    For more than 20 years, SynnerNation has promoted music, comedy, brands, and pop culture through a variety of in-person and online methods. We’re always hiring aspiring industry professionals and pop culture enthusiasts who are passionate fans of music, live events, and stand-up comedy to become a part of our street marketing team across the country.
                                </Typography>
                                <br />
                                <Link to="/apply">
                                    <Button color="secondary">Apply Now</Button>
                                </Link>
                            </div>
                        </div>
                    )}
                    <Login />
                </div>
            </section>
        </div>
    );
};

export default LandingPage;
