import React from 'react';
import Typography from '../../components/Typography';
import InputField from '../../components/InputField';
import Radio from '../../components/Radio';
import Button from '../../components/Button';
import { getStates } from '../../utils';
import { EducationDetails } from '../../models/Application';
import InputSelect from '../../components/InputSelect';

type Props = {
    data: EducationDetails;
    handleSave: (data: EducationDetails) => void;
    handleButtonClick: (action: string) => void;
};

const Education: React.FC<Props> = ({ data, handleSave, handleButtonClick }) => {
    const states = getStates();
    const ThisYear = new Date().getFullYear();
    const dates = [
        {
            label: ThisYear.toString(),
            value: ThisYear.toString(),
        },
        {
            label: (ThisYear + 1).toString(),
            value: (ThisYear + 1).toString(),
        },
        {
            label: (ThisYear + 2).toString(),
            value: (ThisYear + 2).toString(),
        },
        {
            label: (ThisYear + 3).toString(),
            value: (ThisYear + 3).toString(),
        },
        {
            label: (ThisYear + 4).toString(),
            value: (ThisYear + 4).toString(),
        },
        {
            label: (ThisYear + 5).toString(),
            value: (ThisYear + 5).toString(),
        },
        {
            label: (ThisYear + 6).toString(),
            value: (ThisYear + 6).toString(),
        },
        {
            label: (ThisYear + 7).toString(),
            value: (ThisYear + 7).toString(),
        },
        {
            label: (ThisYear + 8).toString(),
            value: (ThisYear + 8).toString(),
        },
    ];

    const schoolYears = [
        {
            label: 'Freshman',
            value: 'freshman',
        },
        {
            label: 'Sophomore',
            value: 'sophomore',
        },
        {
            label: 'Junior',
            value: 'junior',
        },
        {
            label: 'Senior',
            value: 'senior',
        },
        {
            label: 'Other',
            value: 'other',
        },
    ];

    const [educationDetails, setEducationDetails] = React.useState(data);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setEducationDetails(data);
    }, [data]);

    const handleChange = (name: string, value: string | boolean | number) => {
        setEducationDetails({ ...educationDetails, [name]: value });
    };

    const handleSubmit = (): void => {
        let isValid = false;
        if (educationDetails.isStudent) {
            isValid =
                educationDetails.college.length > 0 &&
                educationDetails.collegeCity.length > 0 &&
                educationDetails.collegeState.length > 0 &&
                educationDetails.expectedGraduation.length > 0 &&
                educationDetails.major.length > 0 &&
                educationDetails.schoolYear.length > 0;
            if (!isValid) {
                setShowError(true);
            } else {
                handleSave(educationDetails);
                handleButtonClick('NEXT');
            }
        } else {
            handleSave(educationDetails);
            handleButtonClick('NEXT');
        }
    };

    const handleBack = () => {
        handleButtonClick('BACK');
    };

    return (
        <>
            <div>
                <Typography variant="body" color="secondary">
                    Are you a student?
                </Typography>
                <Radio
                    onChange={() => handleChange('isStudent', true)}
                    checked={educationDetails.isStudent}
                    label="Yes"
                />
                <Radio
                    onChange={() => handleChange('isStudent', false)}
                    checked={!educationDetails.isStudent}
                    label="No"
                />
            </div>
            {educationDetails.isStudent && (
                <>
                    <div className="form-grid-third">
                        <div>
                            <Typography variant="body">
                                What year of school are you?{' '}
                                {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputSelect
                                options={schoolYears}
                                value={educationDetails.schoolYear}
                                name="schoolYear"
                                placeholder="School Year"
                                onChange={handleChange}
                                error={
                                    showError && educationDetails.isStudent && educationDetails.schoolYear.length === 0
                                }
                                errorText="required"
                            />
                        </div>
                        <div>
                            <Typography variant="body">
                                Major {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputField
                                onChange={handleChange}
                                name="major"
                                value={educationDetails.major}
                                error={showError && educationDetails.isStudent && educationDetails.major.length === 0}
                                errorText="required"
                            />
                        </div>
                        <div>
                            <Typography variant="body">
                                When do you expect to graduate?{' '}
                                {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputSelect
                                options={dates}
                                onChange={handleChange}
                                name="expectedGraduation"
                                placeholder=" "
                                value={educationDetails.expectedGraduation}
                                error={
                                    showError &&
                                    educationDetails.isStudent &&
                                    educationDetails.expectedGraduation.length === 0
                                }
                                errorText="required"
                            />
                        </div>
                    </div>
                    <div className="form-grid-third">
                        <div>
                            <Typography variant="body">
                                College {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputField
                                onChange={handleChange}
                                name="college"
                                value={educationDetails.college}
                                error={showError && educationDetails.isStudent && educationDetails.college.length === 0}
                                errorText="required"
                            />
                        </div>
                        <div>
                            <Typography variant="body">
                                City {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputField
                                onChange={handleChange}
                                name="collegeCity"
                                value={educationDetails.collegeCity}
                                error={
                                    showError && educationDetails.isStudent && educationDetails.collegeCity.length === 0
                                }
                                errorText="required"
                            />
                        </div>
                        <div>
                            <Typography variant="body">
                                State {educationDetails.isStudent && <span className="color-error">*</span>}
                            </Typography>
                            <InputSelect
                                options={states}
                                value={educationDetails.collegeState}
                                name="collegeState"
                                placeholder="State"
                                onChange={handleChange}
                                error={
                                    showError &&
                                    educationDetails.isStudent &&
                                    educationDetails.collegeState.length === 0
                                }
                                errorText="required"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="action-container">
                <Button color="primary" onClick={handleBack}>
                    Back
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default Education;
