import React from 'react';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { useParams } from 'react-router-dom';
import { ApplicationService } from '../../api';
import { Applicant } from '../../models/Application';
import ApplicationStatusActionBar from './ApplicantStatusActionBar';
import Card, { CardActionBar, CardActionHeader } from '../../components/Card';
import Socials from '../../components/Socials';
import { formatDateOnly, formatLocation } from '../../utils';
import { Link } from 'react-router-dom';

const ApplicantDetail: React.FC = () => {
    const [applicant, setApplicant] = React.useState<Applicant | null>(null);
    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        ApplicationService.getApplicant(id).then((response) => {
            setApplicant(response);
        });
    }, [id]);

    const handleStatusChange = () => {
        ApplicationService.getApplicant(id).then((response) => {
            setApplicant(response);
        });
    };

    return (
        <div className="container applicants">
            <div className="applicant-detail">
                {applicant && (
                    <Card>
                        <CardActionHeader>
                            <ApplicationStatusActionBar applicant={applicant} handleStatusChange={handleStatusChange} />
                        </CardActionHeader>
                        <Typography
                            variant="heading"
                            color="primary"
                            weight={600}
                            textAlign="center"
                        >{`${applicant.fname} ${applicant.lname}`}</Typography>
                        <br />
                        <Typography variant="subtitle" weight={500} color="secondary">
                            Personal Details
                        </Typography>
                        <br />
                        <div className="details">
                            <div className="detail-item">
                                <Typography variant="body" weight={500}>
                                    Name
                                </Typography>
                                <Typography variant="body">{`${applicant.fname} ${applicant.lname}`}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Email
                                </Typography>
                                <Typography variant="body">{applicant.emailAddress}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Phone Number
                                </Typography>
                                <Typography variant="body">{applicant.phoneNumber}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Pronouns
                                </Typography>
                                <Typography variant="body">{applicant.preferredPronouns}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Date of Birth
                                </Typography>
                                <Typography variant="body">{formatDateOnly(applicant.dateOfBirth)}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Address
                                </Typography>
                                <Typography variant="body">
                                    {formatLocation(
                                        applicant.addressLine1,
                                        applicant.addressLine2,
                                        applicant.city,
                                        applicant.state,
                                        applicant.country,
                                        applicant.zipCode,
                                    )}
                                </Typography>
                            </div>
                        </div>
                        <br />
                        <Typography variant="subtitle" weight={500} color="secondary">
                            Education Details
                        </Typography>
                        <br />
                        <div className="details">
                            <div>
                                <Typography variant="body" weight={500}>
                                    Are you a student?
                                </Typography>
                                <Typography variant="body">{applicant.isStudent ? 'Yes' : 'No'}</Typography>
                            </div>
                            {applicant.isStudent && (
                                <>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            What year of school are you?
                                        </Typography>
                                        <Typography variant="body">{applicant.schoolYear}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            Major
                                        </Typography>
                                        <Typography variant="body">{applicant.major}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            When do you expect to graduate?
                                        </Typography>
                                        <Typography variant="body">{applicant.expectedGraduation}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            College
                                        </Typography>
                                        <Typography variant="body">{applicant.college}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            City
                                        </Typography>
                                        <Typography variant="body">{applicant.collegeCity}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body" weight={500}>
                                            State
                                        </Typography>
                                        <Typography variant="body">{applicant.collegeState}</Typography>
                                    </div>
                                </>
                            )}
                        </div>
                        <br />
                        <Typography variant="subtitle" weight={500} color="secondary">
                            Online Activity Details
                        </Typography>
                        <br />
                        <div className="details">
                            <div>
                                <Typography variant="body" weight={500}>
                                    How often do you check your email?
                                </Typography>
                                <Typography variant="body">{applicant.emailFrequency}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Social Media
                                </Typography>
                                <Socials socials={applicant.socialMedias} />
                            </div>
                        </div>
                        <br />
                        <Typography variant="subtitle" weight={500} color="secondary">
                            Music/Comedy Details
                        </Typography>
                        <br />
                        <div className="details">
                            <div>
                                <Typography variant="body" weight={500}>
                                    What are your favorite bands / singers or songwriters / comedians?
                                </Typography>
                                <Typography variant="body">{applicant.favouriteArtist}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Pick one from above and explain what excited you about them.
                                </Typography>
                                <Typography variant="body">{applicant.favouriteExplanation}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    How often do you attend shows?
                                </Typography>
                                <Typography variant="body">{applicant.showFrequency}</Typography>
                            </div>
                        </div>
                        <br />
                        <Typography variant="subtitle" weight={500} color="secondary">
                            Other Details
                        </Typography>
                        <br />
                        <div className="details">
                            <div>
                                <Typography variant="body" weight={500}>
                                    How did you hear about The Syndicate?
                                </Typography>
                                <Typography variant="body">{applicant.howSyndicate}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    What kind of Smart phone do you have?
                                </Typography>
                                <Typography variant="body">{applicant.whatKindSmartPhone}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    How much free time do you have per week?
                                </Typography>
                                <Typography variant="body">
                                    {applicant.freeTime ? applicant.freeTime.toString() : ''}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    What takes up your free time?
                                </Typography>
                                <Typography variant="body">{applicant.FreeTimeActivity}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Why do you want to be Street Marketing Representative for The Syndicate?
                                </Typography>
                                <Typography variant="body">{applicant.whyCareerGoal}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Have you ever been on a street team?
                                </Typography>
                                <Typography variant="body">{applicant.streetTeamExperience}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Is there anything else you would like us to know?
                                </Typography>
                                <Typography variant="body">{applicant.comments}</Typography>
                            </div>
                        </div>
                        <CardActionBar>
                            <Link to="/applicants">
                                <Button color="secondary">Close</Button>
                            </Link>
                        </CardActionBar>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default ApplicantDetail;
