import React from 'react';
import InputField from '../../../components/InputField';
import Typography from '../../../components/Typography';
import { SurveyInputData } from '../../../models/Survey';

type Props = {
    value?: string;
    data: SurveyInputData;
    handleChange: (name: string, value: string) => void;
};

const SurveyInput: React.FC<Props> = ({ value, data, handleChange }) => {
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <InputField
                onChange={handleChange}
                name={data.name}
                required={data.required}
                value={value}
                type={data.type}
            />
        </div>
    );
};

export default SurveyInput;
