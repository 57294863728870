import React from 'react';
import Radio from '../../../components/Radio';
import Typography from '../../../components/Typography';
import { SurveyRadioData } from '../../../models/Survey';

type Props = {
    value?: string;
    data: SurveyRadioData;
    handleChange: (name: string, value: string) => void;
};

const SurveyRadio: React.FC<Props> = ({ value, data, handleChange }) => {
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <br />
            {data.options && data.options.map((item, index) => (
                <Radio
                    key={index}
                    onChange={() => handleChange(data.name, item)}
                    checked={value === item}
                    label={item}
                />
            ))}
            <br />
        </div>
    );
};

export default SurveyRadio;
