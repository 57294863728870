import React from 'react';
import { Link } from 'react-router-dom';
import { ActivityService } from '../../api';
import Typography from '../../components/Typography';
import { SynnerActivity } from '../../models/Activity';
import { ProjectType } from '../../models/Project';
import { formatDate } from '../../utils';

const RecentCompletedCampaigns: React.FC<{ id: string }> = ({ id }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [activities, setActivities] = React.useState<SynnerActivity[]>([]);

    React.useEffect(() => {
        ActivityService.getRecentActivityForSynner(id)
            .then((response) => {
                setActivities(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);
    return (
        <>
            <Typography variant="subtitle" textAlign="center">
                Recent completed campaigns
            </Typography>
            <table>
                <thead>
                    <tr>
                        <th>
                            <Typography variant="body" weight={500} textAlign="center">
                                Campaign
                            </Typography>
                        </th>
                        <th>
                            <Typography variant="body" weight={500} textAlign="center">
                                Last Updated
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map((activity, index) => (
                        <tr key={index}>
                            <td>
                                <Typography variant="body" weight={500}>
                                    {activity.project}
                                </Typography>
                                <Typography variant="caption">{activity.campaign}</Typography>
                                <Typography variant="caption">
                                    {ProjectType[activity.type]}
                                </Typography>
                            </td>
                            <td>
                                <Typography variant="caption" textAlign="center">
                                    {formatDate(activity.updatedAt)}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {!isLoading && activities.length === 0 && (
                <Typography variant="caption" textAlign="center">
                    No records found
                </Typography>
            )}
            <br/>
            <div style={{display: 'flex', justifyContent: 'space-between', gridGap: '12px'}}>
            <Link to={`/users/${id}/campaigns`}>
                <Typography variant="caption" textAlign="right" color="info">
                    View all user campaigns
                </Typography>
            </Link>
            <Link to={`/users/${id}/reports`}>
                <Typography variant="caption" textAlign="right" color="info">
                    View all user reports
                </Typography>
            </Link>
            </div>
            
        </>
    );
};

export default RecentCompletedCampaigns;
