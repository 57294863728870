import React from 'react';
import Content, { ContentStatus, ContentType } from '../../models/Content';
import ContentManager from '../ContentManager';
import Typography from '../../components/Typography';
import EditablePage from '../../components/EditablePage';

const initAnnouncement: Content = {
    type: ContentType.Announcement,
    status: ContentStatus.Draft,
    title: '',
    description: '',
    data: '',
    isFeatured: false,
};

const CreateAnnouncement: React.FC = () => {    

    return (
        <EditablePage>
            <div className="announcements">
                <Typography variant="subtitle" textAlign="center">
                    CREATE ANNOUNCEMENT
                </Typography>
                <ContentManager content={initAnnouncement}>
                    <Typography variant="subtitle" color="primary" textAlign="center">
                        Please switch to editor mode to create an announcement.
                    </Typography>
                </ContentManager>
            </div>
        </EditablePage>
    );
};

export default CreateAnnouncement;
