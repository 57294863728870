import React from 'react';
import { NotificationActionType, NotificationAction } from './actions';
import NotificationModel from '../NotificationModel';

export const notificationInitialState: NotificationModel = {
    isOpen: false,
};

//reducers
export function notificationReducer(state: NotificationModel, action: NotificationAction): NotificationModel {
    switch (action.type) {
        case NotificationActionType.OPEN:
            return { ...action.payload, isOpen: true };
        case NotificationActionType.CLOSE:
            return {
                ...notificationInitialState,
            };
        default:
            return state;
    }
}

export const NotificationContext = React.createContext<{
    notificationState: NotificationModel;
    notificationDispatch: React.Dispatch<NotificationAction>;
}>({
    notificationState: notificationInitialState,
    notificationDispatch: () => undefined,
});
