import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import Typography from '../../components/Typography';
import Project from '../../models/Project';
import ProjectForm from './ProjectForm';

const EditProject: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [project, setProject] = React.useState<Project>();
    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        toggleLoading(true);
        ProjectService.getProject(id)
            .then((response) => {
                setProject(response);
            })
            .finally(() => toggleLoading(false));
    }, [id]);

    const handleSubmit = (updatedProject: Project): Promise<Project> => {
        return ProjectService.updateProject(updatedProject);
    };

    return (
        <div className="projects">
                <Typography variant="title" textAlign="center">
                    EDIT PROJECT
                </Typography>
                <hr />
                <br />
                {project && (
                    <ProjectForm
                        handleSubmit={handleSubmit}
                        selectedProject={project}
                    />
                )}
        </div>
    );
};

export default EditProject;
