import React from 'react';
import { Pager, PageChangeEvent } from '@progress/kendo-react-data-tools';
import { InvitationActionType, InvitationContext } from './state';

const Pagination: React.FC = () => {
    const { state, dispatch } = React.useContext(InvitationContext);

    const handlePageChange = (event: PageChangeEvent) => {
        const { skip, take } = event;
        dispatch({
            type: InvitationActionType.SET_PAGE,
            payload: { page: (skip/take) + 1, pageSize: take },
        });
        dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH});
        const el = document.getElementById("main-content");
        if(el) {
            el.scrollTop = 0;
        }
    };

    //service starts from page 1 and pager starts from page 0

    return state.total>0 ? 
            <Pager
                skip={(state.page * state.pageSize) - 1 }
                take={state.pageSize}
                total={state.total}
                onPageChange={handlePageChange}
                responsive={true}
            />: <></>    
};

export default Pagination;
