import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as DisableIcon } from '../../../assets/icons/lock.svg';
import { AuthContext } from '../../Authentication/state';
import { UserRole } from '../../../models/User';

type ProjectTabs = 'All Users' | 'Disabled Users';

const UserWrapper: React.FC<{children: React.ReactChild}> = ({children}) => {
    const [active, setActive] = React.useState<ProjectTabs>();
    const location = useLocation();    
    const { user } = React.useContext(AuthContext).state;
    const isAdmin = user && (user.role === UserRole.Admin);

    React.useEffect(() => {
        if (location.pathname === '/users/disabled') setActive('Disabled Users');
        if (location.pathname === '/users') setActive('All Users');
    }, [location]);

    return (
        <div className="selection-wrapper">
            {isAdmin && <div className="tabs">
                <Link to="/users"><div
                    className={`selection ${active === 'All Users' ? 'active' : ''}`}
                >
                    <GridIcon />
                    <span>Users</span>
                </div>
                </Link>
                <Link to="/users/disabled">
                <div
                    className={`selection ${active === 'Disabled Users' ? 'active' : ''}`}
                >
                    <DisableIcon />
                    <span>Disabled Users</span>
                </div>
                </Link>
            </div>}
            <div className='representatives'>
                    {children}
            </div>
        </div>
    );
};
export default UserWrapper;
