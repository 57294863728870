import React from 'react';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as EventIcon } from '../../assets/icons/list.svg';
import './viewselector.scss';

export type CalendarState = 'Your Calendar' | 'Events Calendar';

const selections: { label: CalendarState; Icon: React.ReactNode }[] = [
    {
        label: 'Your Calendar',
        Icon: <CalendarIcon/>,
    },
    {
        label: 'Events Calendar',
        Icon: <EventIcon/>,
    }
];
const CalendarSelector: React.FC<{ active: CalendarState; onChange: (active: CalendarState) => void }> = ({
    active,
    onChange,
}) => {
    return (
        <div className="view-selection">
            {selections.map((el, index) => 
                <div
                    key={index}
                    className={`selection ${el.label === active ? 'active' : ''}`}
                    onClick={() => onChange(el.label)}
                    tabIndex={0}
                    onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) => e.key === 'Enter' && onChange(el.label)}
                >
                    {el.Icon}
                    <span>{el.label}</span>
                </div>
            )}
        </div>
    );
};

export default CalendarSelector;
