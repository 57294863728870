import User from '../models/User';
import instance from './instance';
import AdminUserCreationReq from '../models/AdminUserCreationReq';

function loginWithToken(): Promise<User> {
    return instance.get('auth/token-login').then((response) => {
        return response.data;
    });
}

function login(emailAddress: string, password: string): Promise<User> {
    return instance
        .post('auth/login', {
            emailAddress: emailAddress,
            password: password,
        })
        .then((response) => {
            console.log(response);
            return response.data;
        });
}

function createUser(req: AdminUserCreationReq): Promise<boolean> {
    return instance.post('auth/create-user', req).then((response) => {
        return response.data;
    });
}

function forgotPassword(email: string): Promise<string> {
    return instance.get(`auth/forgot-password?email=${encodeURIComponent(email)}`).then((response) => {
        return response.data;
    });
}

function resetPassword(token: string, email: string, password: string): Promise<string> {
    return instance
        .post(`auth/reset-forgotten-password`, {
            email: decodeURIComponent(email),
            token: token,
            password: password,
        })
        .then((response) => {
            return response.data;
        });
}

function checkTokenValidity(token: string, email: string):Promise<boolean> {
    return instance.post(`auth/check-token-validity`, {token: token, email: decodeURIComponent(email)}).then((response)=> {
        return response.data;
    })
}

function resetUserPassword(password: string): Promise<string> {
    return instance
        .post(`auth/reset-password`, {
            password: password,
        })
        .then((response) => {
            return response.data;
        });
}

function confirmEmail(token: string, userId: string): Promise<string> {
    return instance
        .post(`auth/confirm-email`, {
            token: token,
            id: userId,
        })
        .then((response) => {
            return response.data;
        });
}

function doesEmailExists(email: string): Promise<boolean>{
    return instance.get(`auth/email-exists?emailAddress=${encodeURIComponent(email)}`)
    .then((response) => {
        return response.data as boolean;
    })
}

export { loginWithToken, login, createUser, forgotPassword, resetPassword, resetUserPassword, confirmEmail, doesEmailExists, checkTokenValidity };
