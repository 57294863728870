import React from 'react';
import './errorinfo.scss';
import { ReactComponent as ErrorIcon } from '../../assets/icons/alert-circle.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-circle.svg';

type Props = {
    error: string;
    onClose?: () => void;
};
const ErrorInfo: React.FC<Props> = ({ error, onClose }) => {
    return (
        <>
            {error.length > 0 && (
                <div className="error-container">
                    <ErrorIcon />
                    <p className="text">{error}</p>
                    {onClose && <span
                        className="close-icon"
                        onClick={onClose}
                        tabIndex={0}
                        onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) => e.key === 'Enter' && onClose()}
                    >
                        <CloseIcon />
                    </span>}
                </div>
            )}
        </>
    );
};

export default ErrorInfo;
