import React, { useContext } from 'react';
import { AuthContext } from '../../containers/Authentication/state';
import { useWindowDimensions } from '../../utils';
import { getLeftNavItemsByRole, getTopNavItemsByRole, NavItem } from '../AppRouter/routeConfig';
import './authenticatedlayout.scss';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { UserRole } from '../../models/User';
import IconButton from '../IconButton';
import { ReactComponent as LeftArrow } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import Typography from '../Typography';
import Logo from '../../assets/images/logo-no-subtitle.png';
import LogoSmall from '../../assets/images/logo-small.png';
import LeftNavItems from './LeftNavItems';
import TopNavItems from './TopNavItems';
import AccountSection from './AccountSection';
import LeftHelperLinks from './LeftHelperItems';
import DataLoading from '../DataLoading';

enum NavBarState {
    CLOSED_MOBILE,
    OPEN_MINIFIED,
    OPEN_MAXIMIZED,
    OPEN_MOBILE,
}

type Props = {
    children: React.ReactChild;
};
const AuthenticatedLayout: React.FC<Props> = ({ children }) => {
    const [navState, setNavState] = React.useState<NavBarState>(NavBarState.OPEN_MAXIMIZED);
    const { width } = useWindowDimensions();
    const { user } = useContext(AuthContext).state;
    const [leftNavItems, setLeftNavItems] = React.useState<Array<NavItem>>([]);
    const [topNavItems, setTopNavItems] = React.useState<NavItem[]>([]);
    const [items, setItems] = React.useState<NavItem[]>([]);
    const [activeItem, setActiveItem] = React.useState('');
    const location = useLocation();

    React.useEffect(() => {
        if (width < 768) {
            setNavState(NavBarState.CLOSED_MOBILE);
        } else if (width > 768 && width < 1280) {
            setNavState(NavBarState.OPEN_MINIFIED);
        } else {
            setNavState(NavBarState.OPEN_MAXIMIZED);
        }
    }, [width]);

    React.useEffect(() => {
        setActiveItem('');
        const topNav = getTopNavItemsByRole(user?.role as UserRole);
        const leftNav = getLeftNavItemsByRole(user?.role as UserRole);
        const items = [...topNav, ...leftNav];
        setItems(items);
        setTopNavItems(topNav);
        setLeftNavItems(leftNav);
    }, []);

    React.useEffect(() => {
        const active = items.find((element) =>
            matchPath(location.pathname, {
                path: element.route,
            }),
        );
        if (active) {
            setActiveItem(active.label);
        }
    }, [items, location.pathname]);

    React.useEffect(()=> {        
        //handle scroll within content
        if(location.hash) {  
            const id = location.hash.split("#")[1];
                setTimeout(()=> {
                const mainContainer = document.getElementById("main-content");
                let el = document.getElementById(id);
                if(!el) {
                    el = document.getElementsByName(id)[0];
                }
                if(el && mainContainer) {
                    mainContainer.scrollTop = el.offsetTop;
                }
            }, 1000);       
            
        }
    }, [location.hash])

    const handleNavigation = () => {
        if (navState === NavBarState.OPEN_MOBILE) {
            setNavState(NavBarState.CLOSED_MOBILE);
        }
    };

    const getClassByNavState = (): string => {
        switch (navState) {
            case NavBarState.CLOSED_MOBILE:
                return 'nav-mobile-closed';
            case NavBarState.OPEN_MAXIMIZED:
                return 'nav-maximized';
            case NavBarState.OPEN_MINIFIED:
                return 'nav-minimized';
            case NavBarState.OPEN_MOBILE:
                return 'nav-mobile-open';
            default:
                return '';
        }
    };

    const getToggleIconByState = () => {
        switch (navState) {
            case NavBarState.OPEN_MAXIMIZED:
                return (
                    <IconButton
                        Icon={LeftArrow}
                        onClick={() => setNavState(NavBarState.OPEN_MINIFIED)}
                        color="white"
                    />
                );
            case NavBarState.OPEN_MINIFIED:
                return (
                    <IconButton
                        Icon={RightArrow}
                        onClick={() => setNavState(NavBarState.OPEN_MAXIMIZED)}
                        color="white"
                    />
                );
            case NavBarState.OPEN_MOBILE:
                return (
                    <IconButton
                        Icon={CloseIcon}
                        onClick={() => setNavState(NavBarState.CLOSED_MOBILE)}
                        color="white"
                    />
                );
            default:
                return <></>;
        }
    };
    return (
        <div className="layout-container">
            {navState === NavBarState.CLOSED_MOBILE && (
                <div className="mobile-hamburger">
                    <IconButton
                        Icon={MenuIcon}
                        tooltip="Toggle sidebar"
                        onClick={() => setNavState(NavBarState.OPEN_MOBILE)}
                    />
                </div>
            )}
            {leftNavItems.length>0 && <div className={`nav-bar-vertical-container ${getClassByNavState()}`}>
                <div className="nav-bar-toggle">{getToggleIconByState()}</div>
                <nav className="navbar">
                    <LeftNavItems
                        items={leftNavItems}
                        handleNavigation={handleNavigation}
                        activeItem={activeItem}
                        showFullContent={
                            navState === NavBarState.OPEN_MAXIMIZED || navState === NavBarState.OPEN_MOBILE
                        }
                    />
                    {width <= 768 && <LeftHelperLinks items={topNavItems} handleNavigation={handleNavigation} />}
                </nav>
                <Link to={user?.role===UserRole.Synner? "/dashboard": "/campaigns"}><div className="logo-container">
                    <img src={navState === NavBarState.OPEN_MINIFIED ? LogoSmall : Logo} alt="Logo" className="logo" />
                </div></Link>
            </div>}
            <div className="page-container">
                <div className="action-bar-container">
                    <div className="action-bar-content">
                        {width > 768 ? (
                            <Typography variant="subtitle" weight={500}>
                                {activeItem}
                            </Typography>
                        ) : (
                            <div className="logo-top-mobile">
                                <img src={Logo} alt="Logo" className="logo" />
                            </div>
                        )}
                        <div className="action-bar-items">
                            {width > 768 && <TopNavItems items={topNavItems} handleNavigation={handleNavigation} />}
                            <AccountSection />
                        </div>
                    </div>
                </div>

                <DataLoading>
                    <div className={"page-content " + user?.role} id="main-content">
                        <div className="container"><a id="top"></a>{children}</div>
                    </div>
                </DataLoading>
            </div>
        </div>
    );
};

export default AuthenticatedLayout;
