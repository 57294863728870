import {process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridFilterCellProps,
    GridRowProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GridDropDownFilter from '../../../components/GridDropDownFilter';
import Tag from '../../../components/Tag';
import {  UserReportGrid, UserReportStatus } from '../../../models/Report';
import { formatDate, getListFromEnum } from '../../../utils';
import { getColorByReportStatus } from '../../../utils/getColorByStatus';
import pagerSettings from '../../../utils/pagerSettings';
import CampaignNameCell from '../../Campaigns/CampaignList/CampaignNameCell';

type Props = {
    data: UserReportGrid[];
    getData: () => void;
};

const FullGridList: React.FC<Props> = ({ data }) => { 
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: UserReportStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `report--${getColorByReportStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children  as React.ReactNode);
    };

    const statuses = getListFromEnum(Object.values(UserReportStatus));

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const Status = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Tag
                    label={UserReportStatus[props.dataItem.status]}
                    color={getColorByReportStatus(props.dataItem.status)}
                />
            </td>
        );
    };

    const DateCell = (props: GridCellProps) => {
        return <td>{props.dataItem[props.field as string]? formatDate(props.dataItem[props.field as string]): ''}</td>;
    };

    return (
        <Grid
        style={{ height: '100%' }}  
        sortable={true}
        pageable={pagerSettings}
        filterable={true}
        data={process(data, dataState)}
        {...dataState}
        onDataStateChange={(e:GridDataStateChangeEvent) => {
            history.replace({
                ...history.location,
                state: e.dataState
            });
            setDataState(e.dataState);
          }}
            rowRender={rowRender}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => props.dataItem.reportId ? history.push(`/reports/${props.dataItem.reportId}`): history.push({
                pathname: '/report/create-report',
                state: { campaignId: props.dataItem.campaignId, surveyId: props.dataItem.surveyId },
            })}
        >
            <GridColumn
                field="displayName"
                title="Campaign"
                cell={(props: GridCellProps) => (
                    <td
                        onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/campaigns/${props.dataItem.campaignId}/detail`);
                        }}
                    >
                        <CampaignNameCell {...props} />
                    </td>
                )}
            />
            <GridColumn field="surveyName" title="Title" />
            <GridColumn field="dueDate" title="Due Date" cell={DateCell} filterable={false} />
            <GridColumn field="dateUpdated" title="Date Updated" cell={DateCell} filterable={false} />
            <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />
        </Grid>
    );
};

export default FullGridList;
