import { process, State } from '@progress/kendo-data-query';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridFilterCellProps,
    GridRowProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GridDropDownFilter from '../../../components/GridDropDownFilter';
import Tag from '../../../components/Tag';
import Campaign, { CampaignStatus } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import { formatDate, getListFromEnum } from '../../../utils';
import { getColorByCampaignStatus } from '../../../utils/getColorByStatus';
import pagerSettings from '../../../utils/pagerSettings';
import { AuthContext } from '../../Authentication/state';
import ProjectAvatar from '../../Projects/ProjectAvatar';
import CampaignActions from './CampaignActions';
import CampaignAddressCell from './CampaignAddressCell';
import CampaignNameCell from './CampaignNameCell';

type Props = {
    data: Campaign[];
    getData: () => void;
};

const FullGridList: React.FC<Props> = ({ data }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: CampaignStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = {
            className: isAdminManager
                ? `campaign--${getColorByCampaignStatus(status)}`
                : // eslint-disable-next-line react/prop-types
                props.dataItem.isInCampaign
                ? `campaign--participating`
                : ``,
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const statuses = getListFromEnum(Object.values(CampaignStatus)).filter((el) => el.value !== CampaignStatus.Archived)
       

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const Status = (props: GridCellProps) => {
        return (
            <td className="clickable-grid-custom-item">
                <Tag
                    label={CampaignStatus[props.dataItem.status]}
                    color={getColorByCampaignStatus(props.dataItem.status)}
                />
            </td>
        );
    };

    const StipendCell = (props: GridCellProps) => {
        return(
            <td>${Number.parseFloat(props.dataItem[props.field as string]).toFixed(2)}</td>
        )
    }

    return (
        <Grid
            style={{
                height: '100%',
            }}
            sortable={true}
            pageable={pagerSettings}
            filterable={true}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/campaigns/${props.dataItem.id}/detail`)}
            rowRender={rowRender}
        >
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn title="Name" field="displayName" cell={(props)=><td className="clickable-grid-custom-item"><CampaignNameCell{...props}/></td>} />
            <GridColumn title="Location" cell={CampaignAddressCell} filterable={false} sortable={false} />
            <GridColumn field="startDate" title="Start Date" cell={DateCell} filter="date" format="{0:s}" filterable={false}/>
            <GridColumn field="endDate" title="End Date" cell={DateCell} filter="date" format="{0:s}" filterable={false}/>
            {isAdminManager && history.location.pathname !== "/campaigns/archived" && <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />}
            {!isAdminManager && <GridColumn field="expectedSynners" title="Positions Available" filterable={false} />}
            {!isAdminManager && <GridColumn field="stipend" cell={StipendCell} title="Stipend" filterable={false} sortable={false}/>}
            {isAdminManager && (
                <GridColumn
                    cell={(props: GridCellProps) => (
                        <td className="grid-actions">
                            <CampaignActions
                                campaign={props.dataItem as Campaign}
                            />
                        </td>
                    )}
                    width="180px"
                    filterable={false}
                    sortable={false}
                />
            )}
        </Grid>
    );
};

export default FullGridList;
