import React from 'react';
import Typography from '../../../components/Typography';
import Campaign from '../../../models/Campaign';
import { AuthContext } from '../../Authentication/state';
import { UserRole } from '../../../models/User';
import CampaignSurveyManager from './CampaignSurveyManager';
import { formatDate, formatLocation } from '../../../utils';
import { ProjectType } from '../../../models/Project';
import ProjectImage from '../../Projects/ProjectImage';

const CampaignInfo: React.FC<{ campaign: Campaign;}> = ({
    campaign,
}) => {
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);

    return (
        <>
            <div className='image-aligned-grid'>
                <ProjectImage name={campaign.name} photoUrl={campaign.photoUrl} />
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" weight={500}>
                            {campaign.type === ProjectType['Day-of-Show'] ||
                            campaign.type === ProjectType['Competitive Event']
                                ? 'Event Date'
                                : 'Start Date'}
                        </Typography>
                        <Typography variant="body">{formatDate(campaign.startDate)}</Typography>
                    </div>
                    {campaign.type !== ProjectType['Day-of-Show'] &&
                        campaign.type !== ProjectType['Competitive Event'] && (
                            <div>
                                <Typography variant="body" weight={500}>
                                    End Date
                                </Typography>
                                <Typography variant="body">{formatDate(campaign.endDate)}</Typography>
                            </div>
                        )}
                    <div>
                        <Typography variant="body" weight={500}>
                            Stipend
                        </Typography>
                        <Typography variant="body">${Number.parseFloat(campaign.stipend).toFixed(2)}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Positions Available
                        </Typography>
                        <Typography variant="body">{campaign.expectedSynners}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Location
                        </Typography>
                        <Typography variant="body">
                            {campaign.venue && (
                                <>
                                    <b>{campaign.venue}</b>
                                    <br />
                                </>
                            )}
                            {formatLocation(
                                campaign.addressLine1,
                                campaign.addressLine2,
                                campaign.city,
                                campaign.state,
                                campaign.country,
                                campaign.zipCode,
                            )}
                        </Typography>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <Typography variant="body" weight={500}>
                    Description
                </Typography>
                <Typography variant="body">{campaign.description}</Typography>
            </div>
            <br />
            {isAdminManager && (
                <CampaignSurveyManager
                    id={campaign.id as string}
                    surveys={campaign.surveys ? campaign.surveys : []}
                    status={campaign.status}
                />
            )}
        </>
    );
};

export default CampaignInfo;
