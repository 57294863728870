import React from 'react';
import Typography from '../../components/Typography';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UserService } from '../../api';
import Profile from '../../models/Profile';
import { AuthContext } from '../Authentication/state';
import InputSelect from '../../components/InputSelect';
import { UserRole } from '../../models/User';
import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import Card, { CardActionBar, CardActionHeader } from '../../components/Card';
import Tag from '../../components/Tag';
import TagManager from './TagManager';
import Socials from '../../components/Socials';
import { formatDate, formatDateOnly, formatLocation, formatPhoneNumber, 
    getUserRoles, } from '../../utils';
import { DataLoadingContext } from '../../components/DataLoading';
import RecentCompletedCampaigns from './RecentCompletedCampaigns';
import ErrorInfo from '../../components/ErrorInfo';

const RepresentativeDetail: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [rep, setRep] = React.useState<Profile | null>(null);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { user } = React.useContext(AuthContext).state;
    const isAdmin = user && user.role === UserRole.Admin;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    const userRoles = getUserRoles();

    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    //if no props sent get representative info based on route
    React.useEffect(() => {
        getData();
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        UserService.getUser(id)
            .then((response) => {
                setRep(response);
            })
            .finally(() => toggleLoading(false));
    };

    const goBack = () => {
        history.goBack();
    };

    const handleChangeRole = (id: string, newRole: string) => {
        UserService.changeRole(id, newRole).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `Synner role was successfully updated.`,
                    status: 'success',
                    autoClose: true,
                },
            });            
            history.push('/users');
        });
    };

    const handleUpdateTags = (tags: string[]) => {
        UserService.updateTags(tags, rep?.id as string).then(() => {
            getData();
        });
    };

    const checkAvailable = (value: string | null | undefined): boolean => {
        if (value && value.length > 0) return true;
        return false;
    };

    const isValidDate = (date: string) => {
        const systemDefault = '0001-01-01T00:00:00';
        return date !== systemDefault;
    };

    const isRepSynnerTrainee = ()=> {
        return rep && (rep.role === UserRole.Synner || rep.role === UserRole.Trainee)
    }

    return (
        <div className="representatives">
            <div className="representative-detail">
                {rep && (
                    <Card>
                        <CardActionHeader>
                            <Typography variant="title" color="secondary">
                                {`${rep.fname} ${rep.lname}`}
                            </Typography>
                            <>
                                {isAdmin && (
                                    <div style={{ minWidth: '140px' }}>
                                        <Typography variant="body" weight={500}>
                                            Change Role
                                        </Typography>
                                        <InputSelect
                                            placeholder="Change Synner Role"
                                            onChange={(_name, value) => handleChangeRole(rep.id, value as string)}
                                            options={userRoles}
                                            name="roles"
                                            value={rep.role}
                                        />
                                    </div>
                                )}
                            </>
                        </CardActionHeader>
                        <>{rep.isDeleted && rep.comments && <ErrorInfo error={rep.comments}/>}<br/></>
                        <Avatar source={rep.photoUrl} name={rep.fname} size="large" />
                        <>
                            {!rep.viewableBySynners && (
                                <Typography variant="subtitle" textAlign="center" color="grey">
                                    Private Account
                                </Typography>
                            )}
                            {rep.isAlumni && <Typography variant='subtitle' textAlign='center' weight={300}>ALUMNI</Typography>}
                            {(isAdminManager || rep.viewableBySynners) && (
                                <div className="details">
                                    {isRepSynnerTrainee() && checkAvailable(rep.totalCompletedCampaigns.toString()) && (
                                        <Typography variant="subtitle" color="info" textAlign="center">
                                            {rep.totalCompletedCampaigns} campaign{rep.totalCompletedCampaigns>1?'s':''} completed
                                        </Typography>
                                    )}
                                    {checkAvailable(rep.lastLoginAt) && (
                                        <Typography variant="subtitle" textAlign="center">
                                            Last Login: {formatDate(rep.lastLoginAt)}
                                        </Typography>
                                    )}
                                    <br />
                                    {checkAvailable(rep.fname) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Name
                                            </Typography>
                                            <Typography variant="body">{`${rep.fname} ${rep.lname}`}</Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.emailAddress) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Email
                                            </Typography>
                                            <Typography variant="body">{rep.emailAddress}</Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.workEmailAddress) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Work Email
                                            </Typography>
                                            <Typography variant="body">{rep.workEmailAddress}</Typography>
                                        </div>
                                    )}
                                    
                                    {checkAvailable(rep.dateRegistered.toString()) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Member Since
                                            </Typography>
                                            <Typography variant="body">{formatDate(rep.dateRegistered)}</Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.dateOfBirth) && isValidDate(rep.dateOfBirth) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Date of Birth
                                            </Typography>
                                            <Typography variant="body">{formatDateOnly(rep.dateOfBirth)}</Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.phoneNumber) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Mobile Phone
                                            </Typography>
                                            <Typography variant="body">{formatPhoneNumber(rep.phoneNumber, true)}</Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.homePhoneNumber) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Home Phone
                                            </Typography>
                                            <Typography variant="body">
                                                {formatPhoneNumber(rep.homePhoneNumber)}
                                            </Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.workPhoneNumber) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Work Phone
                                            </Typography>
                                            <Typography variant="body">
                                                {formatPhoneNumber(rep.workPhoneNumber)}
                                            </Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.preferredPronouns) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Pronouns
                                            </Typography>
                                            <Typography variant="body">
                                                {rep.preferredPronouns && rep.preferredPronouns.length > 0
                                                    ? rep.preferredPronouns
                                                    : 'N/A'}
                                            </Typography>
                                        </div>
                                    )}
                                    {checkAvailable(rep.addressLine1) && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Address
                                            </Typography>
                                            <Typography variant="body">
                                                {formatLocation(
                                                    rep.addressLine1,
                                                    rep.addressLine2,
                                                    rep.city,
                                                    rep.state,
                                                    rep.country,
                                                    rep.zipCode,
                                                )}
                                            </Typography>
                                        </div>
                                    )}
                                    {rep.socialMedias.length > 0 && (
                                        <div className="details-item">
                                            <Typography variant="body" weight={500}>
                                                Social Medias
                                            </Typography>
                                            <Socials socials={rep.socialMedias} />
                                        </div>
                                    )}
                                    {rep.tags && rep.tags.length > 0 && (
                                        <>
                                            <div className="details-item">
                                                <Typography variant="body" weight={500}>
                                                    Tags
                                                </Typography>
                                                <div className="tag-list">
                                                    {rep.tags.map((el: string, index: number) => (
                                                        <Tag label={el} color="secondary" key={index} />
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {isAdminManager && isRepSynnerTrainee() && (
                                <TagManager synner={rep} handleUpdateTags={handleUpdateTags} />
                            )}
                            {isAdminManager && isRepSynnerTrainee() && <RecentCompletedCampaigns id={rep.id} />}
                            {rep.applicantId && 
                                <div style={{marginTop: '12px'}}>           
                                <Link to={`/applicants/${rep.applicantId}`}>
                                    <Typography variant="caption" textAlign="right" color="info">
                                        View application
                                    </Typography>
                                </Link>
                                </div> 
                            }
                        </>
                        <CardActionBar>
                            <Button onClick={goBack} color="secondary">
                                Close
                            </Button>
                        </CardActionBar>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default RepresentativeDetail;
