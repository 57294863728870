import React from 'react';
import Socials from '../../../components/Socials';
import Typography from '../../../components/Typography';
import Project, { ProjectStatus, ProjectType } from '../../../models/Project';
import ProjectImage from '../ProjectImage';

const ProjectInfo: React.FC<{ project: Project }> = ({ project }) => {
    return (
        <>
            <div className="image-aligned-grid">
                <div>
                    <ProjectImage name={project.name} photoUrl={project.photoUrl} />                    
                    {project.socialMedias.length > 0 && (
                        <div>
                            <Socials socials={project.socialMedias} showFollowers={false} />
                        </div>
                    )}
                </div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body" weight={500}>
                            Name
                        </Typography>
                        <Typography variant="body">{project.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Project Status
                        </Typography>
                        <Typography variant="body">{ProjectStatus[project.status]}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Project Type
                        </Typography>
                        <Typography variant="body">{ProjectType[project.type]}</Typography>
                    </div>
                    {project.clientName?.length>0 && <div>
                        <Typography variant="body" weight={500}>
                            Client
                        </Typography>
                        <Typography variant="body">{project.clientName}</Typography>
                    </div>}
                    <div>
                        <Typography variant="body" weight={500}>
                            Website
                        </Typography>
                        <Typography variant="body">
                            {project.website && project.website.length > 0 ? (
                                <a
                                    href={project.website.includes('://') ? project.website : `//${project.website}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {project.website.slice(0, 40)}
                                    {project.website.length > 40 ? '...' : ''}
                                </a>
                            ) : (
                                'N/A'
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Manager Name
                        </Typography>
                        <Typography variant="body">{project.managerName}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Manager Email
                        </Typography>
                        <Typography variant="body">{project.managerEmail}</Typography>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <Typography variant="body" weight={500}>
                    Description
                </Typography>
                <Typography variant="body">{project.description}</Typography>
            </div>
            <br />
            <div>
                <Typography variant="body" weight={500}>
                    Notes
                </Typography>
                <Typography variant="body">{project.staffNote}</Typography>
            </div>
            <br />
            <div>
                <Typography variant="body" weight={500}>
                    Comments and updates
                </Typography>
                <Typography variant="body">{project.misc}</Typography>
            </div>
        </>
    );
};

export default ProjectInfo;
