import React from 'react';
import './tag.scss';

type Props= {
    color: string,
    label: string
}

const Tag:React.FC<Props>=({color, label})=> {
    return <div className={`tag bg-${color}`}>{label}</div>
}

export default Tag;