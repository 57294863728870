import React from 'react';
import Typography from '../../../components/Typography';
import { SurveyCheckboxData, SurveyInputData, SurveyTextAreaData, SurveyRadioData } from '../../../models/Survey';

type Props = {
    data: SurveyRadioData | SurveyInputData | SurveyCheckboxData | SurveyTextAreaData;
    value: string | undefined;
};
const FieldDetail: React.FC<Props> = ({ data, value }) => {
    return (
        <div>
            <Typography variant="subtitle" color="secondary">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <br/>
            <div className='answer'><Typography variant="body">{value}</Typography></div>
        </div>
    );
};

export default FieldDetail;
