import React from 'react';
import Typography from '../../../components/Typography';
import Survey from '../../../models/Survey';
import ElementDetail from './ElementDetail';
import Preview from './Preview';
import { initialState, SurveyBuilderActionType, surveyBuilderReducer, surveyBuilderContext } from './state';
import SurveyActions from './SurveyActions';
import SurveyIntro from './SurveyIntro';
import Tools from './Tools';

const SurveyBuilder: React.FC<{survey?: Survey}> = ({survey}) => {
    const [state, dispatch] = React.useReducer(surveyBuilderReducer, initialState);

    React.useEffect(() => {
        if (survey) {
                dispatch({
                    type: SurveyBuilderActionType.SET_SURVEY,
                    payload: survey,
                });
        }
    }, [survey]);    

    return (
        <surveyBuilderContext.Provider value={{ state, dispatch }}>
            <Typography variant="title" textAlign='center'>REPORT BUILDER</Typography>
            <br/>
            <SurveyIntro />
            <div className="builder">
                <Tools />
                <Preview />
                <div>
                    <ElementDetail />
                    <SurveyActions/>
                </div>
            </div>
        </surveyBuilderContext.Provider>
    );
};

export default SurveyBuilder;
