import React from 'react';
import { UserService } from '../../../api';
import Button from '../../../components/Button';
import InputMultiSelect from '../../../components/InputMultiSelect';
import InputRange from '../../../components/InputRange';
import InputSelect from '../../../components/InputSelect';
import Typography from '../../../components/Typography';
import { SynnerTag } from '../../../models/Profile';
import { ProjectType } from '../../../models/Project';
import { getListFromEnum, useDebounse } from '../../../utils';
import { InvitationActionType, InvitationContext } from './state';

const SearchFilters:React.FC<{exportToCSV: ()=>void}>=({exportToCSV})=> {
    const {state, dispatch} = React.useContext(InvitationContext);
    const [availableTags, setAvailableTags] = React.useState<SynnerTag[]>([]);
    const debouncedDistance= useDebounse(state.distance, 1500);

    const {distance, tags, workType} = state;

    const ProjectTypeList = getListFromEnum(Object.values(ProjectType));

    React.useEffect(()=> {
        UserService.getTags().then((response) => {
            setAvailableTags(response);
        });
    }, [])

    React.useEffect(()=> {
        dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH});
    }, [debouncedDistance])

    const handleDistance = (_name: string, value: string) => {
        dispatch({
            type: InvitationActionType.SET_DISTANCE,
            payload: parseInt(value),
        });
    };

    const handleTagChange=(_name: string, value: Array<string>)=> {
        dispatch({
            type: InvitationActionType.SET_TAGS,
            payload: value,
        });
        dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH})
    }

    const handleWorkTypeChange=(_name: string, value: unknown)=> {
        dispatch({
            type: InvitationActionType.SET_WORK_TYPE,
            payload: value as unknown as ProjectType,
        });
        dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH})
    }

    const handleClearFilter=()=> {
        dispatch({
            type: InvitationActionType.CLEAR_FILTER
        });
        dispatch({type: InvitationActionType.TOGGLE_DATA_FETCH})
    }

    return <div className='search-filters'>
             <div className='form-grid-half'>
                    <div>
                        <Typography variant="subtitle">Distance</Typography>
                        <Typography variant="caption">Search synners by distance from the campaign location</Typography>
                        <br/>
                        <InputRange
                            onChange={handleDistance}
                            max={500}
                            min={0}
                            name="distance"
                            value={distance ? distance:0}
                        />
                    </div>
                    <div>
                        <Typography variant="subtitle">Tags</Typography>
                        <Typography variant="caption">Search synners by tags</Typography>
                        <InputMultiSelect
                        options={availableTags}
                        value={tags}
                        name="tags"
                        placeholder="select tags"
                        onChange={handleTagChange}
                    />
                    </div>
                    <div>
                        <Typography variant="subtitle">Activity history</Typography>
                        <Typography variant="caption">Search synners by the type of campaigns they have completed</Typography>
                        <InputSelect
                            options={ProjectTypeList}
                            value={workType?workType:null}
                            name="tags"
                            placeholder="select activity type"
                            onChange={handleWorkTypeChange}
                    />
                    </div>
                    <div className='search-actions'>                        
                            {state.total>0 && <Button onClick={exportToCSV} color="success">Export</Button>}
                            <Button onClick={handleClearFilter}>Clear Filters</Button>
                    </div>
             </div>
        </div>
}

export default SearchFilters;