import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import './calendar.scss';
import CalendarEvent from '../../models/CalendarEvent';
import { CalendarService } from '../../api';
import { useHistory } from 'react-router-dom';
import { DataLoadingContext } from '../../components/DataLoading';
import { AuthContext } from '../Authentication/state';
import { UserRole } from '../../models/User';
import CalendarSelector, { CalendarState } from '../../components/ViewSelector/CalendarSelector';
import ProjectTypeLegend from './ProjectTypeLegend';

const Calendar: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calanderRef = React.useRef<any>(null);
    const [events, setEvents] = React.useState<CalendarEvent[]>([]);
    const [active, setActive] = React.useState<CalendarState>(isAdminManager ? 'Events Calendar' : 'Your Calendar');
    const history = useHistory();

    React.useEffect(() => {
        getData();
    }, [active]);

    const getData = () => {
        toggleLoading(true);
        const request = determineRequest();
        request
            .then((response) => {
                setEvents(response);
            })
            .finally(() => toggleLoading(false));
    };

    const determineRequest = () => {
        switch (active) {
            case 'Your Calendar':
                return CalendarService.getMyCalendarEvents();
            case 'Events Calendar':
                return CalendarService.getCalendarEvents();
            default:
                return CalendarService.getCalendarEvents();
        }
    };

    const handleActiveChange=(el: CalendarState) => {
        setActive(el);
        setEvents([]);
        if(el === "Your Calendar") calanderRef.current.getApi().changeView("dayGridMonth");
        if(el === "Events Calendar") calanderRef.current.getApi().changeView("listMonth");
    }

    const handleChange = (value: string) => {
        if (calanderRef.current) {
            if(value==="today") {
                calanderRef.current.getApi().today()
            }
            else{
                calanderRef.current.getApi().changeView(value);
            }
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (info: any) => {
        history.push(`/campaigns/${info.event.id}/detail`);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventContent=(e:any)=> {
        const temp = e.event.extendedProps;
        return `${temp.projectName} - ${e.event.title}`
    }

    return (
        <div className="calendar-container">
            {!isAdminManager && <CalendarSelector active={active} onChange={handleActiveChange} />}
            <ProjectTypeLegend/>
            <FullCalendar
                plugins={[listPlugin, dayGridPlugin]}
                initialView={active === "Your Calendar"?"dayGridMonth":"listMonth"}
                headerToolbar={{
                    left: 'prev,next,today',
                    center: 'title',
                    right: active === "Your Calendar"? 'listDay,listWeek,dayGridMonth,listYear' : 'listDay,listWeek,listMonth,listYear',
                }}
                customButtons={{
                    listDay: {
                        text: 'Day',
                        click: () => handleChange('listDay'),
                    },
                    listWeek: {
                        text: 'Week',
                        click: () => handleChange('listWeek'),
                    },
                    dayGridMonth: {
                        text: 'Month',
                        click: () => handleChange("dayGridMonth"),
                    },
                    listMonth: {
                        text: 'Month',
                        click: () => handleChange("listMonth"),
                    },
                    listYear: {
                        text: 'Year',
                        click: () => handleChange('listYear'),
                    },
                    today: {
                        text: 'Today',
                        click:()=> handleChange('today'),
                    }
                }}
                eventClick={handleClick}
                events={events}
                allDayText=""
                eventContent={eventContent}
                ref={calanderRef}
            />
        </div>
    );
};

export default Calendar;
