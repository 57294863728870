import React from 'react';
import './radio.scss';

type Props = {
    onChange: () => void;
    disabled?: boolean;
    label: string;
    checked: boolean;
    required?:boolean;
};

const Radio: React.FC<Props> = ({ onChange, checked, disabled, label, required}) => {
    return (
        <label className="radio-container">
            <input type="radio" checked={checked} onChange={onChange} disabled={disabled} required={required}/>
            <span
                className="checkmark"
                tabIndex={0}
                onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) => e.key === 'Enter' && onChange()}
            ></span>
            <p>{label}</p>
        </label>
    );
};

export default Radio;
