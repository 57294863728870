import { ProjectStatus } from "./Project";

export enum ActivityStatus {
    Invited = 0,
    Active = 1,
    Applied = 2,
    Declined = 3,
    Completed = 4,
    Incomplete = 5
}

export type SynnerActivity ={
    type: ProjectStatus,
    campaign: string,
    project: string,
    updatedAt: string
}

export type ActivityStats = {
    totalCompletedCampaigns: number,
    totalInvolvedCampaigns: number
}

