import React from 'react';
import { ReportService } from '../../../api';
import ErrorInfo from '../../../components/ErrorInfo';
import Typography from '../../../components/Typography';
import Upload from '../../../components/Upload';
import { SurveyUploadData } from '../../../models/Survey';
import { urlIsValid } from '../../../utils';

type Props = {
    value?: string;
    data: SurveyUploadData;
    handleChange: (name: string, value: string) => void;
};

const SurveyUpload: React.FC<Props> = ({ value, data, handleChange }) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleUpload = (file: File | File[]) => {
        setLoading(true);
        ReportService.uploadReportFiles(file)
            .then((response) => {
                handleChange(data.name, response);
                setError('');
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };
    return (
        <div>
            <Typography variant="body">
                {data.title} {data.required && <span className="color-error">*</span>}
            </Typography>
            {data.description && <Typography variant="caption">{data.description}</Typography>}
            <br />
            {error.length > 0 && (
                <>
                    <ErrorInfo error={error} onClose={() => setError('')} />
                    <br />
                </>
            )}
            <Upload
                maxSize={102400}
                isMultiple={data.isMultiple ? data.isMultiple : false}
                handleUpload={handleUpload}
                loading={loading}
            />
            {value && urlIsValid(value) && (
                <a href={value} target="_blank" rel="noreferrer" className='color-info'>
                    Click here to view file
                </a>
            )}
        </div>
    );
};

export default SurveyUpload;
