import React from 'react';
import IconButton from '../../../components/IconButton';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/x-circle.svg';
import { SurveyBuilderActionType, surveyBuilderContext } from './state';

const PreviewActions: React.FC<{ index: number }> = ({ index }) => {
    const dispatch = React.useContext(surveyBuilderContext).dispatch;

    const handleRemove = () => {
        dispatch({
            type: SurveyBuilderActionType.REMOVE_SURVEY_ELEMENT,
            payload: index,
        });
    };

    const handleSelect = () => {
        dispatch({
            type: SurveyBuilderActionType.SELECT_SURVEY_ELEMENT,
            payload: index,
        });
    };
    return (
        <div className="preview-actions">
            <IconButton Icon={EditIcon} onClick={handleSelect} />
            <IconButton Icon={RemoveIcon} onClick={handleRemove} />
        </div>
    );
};
export default PreviewActions;
