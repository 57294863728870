import NotificationModel from '../NotificationModel';

export enum NotificationActionType {
    OPEN,
    CLOSE,
}

//actions
export type NotificationAction =
    | { type: NotificationActionType.OPEN; payload: NotificationModel }
    | { type: NotificationActionType.CLOSE };
