import React from 'react';
import Select, { MultiValue } from 'react-select';
import { CampaignService, SurveyService } from '../../../api';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { CampaignStatus, CampaignSurvey } from '../../../models/Campaign';

const CampaignSurveyManager: React.FC<{ surveys: CampaignSurvey[]; id: string; status: CampaignStatus }> = ({
    surveys,
    id,
    status,
}) => {
    const [availableSurveys, setAvailableSurveys] = React.useState<CampaignSurvey[]>([]);
    const [campaignSurvey, setCampaignSurvey] = React.useState<CampaignSurvey[]>(surveys);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        SurveyService.getAvailableSurveyList().then((response) => {
            setAvailableSurveys(response);
        });
    }, [ ]);

    const handleChange = (options: MultiValue<CampaignSurvey>): void => {
        setCampaignSurvey(options as CampaignSurvey[]);
    };

    const handleSubmit = () => {
        setLoading(true);
        CampaignService.updateCampaignSurveys(id, campaignSurvey).finally(() => setLoading(false));
    };

    return (
        <div className='campaign-survey'>
            <br />
            <Typography variant="body" weight={500}>
                Report Templates
            </Typography>
            {(status === CampaignStatus.Ready) ? (
                <>
                    <Select
                        onChange={handleChange}
                        options={availableSurveys}
                        value={campaignSurvey}
                        placeholder="select templates"
                        getOptionValue={(opt)=>opt.id}
                        getOptionLabel={(opt)=>opt.title}
                        isMulti={true}
                    />
                    <Button onClick={handleSubmit} loading={loading} disabled={campaignSurvey.length === 0}>
                        Save
                    </Button>
                </>
            ) : (
                <Typography variant="body">{surveys.length>0 ? surveys.map((el)=><><span key={el.id}>{el.title}</span><br/></>): "no templates are associted with the campaign"}</Typography>
            )}
            <br />
        </div>
    );
};

export default CampaignSurveyManager;
