import React from 'react';
import { AlertDialogActionType, AlertDialogAction } from './actions';
import AlertDialogModel from '../AlertDialogModel';

export const alertDialogInitialState: AlertDialogModel = {
    isOpen: false,
    title: '',
    description: ''
};

//reducers
export function alertDialogReducer(state: AlertDialogModel, action: AlertDialogAction): AlertDialogModel {
    switch (action.type) {
        case AlertDialogActionType.OPEN:
            return {...action.payload , isOpen: true };
        case AlertDialogActionType.CLOSE:
            return {
                ...alertDialogInitialState,
            };
        default:
            return state;
    }
}

export const AlertDialogContext = React.createContext<{
    alertDialogState: AlertDialogModel;
    alertDialogDispatch: React.Dispatch<AlertDialogAction>;
}>({
    alertDialogState: alertDialogInitialState,
    alertDialogDispatch: () => undefined,
});
