import PaymentInvoice, { InvoiceRequest } from '../models/Payment';
import instance from './instance';

function getFinancials(showArchived: boolean): Promise<PaymentInvoice[]> {
    const reqUrl = showArchived ? `financials/invoices?completed=true` : 'financials/invoices';
    return instance.get(reqUrl).then((response) => {
        return response.data;
    });
}

function completeInvoices(invoices: string[]): Promise<boolean> {
    return instance.put(`financials/invoices`, invoices).then((response) => {
        return response.data;
    });
}

function createInvoice(req: InvoiceRequest): Promise<boolean> {
    return instance.post(`financials/invoice`, req).then((response) => {
        return response.data;
    });
}

function updateInvoice(req: InvoiceRequest): Promise<boolean> {
    return instance.put(`financials/invoice`, req).then((response) => {
        return response.data;
    });
}

function deleteInvoice(Id: string): Promise<boolean> {
    return instance.delete(`financials/invoices/${Id}`).then((response) => {
        return response.data;
    });
}

export { getFinancials, completeInvoices, createInvoice, deleteInvoice, updateInvoice };
