import React from 'react';
import Img from '../../components/Img';

type Props = {
    name: string;
    photoUrl?: string;
};

const ProjectImage: React.FC<Props> = ({ name, photoUrl }) => {
    return (
        <div
            style={{
                maxWidth: '360px',
                padding: '12px',
                boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
            }}
        >
            <Img
                src={
                    photoUrl ? photoUrl : `https://via.placeholder.com/360?text=Project Image`
                }
                alt={name}
                style={{ width: '100%' }}
            />
        </div>
    );
};

export default ProjectImage;
