import React from 'react';
import LoaderGif from '../../assets/images/loader.gif';
import './pageLoader.scss';

const PageLoader: React.FC = () => {
    return (
        <section className="page-loader">
            <div className="loader-container">
                <img src={LoaderGif} alt="loader" />
            </div>
        </section>
    );
};
export default PageLoader;
