import React from 'react';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import Button from '../../components/Button';
import InputSelect from '../../components/InputSelect';
import Typography from '../../components/Typography';
import Content, { ContentStatus, ContentType } from '../../models/Content';
import { getListFromEnum } from '../../utils';

type Props = {
    handleChange: (name: string, value: string | boolean | number) => void;
    handleSubmit: () => void;
    handleDelete?: () => void;
    content: Content;
    loading: boolean;
};

const ContentActions: React.FC<Props> = ({ handleChange, handleDelete, content, handleSubmit, loading }) => {
    const featuredOptions = [
        { label: 'Featured', value: 'true' },
        { label: 'Non-Featured', value: 'false' },
    ];

    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleOpenDeleteAlert = () => {
        if (handleDelete) {
            alertDialogDispatch({
                type: AlertDialogActionType.OPEN,
                payload: {
                    title: `Delete ${ContentType[content.type]}`,
                    description: `Are you sure you want to delete ${content.title}?`,
                    handleConfirm: () => handleDelete(),
                },
            });
        }
    };

    const handleFeaturedChange = (name: string, value: string | number) => {
        handleChange(name, value === 'true' ? true : false);
    };
    const handleStatusChange = (name: string, value: string | number) => {
        handleChange(name, value as ContentStatus);
    };

    const contentStatuses = getListFromEnum(Object.values(ContentStatus));

    return (
        <div className="content-actions">
            <Typography variant="body" color="primary">
                Status
            </Typography>
            <InputSelect
                options={contentStatuses}
                onChange={handleStatusChange}
                name="status"
                value={content.status}
                placeholder="select Status"
            />
            <Typography variant="body" color="primary">
                Featured
            </Typography>
            <InputSelect
                options={featuredOptions}
                onChange={handleFeaturedChange}
                name="isFeatured"
                value={content.isFeatured.toString()}
                placeholder=""
            />
            {content.createdAt && (
                <Typography variant="caption">
                    Created: {new Date(content.createdAt).toLocaleString('en-US')}
                </Typography>
            )}
            {content.updatedAt && (
                <Typography variant="caption">
                    Updated: {new Date(content.updatedAt).toLocaleString('en-US')}
                </Typography>
            )}
            <br />
            {content.id && (
                <Button onClick={handleOpenDeleteAlert} color="error" disabled={loading}>
                    Delete
                </Button>
            )}
            <div className="action">
                <Button onClick={handleSubmit} color="primary" disabled={loading}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default ContentActions;
