import Content, { ContentType } from '../models/Content';
import { UploadFileType } from '../models/Upload';
import instance from './instance';

function getContents(type: ContentType, skip = 0): Promise<{ items: Content[]; total: number }> {
    const url = `contents?type=${type}&skip=${skip}`
    return instance.get(url).then((response) => {
        return {
            items: response.data.items ? response.data.items : [],
            total: response.data.total ? response.data.total : 0,
        };
    });
}

function createContent(content: Content): Promise<Content> {
    return instance.post(`content`, content).then((response) => {
        return response.data;
    });
}

function getContent(id: string): Promise<Content> {
    return instance.get(`content/${id}`).then((response) => {
        return response.data;
    });
}

function deleteContent(id: string): Promise<boolean> {
    return instance.delete(`content/${id}`).then((response) => {
        return response.data;
    });
}

function updateContent(content: Content): Promise<Content> {
    return instance.put(`content`, content).then((response) => {
        return response.data;
    });
}

function uploadMedia(blob: Blob, name: string): Promise<string> {
    const formData = new FormData();
    formData.append('type', UploadFileType.Content.toString());
    formData.append('file', blob, name);
    return instance.post(`/upload`, formData).then((response) => {
        return response.data;
    });
}

function getFeaturedAnnouncement():Promise<Content> {
    return instance.get('/content/featured-announcement').then((response)=> {
        return response.data;
    })
}

export { getContents, createContent, updateContent, getContent, deleteContent, uploadMedia, getFeaturedAnnouncement };
