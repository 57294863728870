import React from 'react';
import { UserService } from '../../api';
import Button from '../../components/Button';
import InputSelect from '../../components/InputSelect';
import Modal, { ModalActionBar } from '../../components/Modal';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
type Props = {
    isOpen: boolean;
    close: () => void;
    name: string | null;
    id: string | null;
    getData: () => void;
};
const UserDeletionModal: React.FC<Props> = ({ isOpen, close, id, name, getData }) => {
    const [loading, setLoading] = React.useState(false);
    const [comments, setComments] = React.useState('');
    const [reason, setReason] = React.useState('')

    const handleDelete = () => {
        setLoading(true);
        UserService.disableUser(id as string, comments, reason === "ALMUNI")
            .then(() => {
                getData();
                setComments('');
                close();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal title="Disable User" isOpen={isOpen} close={close} size="medium">
            <Typography variant="subtitle">Are you sure you want to disable {name}?</Typography>
            <Typography variant="caption">
                Please select a reason as to why the account is being disabled.
            </Typography>
            <InputSelect options={[{label: "ALUMNI", value: "ALMUNI"}, {label: "DISMISSED", value: "DISMISSED"}]} value={reason} name="reason" onChange={(_name, value)=>setReason(value as string)} placeholder="Select a reason"/>
            <TextArea onChange={(_name, value)=>setComments(value)} name="comments" value={comments} placeholder="comments"/>
            <ModalActionBar>
                <Button onClick={close}>Cancel</Button>
                <Button color="error" onClick={handleDelete} loading={loading} disabled={reason.length===0}>
                    Disable
                </Button>
            </ModalActionBar>
        </Modal>
    );
};

export default UserDeletionModal;
