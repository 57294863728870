import React from 'react';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/external-link.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as UnArchiveIcon } from '../../../assets/icons/unarchive.svg';
import IconButton from '../../../components/IconButton';
import Project, { ProjectStatus } from '../../../models/Project';
import { Link } from 'react-router-dom';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/AlertDialog/state';
import { DataLoadingContext } from '../../../components/DataLoading';
import { ProjectService } from '../../../api';
import { NotificationActionType, NotificationContext } from '../../../components/Notification/state';

type Props = {
    project: Project;
    refreshData: ()=> void;
};
const ProjectActions: React.FC<Props> = ({ project, refreshData }) => {
    
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const handleStatusChange = (status: ProjectStatus) => {
        toggleLoading(true);
        ProjectService.updateProjectStatus(project?.id as string, status).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: "Project unarchived successfully",
                    status: 'success',
                    autoClose: true,
                }})
            refreshData();
        }).catch((e)=>notificationDispatch({
            type: NotificationActionType.OPEN,
            payload: e.toString()
        })).finally(()=>toggleLoading(false))
    };

    const handleOpenUnArchiveDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Unarchive Project',
                description:  "Are you sure you want to unarchive this project?",
                handleConfirm: () => handleStatusChange(ProjectStatus.Active),
            },
        });
    }
    
    return (
        <>            
            {project.status === ProjectStatus.Active && (
                <Link to={`/campaigns/create-campaign/${project.id}`}><IconButton
                    Icon={AddIcon}
                    color="info"
                    tooltip="Create campaign"
                />
                </Link>
            )}
            <Link to={`/projects/${project.id}/detail`}>
            <IconButton
                Icon={ExternalLinkIcon}
                color="info"
                tooltip="Project Details"
            />
            </Link>
            {project.status === ProjectStatus.Archived && (<IconButton
                    Icon={UnArchiveIcon}
                    color="info"
                    tooltip="Unarchive Project"
                    onClick={handleOpenUnArchiveDialog}
                />
            )}
        </>
    );
};

export default ProjectActions;
