import React from 'react';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputField from '../../../components/InputField';
import TextArea from '../../../components/TextArea';
import Typography from '../../../components/Typography';
import { SurveyCheckboxData } from '../../../models/Survey';

type Props = {
    data: SurveyCheckboxData;
    handleSubmit: (data: SurveyCheckboxData) => void;
};
const SurveyCheckboxDetail: React.FC<Props> = ({ data, handleSubmit }) => {
    const [detail, setDetail] = React.useState(data);

    React.useEffect(() => {
        setDetail(data);
    }, [data]);

    const handleChange = (name: string, value: string | boolean) => {
        setDetail({ ...detail, [name]: value });
    };
    return (
        <div className="detail">
            <Typography variant="subtitle" color="primary">
                CHECKBOX DETAILS
            </Typography>
            <hr />
            <Typography variant="body">Please provide title for the form field.</Typography>
            <InputField onChange={handleChange} name="title" value={detail.title} />
            <Typography variant="body">Please provide description if applicable.</Typography>
            <TextArea onChange={handleChange} name="description" value={detail.description} />
            <Typography variant="body">Please provide label for the checkbox.</Typography>
            <InputField onChange={handleChange} name="label" value={detail.label} />
            <Checkbox
                onChange={() => handleChange('required', !detail.required)}
                label={'This is a required field'}
                checked={detail.required ? detail.required : false}
            />
            <div className="action-container">
                <Button onClick={() => handleSubmit(detail)}>Update Item</Button>
            </div>
        </div>
    );
};

export default SurveyCheckboxDetail;
