import React from 'react';
import Typography from '../../components/Typography';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import TextArea from '../../components/TextArea';
import { OtherDetails } from '../../models/Application';
import InputMultiSelect from '../../components/InputMultiSelect';

type Props = {
    data: OtherDetails;
    handleSave: (data: OtherDetails) => void;
    handleButtonClick: (action: string) => void;
};

const Other: React.FC<Props> = ({ data, handleSave, handleButtonClick }) => {
    const [otherDetails, setOtherDetails] = React.useState(data);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setOtherDetails(data);
    }, [data]);

    const freeTime = [
        {
            label: 'Daytime',
            value: 'Daytime',
        },
        {
            label: 'Evening',
            value: 'Evening',
        },
        {
            label: 'Weekends',
            value: 'Weekends',
        },
        {
            label: 'Around Classes',
            value: 'Around Classes',
        },
        {
            label: 'Around Work Schedule',
            value: 'Around Work Schedule',
        },
    ];

    const handleChange = (name: string, value: string | Array<string> | boolean) => {
        setOtherDetails({ ...otherDetails, [name]: value });
    };

    const handleSubmit = (): void => {
        const isValid =
            otherDetails.whatKindSmartPhone.length > 0 &&
            otherDetails.freeTime.length > 0 &&
            otherDetails.FreeTimeActivity.length > 0 &&
            otherDetails.whyCareerGoal.length > 0 &&
            otherDetails.streetTeamExperience.length > 0 &&
            otherDetails.hasAgreedPolicy;

        if (!isValid) {
            setShowError(true);
        } else {
            handleSave(otherDetails);
            handleButtonClick('NEXT');
        }
    };

    const handleBack = () => {
        handleButtonClick('BACK');
    };

    return (
        <>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body" color="secondary">
                        What kind of Smart phone do you have? <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="whatKindSmartPhone"
                        value={otherDetails.whatKindSmartPhone}
                        error={showError && otherDetails.whatKindSmartPhone.length === 0}
                        errorText="required"
                    />
                </div>                
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        How much free time do you have per week? <span className="color-error">*</span>
                    </Typography>
                    <InputMultiSelect
                        options={freeTime}
                        value={otherDetails.freeTime}
                        name="freeTime"
                        placeholder="select all that apply"
                        onChange={handleChange}
                        error={showError && otherDetails.freeTime.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <div>
                <Typography variant="body" color="secondary">
                    What takes up your free time? <span className="color-error">*</span>
                </Typography>
                <Typography variant="caption">(Do you have any extracurricular activities or jobs?)</Typography>
                <TextArea
                    onChange={handleChange}
                    name="FreeTimeActivity"
                    error={showError && otherDetails.FreeTimeActivity.length === 0}
                    errorText="required"
                    value={otherDetails.FreeTimeActivity}
                />
            </div>
            <div>
                <Typography variant="body" color="secondary">
                    Why do you want to be Street Marketing Representative for The Syndicate?{' '}
                    <span className="color-error">*</span>
                </Typography>
                <Typography variant="caption">(If not, what are your career goals?)</Typography>
                <TextArea
                    onChange={handleChange}
                    name="whyCareerGoal"
                    error={showError && otherDetails.whyCareerGoal.length === 0}
                    errorText="required"
                    value={otherDetails.whyCareerGoal}
                />
            </div>
            <div>
                <Typography variant="body" color="secondary">
                    Have you ever been on a street team? <span className="color-error">*</span>
                </Typography>
                <Typography variant="caption">(If yes, for whom?)</Typography>
                <TextArea
                    onChange={handleChange}
                    name="streetTeamExperience"
                    error={showError && otherDetails.streetTeamExperience.length === 0}
                    errorText="required"
                    value={otherDetails.streetTeamExperience}
                />
            </div>
            <div>
                <Typography variant="body" color="secondary">
                    Is there anything else you would like us to know?
                </Typography>
                <TextArea onChange={handleChange} name="comments" value={otherDetails.comments} />
            </div>
            <div>
                <Typography variant="body" color="secondary" weight={600}>
                    I understand that by clicking Submit, I am confirming I am either a U.S citizen or can provide valid documentation to work in the United States.
                </Typography>
                <Radio
                    onChange={() => handleChange('hasAgreedPolicy', !otherDetails.hasAgreedPolicy)}
                    checked={otherDetails.hasAgreedPolicy}
                    label="I understand"
                />
                {showError && !otherDetails.hasAgreedPolicy && (
                    <Typography variant="caption" color="error">
                        You must accept our terms and conditions
                    </Typography>
                )}
            </div>
            <div className="action-container">
                <Button color="primary" onClick={handleBack}>
                    Back
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default Other;
