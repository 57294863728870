import React from 'react';
import './forgotpassword.scss';
import Logo from '../../assets/images/logo-no-subtitle.png';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { emailIsValid } from '../../utils';
import { AuthService } from '../../api';
import { Link, useParams } from 'react-router-dom';

const ForgotPassword: React.FC = () => {    
    const { emailAddress } = useParams<{ token: string; emailAddress: string }>();
    const isResend = emailAddress && emailAddress.length>0;
    const [email, setEmail] = React.useState(isResend?decodeURIComponent(emailAddress): '');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleEmailChange = () => {
        setIsSuccess(false);
        setError('');
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        AuthService.forgotPassword(email)
            .then(() => {
                setIsLoading(false);
                setIsSuccess(true);
                setError('');
            })
            .catch((e) => {
                setIsLoading(false);
                setError(e.message);
            });
    };

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                {!isSuccess ? (
                    <>
                        {!isResend && <Typography variant="title" weight={500} color="secondary">
                           FORGOT PASSWORD
                        </Typography>}

                        {!isResend ? <Typography variant="body">
                            Enter the email associated with your account and {`we'll`} send an email with instructions
                            to reset your password.
                        </Typography>: <Typography variant="body">
                            For security reasons, this link has expired.  Please press <strong>Resend</strong> to generate a new link.
                        </Typography>}
                        {error.length > 0 && (
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        )}
                        <InputField
                            onChange={(_name, value) => setEmail(value)}
                            name="email"
                            required={true}
                            value={email}
                            error={email.length > 0 && !emailIsValid(email)}
                            disabled={emailAddress?.length>0}
                        />
                        <div className="action-container">
                            <Typography variant="caption">
                                <Link to="/"><span
                                    className="text-action"
                                >
                                    Back to login
                                </span></Link>
                            </Typography>
                            <Button color="primary" onClick={handleResetPassword} disabled={!emailIsValid(email)} loading={isLoading}>
                            {!isResend ? "Send Instructions" : "Resend"}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography variant="title" color="secondary" textAlign="center">
                            Password recovery instructions have been sent to your email.
                        </Typography>
                        <Typography variant="body" textAlign="center">
                            Did not receive the email? Check your spam filter, or&nbsp;
                            <strong
                                onClick={handleEmailChange}
                                onKeyPress={(e: React.KeyboardEvent) => e.key === 'Enter' && handleEmailChange()}
                                className="text-action"
                                tabIndex={0}
                            >
                                try another email address
                            </strong>
                        </Typography>
                        <Link to="/"> <Button
                            color="primary"                            
                            className="mx-auto d-block"
                            loading={isLoading}
                        >
                            Back to Login
                        </Button></Link>
                    </>
                )}
            </section>
        </div>
    );
};

export default ForgotPassword;
