export default interface UserReport {
    id?: string;
    synnerId?: string;
    campaignId: string;
    surveyId?: string;
    status?: UserReportStatus;
    comments?: string;
    data: ReportData;
    createdAt?: string;
    updatedAt?: string;
}

export interface UserReportGrid {
    id: string;
    synnerId: string;
    campaignId: string;
    campaignName: string;
    projectName: string;
    displayName: string;
    surveyId: string;
    surveyTitle: string;
    status: UserReportStatus;
    updatedAt: Date;
}

export enum UserReportStatus {
    'To Do' = 0,    
    'Need More Info' = 1,
    Approved = 2,
    Rejected = 3,    
    'Pending Review' = 4,
    Incomplete = 5
}

export type UserSubmission = {
    surveyId: string,
    campaignId: string,
    surveyName: string,
    campaignName: string,
    dueDate: string,
    surveyCompleted: boolean,
    totalSubmissions: number,
    reports: SubmissionReport[]
}

export type SubmissionReport = {    
    reportId:string,
    dateUpdated:string
    status:UserReportStatus
}

export type ReportData = { [property: string]: string };
