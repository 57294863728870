import SocialMedia  from "./SocialMedia"

export type PersonalDetails = {
    fname: string,
    lname: string,
    name?: string,
    addressLine1: string,
    addressLine2?: string,
    city: string,
    state: string,
    zipCode: string,
    country: string,
    dateOfBirth: string,
    age?: string,
    preferredPronouns: string,
    howSyndicate: string,
    emailAddress: string,
    phoneNumber: string,
}

export type EducationDetails = {
    isStudent: boolean,
    schoolYear: string,
    major: string,
    college: string,
    collegeCity: string,
    collegeState:string,
    expectedGraduation: string,
}

export type OnlineActivityDetails = {
    emailFrequency: string,
    socialMedias: Array<SocialMedia>    
}

export type MusicComedyDetails = {
    favouriteArtist: string,
    favouriteExplanation: string,
    showFrequency: string,
}

export type OtherDetails = {
    whatKindSmartPhone: string,
    freeTime: Array<string>,
    FreeTimeActivity: string,
    whyCareerGoal: string,
    streetTeamExperience: string,
    comments?: string,
    hasAgreedPolicy: boolean,
}

export type Applicant = PersonalDetails & EducationDetails & OnlineActivityDetails & MusicComedyDetails & OtherDetails & {
    id: string,
    status : ApplicantionStatus,
    applicationReceivedAt: string,
    interviewRequestedAt?: string,
    approvedAt?: string,
    rejectedAt?: string,
    dateCreated: string,
    dateUpdated?: string,
    isDeleted: boolean
}

export enum ApplicantionStatus  {
    Applicant = 0,
    Interview = 1,
    Approved = 2,
    Denied = 3
}
