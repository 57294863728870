import React from 'react';
import { useParams } from 'react-router-dom';
import { CampaignService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import Typography from '../../components/Typography';
import Campaign from '../../models/Campaign';
import CampaignForm from './CampaignForm';

const EditCampaign: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [campaign, setCampaign] = React.useState<Campaign>();
    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        toggleLoading(true);
        CampaignService.getCampaign(id)
            .then((response) => {
                setCampaign(response);
            })
            .finally(() => toggleLoading(false));
    }, [id]);

    const handleSubmit = (updatedCampaign: Campaign): Promise<Campaign> => {
        return CampaignService.updateCampaign(updatedCampaign);
    };
    return (
        <div className="campaigns">
            <Typography variant="title" textAlign="center">
                EDIT CAMPAIGN
            </Typography>
            <hr />
            <br />
            {campaign && <CampaignForm selectedCampaign={campaign} handleSubmit={handleSubmit} />}
        </div>
    );
};

export default EditCampaign;
