import { process, State } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../../components/Typography';
import Campaign, { CampaignStatus } from '../../../models/Campaign';
import { UserRole } from '../../../models/User';
import { formatDate, formatLocation } from '../../../utils';
import { getColorByCampaignStatus } from '../../../utils/getColorByStatus';
import { pagerMiniSettings } from '../../../utils/pagerSettings';
import { AuthContext } from '../../Authentication/state';
import ProjectAvatar from '../../Projects/ProjectAvatar';
import CampaignActions from './CampaignActions';

type Props = {
    data: Campaign[];
    getData: () => void;
};

const MiniGridList: React.FC<Props> = ({ data }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { user } = React.useContext(AuthContext).state;
    const isAdminManager = user && (user.role === UserRole.Admin || user.role === UserRole.Manager);
    const history = useHistory();

    React.useEffect(()=>{
        if(history.location.state) {
            setDataState(history.location.state as State);
        } else {
            setDataState({ skip: 0, take: 20 });
        }
    }, [])

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: CampaignStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = {
            className: isAdminManager
                ? `campaign--${getColorByCampaignStatus(status)}`
                : // eslint-disable-next-line react/prop-types
                props.dataItem.isInCampaign
                ? `campaign--participating`
                : ``,
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    return (
        <Grid
            style={{ height: '100%' }}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                history.replace({
                    ...history.location,
                    state: e.dataState
                });
                setDataState(e.dataState);
            }}
            pageable={pagerMiniSettings}
            filterable={true}
            sortable={false}
            // eslint-disable-next-line react/prop-types
            onRowClick={(props) => history.push(`/campaigns/${props.dataItem.id}/detail`)}
            rowRender={rowRender}
        >
            <GridColumn width="76px" cell={(props)=><td className="clickable-grid-custom-item project-avatar"><ProjectAvatar {...props}/></td>} field="photoUrl" title=" " filterable={false} sortable={false}/>
            <GridColumn
                title="search by name"
                field="displayName"
                cell={(props: GridCellProps) => (
                    <td className="grid-item-mobile clickable-grid-custom-item">
                        <div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Name
                                </Typography>
                                <Typography variant="caption" weight={500}>
                                    {props.dataItem.projectName}
                                </Typography>
                                <Typography variant="caption">{props.dataItem.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Duration
                                </Typography>
                                <Typography variant="caption">
                                    {formatDate(props.dataItem.startDate)} - {formatDate(props.dataItem.endDate)}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Address
                                </Typography>
                                <Typography variant="caption">
                                    {props.dataItem.venue && (
                                        <>
                                            <b>{props.dataItem.venue}</b>
                                            <br />
                                        </>
                                    )}
                                    {formatLocation(
                                        props.dataItem.addressLine1,
                                        props.dataItem.addressLine2,
                                        props.dataItem.city,
                                        props.dataItem.state,
                                        props.dataItem.country,
                                        props.dataItem.zipCode,
                                    )}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Stipend
                                </Typography>
                                <Typography variant="caption">${Number.parseFloat(props.dataItem.stipend).toFixed(2)}</Typography>
                            </div>
                            <div>
                                <Typography variant="body" weight={500}>
                                    Positions Available
                                </Typography>
                                <Typography variant="caption">{props.dataItem.expectedSynners}</Typography>
                            </div>
                        </div>
                        <div className="grid-actions">
                            <CampaignActions campaign={props.dataItem as Campaign}/>
                        </div>
                    </td>
                )}
            />
        </Grid>
    );
};

export default MiniGridList;
