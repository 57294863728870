import React from 'react';
import Content, { ContentStatus, ContentType } from '../../models/Content';
import ContentManager from '../ContentManager';
import Typography from '../../components/Typography';
import EditablePage from '../../components/EditablePage';

const initHandBook: Content = {
    type: ContentType.Handbook,
    status: ContentStatus.Draft,
    title: '',
    description: '',
    data: '',
    isFeatured: false,
};

const CreateHandbook: React.FC = () => {

    return (
        <EditablePage>
            <div className="handbooks">
                <Typography variant="subtitle" textAlign="center">
                    CREATE HANDBOOK
                </Typography>
                <ContentManager content={initHandBook}>
                    <Typography variant="subtitle" color="primary" textAlign="center">
                        Please switch to editor mode to create a handbook
                    </Typography>
                </ContentManager>
            </div>
        </EditablePage>
    );
};

export default CreateHandbook;
