import React from 'react';
import { ActivityService } from '../../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/AlertDialog/state';
import IconButton from '../../../components/IconButton';
import { ActivityStatus } from '../../../models/Activity';
import UserInCampaign from '../../../models/UserInCampaign';
import { ReactComponent as ApproveIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CompleteIcon } from '../../../assets/icons/check-square.svg';
import { ReactComponent as DeclineIcon } from '../../../assets/icons/x-circle.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/minus-circle.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/clipboard.svg';
import { ReactComponent as DollarIcon } from '../../../assets/icons/dollar-sign.svg';
import { CampaignStatus } from '../../../models/Campaign';
import { ReactComponent as LinkIcon } from '../../../assets/icons/external-link.svg';
import { Link } from 'react-router-dom';
type Props = {
    data: UserInCampaign;
    getData: () => void;
    openReport: (synner: UserInCampaign) => void;
    openInvoice: (synner: UserInCampaign) => void;
    campaignstatus: CampaignStatus;
};
const UserCampaignAdminAction: React.FC<Props> = ({ data, getData, openReport, openInvoice, campaignstatus }) => {
    const { activityStatus: status } = data;
    const preStartStatus =
        status === ActivityStatus.Invited || status === ActivityStatus.Applied || status === ActivityStatus.Declined;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleDeleteActivityFromCampaign = (activityId: string) => {
        ActivityService.deleteActivityFromCampaign(activityId).then(() => {
            getData();
        });
    };

    const handleOpenDeleteSynnerInCampaignDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Remove Synner',
                description: `Are you sure you want to remove ${data.name} from the campaign?`,
                handleConfirm: () => handleDeleteActivityFromCampaign(data.activityId as string),
            },
        });
    };

    const handleApprove = () => {
        ActivityService.updateActivityStatus({ id: data.activityId, status: ActivityStatus.Active }).then(() => {
            getData();
        });
    };

    const handleDecline = () => {
        ActivityService.updateActivityStatus({ id: data.activityId, status: ActivityStatus.Declined }).then(() => {
            getData();
        });
    };

    const handleOPenCompleteDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Complete Activity',
                description: `The required reports have not been approved through the system. Are you sure you want to mark ${data.name}'s work as completed?`,
                handleConfirm: handleComplete,
            },
        });
    };

    const handleComplete =()=> {        
        ActivityService.updateActivityStatus({ id: data.activityId, status: ActivityStatus.Completed }).then(() => {
            getData();
        });
    }

    return (
        <>
            {campaignstatus === CampaignStatus.Ready && (
                <IconButton
                    Icon={DeleteIcon}
                    onClick={handleOpenDeleteSynnerInCampaignDialog}
                    color="error"
                    tooltip="Remove from campaign"
                />
            )}
            {status === ActivityStatus.Applied && (
                <IconButton tooltip="Approve" color="success" onClick={handleApprove} Icon={ApproveIcon} />
            )}
            {status === ActivityStatus.Applied && (
                <IconButton tooltip="Decline" color="error" onClick={handleDecline} Icon={DeclineIcon} />
            )}
            {!preStartStatus && (
                <div className={data.hasNewReport ? 'show-action-ticker' : ''}>
                    <IconButton
                        tooltip="View reports"
                        Icon={ReportIcon}
                        color="info"
                        onClick={() => openReport(data)}
                    />
                </div>
            )}
            {(status === ActivityStatus.Completed) && !data.invoiceIsCreated && campaignstatus !== CampaignStatus.Archived && (
                <IconButton
                    Icon={DollarIcon}
                    onClick={() => openInvoice(data)}
                    color="success"
                    tooltip="Create invoice"
                />
            )}
            {(status === ActivityStatus.Incomplete || status === ActivityStatus.Active) &&
                campaignstatus !== CampaignStatus.Archived && (
                    <IconButton Icon={CompleteIcon} color="info" tooltip="Mark as Completed" onClick={handleOPenCompleteDialog} />
            )}
            {status === ActivityStatus.Completed && data.invoiceIsCreated && (
                <Link to="/financials">
                    <IconButton Icon={LinkIcon} color="info" tooltip="View Financials" />
                </Link>
            )}
        </>
    );
};

export default UserCampaignAdminAction;
