import { GridPagerSettings } from "@progress/kendo-react-grid";

const pagerSettings:GridPagerSettings = {
    buttonCount: 0,
    info: true,
    type: "input",
    pageSizes: [20, 50, 100],
    previousNext: true,
  };

export const pagerMiniSettings:GridPagerSettings = {
    buttonCount: 0,
    info: true,
    type: "numeric",
    pageSizes: false,
    previousNext: true,
  };

export default  pagerSettings;