import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserRole } from '../../models/User';
import { routes } from './routeConfig';
import PrivateRoute from './PrivateRoute';
import AuthenticatedLayout from '../AuthenticatedLayout';

const AppRouter: React.FC = () => {

    const visitorRoutes = routes.filter((element) => element.roles.includes(UserRole.Visitor));
    const notVisitorRoutes = routes.filter((element) => !element.roles.includes(UserRole.Visitor));
    
    return (
        <Switch>
            {visitorRoutes.map((element, index) => {
                return <Route path={element.route} exact={element.exact} component={element.Component} key={index} />;
            })}
            <AuthenticatedLayout>
                <Switch>
                    {notVisitorRoutes.map((element, index) => {
                        return (
                            <PrivateRoute
                                path={element.route}
                                exact={element.exact}
                                component={element.Component}
                                roles={element.roles}
                                key={index}
                            />
                        );
                    })}
                    <Redirect to="/404"/>
                </Switch>
            </AuthenticatedLayout>
        </Switch>
    );
};

export default AppRouter;
