import React, { useEffect, useRef } from 'react';
import { ProfileService } from '../../api';
import Button from '../../components/Button';
import { NotificationActionType, NotificationContext } from '../../components/Notification/state';
import Typography from '../../components/Typography';
import Profile from '../../models/Profile';
import './profile.scss';
import ProfileInfo from './ProfileInfo';
import ProfilePicture from './ProfilePicture';
import {useHistory} from 'react-router-dom';
import { DataLoadingContext } from '../../components/DataLoading';
import { AuthContext } from '../Authentication/state';
import { UserRole } from '../../models/User';
import ErrorInfo from '../../components/ErrorInfo';

const UserProfile: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [profile, setProfile] = React.useState<Profile | null>(null);
    const {user} = React.useContext(AuthContext).state;
    const needsEnrichment = user ? user.role=== UserRole.OnBoarding: false;
    const [showEnrichment, setShowEnrichment]= React.useState(needsEnrichment);
    const [edit, setEdit] = React.useState(needsEnrichment);
    const history = useHistory();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const myRef =  useRef<null | HTMLDivElement>(null); 
    
    useEffect(() => {
        toggleLoading(true);
        ProfileService.getProfile().then((response) => {
            setProfile(response);
        }).finally(()=>toggleLoading(false));        
    }, []);

    const handleUpdate = (updatedProfile: Profile):Promise<boolean> => {
        return new Promise((resolve)=> {
            ProfileService.updateProfile(updatedProfile).then(() => {
                setEdit(false);
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: 'profile successfully updated!',
                        status: "success",
                        autoClose: true,
                    },
                });
            }).catch((error)=> {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: error.message,
                        status: "error",
                        autoClose: true,
                    },
                });
            }).finally(()=> {
                resolve(true);
            })
        })        
    };

    const handlePictureChange =(file:File):Promise<string>=> {
        return ProfileService.uploadProfilePicture(profile?.id as string, file);
    }

    return (
        <div className="profile-container">
                    <Typography variant="subheading" textAlign='center'>
                        Profile
                    </Typography>
                    <hr />
                    <br/>
                    {profile && (
                        <>
                            <div className="first-section">
                                <div>
                                    <ProfilePicture source={profile.photoUrl} name={profile.fname} handleChange={handlePictureChange}/>
                                </div>
                                {(profile.role ===UserRole.Admin || profile.role === UserRole.Manager) && <Typography variant='subtitle' weight={600} textAlign="center">{UserRole[profile.role]}</Typography>}
                                <div>
                                    <Typography variant="caption" textAlign='center'>
                                        Number of campaigns completed: <strong>{profile.totalCompletedCampaigns}</strong>
                                    </Typography>
                                    {!edit && <><Button onClick={() => setEdit(true)}>Edit Profile</Button>&nbsp;&nbsp;<Button onClick={() => history.push('/reset-password')}>Reset Password</Button></>}                                    
                                </div>
                            </div>  
                            <div ref ={myRef}>{showEnrichment && <ErrorInfo error='Please update your profile with valid information to use the portal.' onClose={()=>setShowEnrichment(false)}/> }</div>
                            <ProfileInfo
                                profileData={profile}
                                cancelUpdate={() => setEdit(false)}
                                updateProfile={handleUpdate}
                                isEditView={edit}
                                handleUpdateError = {()=>myRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  })}
                            />
                        </>
                    )}
        </div>
    );
};

export default UserProfile;


