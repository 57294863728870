import React from 'react';
import Typography from '../../../components/Typography';
import { formatDate, formatLocation } from '../../../utils';
import { InvitationContext } from './state';

const CampaignSummary: React.FC = () => {
    const { campaignDetail } = React.useContext(InvitationContext).state;

    return (
        <>
            {campaignDetail && (
                <>
                    <Typography variant="subtitle" textAlign="center">
                        {campaignDetail.name}
                    </Typography>
                    <Typography variant="body" textAlign="center">
                        {formatDate(campaignDetail.startDate)} -{' '}
                        {formatDate(campaignDetail.endDate)}
                    </Typography>
                    <Typography variant="body" textAlign="center">
                        {campaignDetail.venue && <><b>{campaignDetail.venue}</b><br/></>}
                        {formatLocation(
                            campaignDetail.addressLine1,
                            campaignDetail.addressLine2,
                            campaignDetail.city,
                            campaignDetail.state,
                            campaignDetail.country,
                            campaignDetail.zipCode,
                        )}
                    </Typography>
                </>
            )}
        </>
    );
};

export default CampaignSummary;
