import React from 'react';
import { useParams } from 'react-router-dom';
import { ActivityService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import Modal from '../../components/Modal';
import { UserCampaign } from '../../models/Campaign';
import { useWindowDimensions } from '../../utils';
import FullGridList from '../Campaigns/MyCampaigns/FullGridList';
import MiniGridList from '../Campaigns/MyCampaigns/MiniGridList';
import UserSubmissions from '../Campaigns/UserSubmissions';

const UserCampaigns: React.FC = () => {
    const [data, setData] = React.useState<UserCampaign[]>([]);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const { width } = useWindowDimensions();
    const {userId}= useParams<{userId: string}>();
    const [selectedCampaignId, setSelectedCampaignId] = React.useState('');

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ActivityService.getUserCampaigns(userId)
            .then((response) => setData(response))
            .finally(() => toggleLoading(false));
    };
    return (
        <div className='all-user-activities'>
            <>
            {width > 1000 ? (
                <FullGridList data={data} getData={getData} openReport={(campaignId)=>setSelectedCampaignId(campaignId)}/>
            ) : (
                <MiniGridList data={data} getData={getData} openReport={(campaignId)=>setSelectedCampaignId(campaignId)}/>
            )}
            <Modal
                    isOpen={selectedCampaignId.length !== 0}
                    close={() => setSelectedCampaignId('')}
                    size="medium"
                    title="Campaign Reports"
                >
                    <UserSubmissions campaignId={selectedCampaignId} synnerId={userId}/>
                </Modal>
                </>
        </div>
    );
};

export default UserCampaigns;
