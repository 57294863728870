import React from 'react';
import Typography from '../../components/Typography';

const data = [{
    label: "Competitive Event",
    color: "#908960",
}, {
    label: "Day-of-Show",
    color: "#373737",
}, {
    label: "Retail/Lifestyle Campaign",
    color: "#28A745",
}, {
    label: "Outdoor Postering",
    color: "#007BFF",
}, {
    label: "Digital Marketing Campaign",
    color: "#9995DB",
}]

const ProjectTypeLegend:React.FC=()=> {
    return <div className='project-legend'>
        {data.map((el, index)=><div className='legend' key={index}>
            <span style={{color: el.color}}>&#11044;</span>
            <Typography variant="body">{el.label}</Typography>
        </div>)}
    </div>
}

export default ProjectTypeLegend;