import React from 'react';
import { SurveyService } from '../../api';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Survey, { SurveyStatus } from '../../models/Survey';
import './surveys.scss';
import { ProjectType } from '../../models/Project';
import IconButton from '../../components/IconButton';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/AlertDialog/state';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridRowClickEvent,
    GridRowProps,
    GridFilterCellProps,
    GridToolbar,
    GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import {formatDate, getListFromEnum, useWindowDimensions} from '../../utils';
import GridDropDownFilter from '../../components/GridDropDownFilter';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import Tag from '../../components/Tag';
import Typography from '../../components/Typography';
import { DataLoadingContext } from '../../components/DataLoading';
import pagerSettings, { pagerMiniSettings } from '../../utils/pagerSettings';


const Surveys: React.FC = () => {
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [surveys, setSurveys] = React.useState<Survey[]>([]);
    const history = useHistory();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { width } = useWindowDimensions();

    const types = getListFromEnum(Object.values(ProjectType));

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        SurveyService.getSurveys().then((response) => {
            setSurveys(response);
        }).finally(()=>toggleLoading(false));
    };

    const handleDeleteDialog = (survey: Survey) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Survey',
                description: `Are you sure you want to delete survey ${survey.title}? Users won't be able to view reports that were created using this survey.`,
                handleConfirm: () => handleDeleteSurvey(survey.id as string),
            },
        });
    };

    const handleDeleteSurvey = (id: string) => {
        SurveyService.deleteSurvey(id).then(() => {
            getData();
        });
    };

    const handleSelection = (survey: Survey) => {
        history.push(`/surveys/${survey.id as string}`);
    };

    const handleRowSelection = (props: GridRowClickEvent) => {
        // eslint-disable-next-line react/prop-types
        handleSelection(props.dataItem);
    };

    const statuses = getListFromEnum(Object.values(SurveyStatus));

    const StatusFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={statuses} defaultValue={{ label: 'select status', value: '' }} />
    );

    const TypeFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={types} defaultValue={{ label: 'select type', value: '' }} />
    );

    const DateCell = (props: GridCellProps) => {
        return <td>{formatDate(props.dataItem[props.field as string])}</td>;
    };

    const Status = (props: GridCellProps) => {
        return (
            <td className='clickable-grid-custom-item'>
                <Tag label={SurveyStatus[props.dataItem.status]} color={getColorByStatus(props.dataItem.status)} />
            </td>
        );
    };
    const Type = (props: GridCellProps) => {
        return <td>{ProjectType[props.dataItem.type]}</td>;
    };

    const DefaultCell = (props: GridCellProps) => {
        return <td>{props.dataItem[props.field as string] ? <p className='text-center'><CheckIcon className='color-success' style={{width: '16px'}}/></p> : ''}</td>;
    };

    const MobileCell = (props: GridCellProps) => {
        return (
            <td className="grid-item-mobile clickable-grid-custom-item">
                <div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Title
                        </Typography>
                        <Typography variant="caption">{props.dataItem.title}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Date Updated
                        </Typography>
                        <Typography variant="caption">
                            {formatDate(props.dataItem.updatedAt)}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Type
                        </Typography>
                        <Typography variant="caption">
                            {ProjectType[props.dataItem.type]}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Default
                        </Typography>
                        <Typography variant="caption">{props.dataItem.isDefaultForType ? 'YES' : 'NO'}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Status
                        </Typography>
                        <Tag
                            label={SurveyStatus[props.dataItem.status]}
                            color={getColorByStatus(props.dataItem.status)}
                        />
                    </div>
                </div>
                <div className='grid-actions'>
                    <IconButton
                        Icon={ExternalLinkIcon}
                        onClick={() => handleSelection(props.dataItem as Survey)}
                        color="info"
                        tooltip='Survey Details'
                    />
                    <IconButton
                        onClick={() => handleDeleteDialog(props.dataItem as Survey)}
                        Icon={DeleteIcon}
                        color="error"
                        tooltip="Delete Survey"
                    />
                </div>
            </td>
        );
    };

    const getColorByStatus = (status: SurveyStatus) => {
        switch (status) {
            case SurveyStatus.Draft:
                return 'warning';
            case SurveyStatus.Published:
                return 'success';
            default:
                return 'primary';
        }
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        // eslint-disable-next-line react/prop-types
        const status: SurveyStatus = props.dataItem.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `survey--${getColorByStatus(status)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    return (
        <div className="surveys">
            <div className="survey-list">
                <Grid
                    style={{ height: '100%' }}  
                    sortable={true}
                    pageable={width>1000 ?pagerSettings: pagerMiniSettings}
                    filterable={true}
                    data={process(surveys, dataState)}
                    {...dataState}
                    onDataStateChange={(e:GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                      }}
                    onRowClick={handleRowSelection}
                    rowRender={rowRender}
                >
                    <GridToolbar>
                        <Button color="primary" onClick={() => history.push(`/create-survey`)}>
                            Create
                        </Button>
                    </GridToolbar>
                    {width > 1000 && <GridColumn width="160px" title="Title" field="title" />}
                    {width > 1000 && (
                        <GridColumn field="status" title="Status" cell={Status} filterCell={StatusFilterCell} />
                    )}
                    {width > 1000 && <GridColumn field="type" title="Type" cell={Type} filterCell={TypeFilterCell} />}
                    {width > 1000 && <GridColumn field="isDefaultForType" title="Default Template" cell={DefaultCell} filterable={false} sortable={false} />}
                    {width > 1000 && <GridColumn field="updatedAt" title="Date Updated" cell={DateCell} filterable={false} sortable={false}/>}
                    {width > 1000 && (
                        <GridColumn
                            cell={(props: GridCellProps) => {
                                return (
                                    <td className='grid-actions'>
                                        <IconButton
                                            onClick={() => handleDeleteDialog(props.dataItem as Survey)}
                                            Icon={DeleteIcon}
                                            color="error"
                                            tooltip="Delete Survey"
                                        />
                                    </td>
                                );
                            }}
                            filterable={false}
                            sortable={false}
                        />
                    )}

                    {width <= 1000 && <GridColumn title="search by title" field="name" cell={MobileCell} />}
                </Grid>
            </div>
        </div>
    );
};

export default Surveys;

