import React from 'react';
import './textarea.scss';

type Props = {
    onChange: (name:string, value: string) => void;
    disabled?: boolean;
    name: string;
    className?: string;
    id?: string | undefined;
    error?: boolean;
    errorText?:string;
    required?:boolean;
    placeholder?: string;
    value?: string| number;
};

const TextArea: React.FC<Props> = ({ onChange, disabled=false, name, id, className, error, errorText, required, placeholder = " ", value='' }) => {
    const handleChange=(e:React.ChangeEvent<HTMLTextAreaElement>): void=> {
        onChange(e.target.name, e.target.value)
    }
    return (
        <div className="text-area-field-container">
            <textarea className={`input-field ${className? className: ''} ${error? 'error': ''}`} placeholder={placeholder} onChange={handleChange} disabled={disabled} name={name} id={id} required={required} value={value}/>            
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default TextArea;
