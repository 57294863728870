import React from 'react';
import './card.scss';
type Props = {
    children: JSX.Element | JSX.Element[];
};

const Card: React.FC<Props> = ({ children}) => {
    return <div className='card-container'>{children}</div>
}

export const CardActionBar: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
    return <div className="card-action-bar">{children}</div>;
};

export const CardActionHeader: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
    return <div className="card-action-header">{children}</div>;
};

export default Card;